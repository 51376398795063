import {requiredIf} from 'vuelidate/lib/validators'
import {payloadKey} from '@/app/validators'

export default {
  $each: {
    key: {
      payloadKey,
      required: requiredIf (function (addOn) {
        return addOn.value && addOn.value !== ''
      })
    },
    value: {
      required: requiredIf (function (addOn) {
        return addOn.key && addOn.key !== ''
      })
    }
  }
}
