<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <base-layout mw3>
      <v-col cols="12">
        <v-alert
          v-model="isSuccessAlertVisible"
          type="success"
          dismissible>
          <i18n path="update.success">
            <template #link>
              <router-link
                :to="{name: 'domain.view', params: { name }}"
                v-text="name"/>
            </template>
          </i18n>
        </v-alert>
      </v-col>
    </base-layout>

    <v-alert
      id="invalidDataAlertVisible"
      v-t="'general.invalid.form'"
      type="error"
      :value="isInvalidDataAlertVisible"/>

    <domain-create-update
      :domain-data="{domain}"
      :type="type"
      @success="onSuccess"
      @unsavedChanges="setUnsavedChanges"
      @dataValidityChanged="onDataValidityChanged"
      @cancel="back"/>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import goTo from 'vuetify/lib/services/goto'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import unsavedChangesHelper from '@/app/core/mixins/UnsavedChangesHelper'

  import DomainCreateUpdate from './DomainCreateUpdate'

  import {typeProp} from './ApplicationSupport'
  import {mapMutations} from 'vuex'

  export default {
    name: 'DomainEdit',

    components: {
      BaseLayout,
      DomainCreateUpdate
    },

    mixins: [unsavedChangesHelper],

    props: {
      name: {
        type: String,
        default: undefined
      },
      id: {
        type: [String, Number],
        default: undefined
      },
      type: typeProp
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        isInvalidDataAlertVisible: false
      }
    },

    computed: {
      domain () {
        return this.name ? {name: this.name} : {id: this.id}
      }
    },

    methods: {

      ...mapMutations ('notification', ['addEvent']),

      onSuccess () {
        this.isSuccessAlertVisible = true

        this.addEvent ({
          type: 'success',
          message: this.$t ('update.success', {link: this.name}),
          objects: [{name: this.name, link: {name: 'domain.view', params: {name: this.name}}}]
        })
      },

      onDataValidityChanged (isValid) {
        console.warn ('onDataValidityChanged', isValid)
        this.isInvalidDataAlertVisible = !isValid
        if (this.isInvalidDataAlertVisible) this.scrollToAlert ()
      },

      back () {
        this.$router.back ()
      },

      scrollToAlert () {
        goTo ('#invalidDataAlert')
      }
    }
  }
</script>
