import { render, staticRenderFns } from "./ContactCreateUpdate.vue?vue&type=template&id=67f208d0&scoped=true&"
import script from "./ContactCreateUpdate.vue?vue&type=script&lang=js&"
export * from "./ContactCreateUpdate.vue?vue&type=script&lang=js&"
import style0 from "./ContactCreateUpdate.vue?vue&type=style&index=0&id=67f208d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f208d0",
  null
  
)

/* custom blocks */
import block0 from "./ContactCreateUpdate.vue?vue&type=custom&index=0&blockType=messages"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VIcon,VRow,VStepper,VStepperContent,VStepperStep,VSubheader,VSwitch,VTooltip})
