<messages>["./Input"]</messages>
<template>
  <v-combobox
    ref="input"
    v-model="internalValue"
    append-icon=""
    :label="label"
    :hint="hint"
    :search-input="input"
    multiple
    clearable
    :error-messages="[
      ...errorMessages,
      ...additionalErrorMessages]"
    @input="onInput">
    <template #selection="data">
      <v-chip
        :key="JSON.stringify (data.item)"
        close
        outlined
        small
        :color="test(data.item) ? 'black': 'error'"
        class="my-1"
        @click="onEdit (data)"
        @update:active="onDelete (data)">
        <span
          class="chipContent"
          :title="data.item">
          {{ data.item }}
        </span>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {
    name: 'MultiTagInput',

    mixins: [validationMixins],

    props: {
      value: {
        type: Array,
        default: () => []
      },

      label: {
        type: String,
        default: ''
      },

      hint: {
        type: String,
        default: undefined
      },

      errorMap: {
        type: [Array, Object],
        default: () => []
      },

      validationProp: {
        type: Object,
        default: () => ({})
      },

      additionalErrorMessages: {
        type: Array,
        default: () => []
      }
    },

    data () {
      return {
        input: ''
      }
    },

    validations () {
      return {
        internalValue: {
          $each: {
            ...this.validationProp
          }
        }
      }
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      errorMessages () {
        if (this.$v.$invalid) {
          const errors = []
          Object.keys (this.$v.internalValue.$each.$iter).forEach ((key) => {
            const validationObject = this.$v.internalValue.$each.$iter[key]
            for (const [validationType, messageAttribute] of
            Object.entries (this.errorMap)) {
              if (validationObject[validationType] === false ||
                validationObject[validationType].$invalid) {
                const message =
                  (typeof messageAttribute === 'string')
                    ? this.$t (messageAttribute)
                    : this.$t (messageAttribute.key, messageAttribute.params)
                errors.push (message)
              }
            }
          })
          return errors
        } else {
          return []
        }
      }
    },

    methods: {

      /**
       * Validate data and emit an input event on data input.
       */
      onInput (value) {
        this.$v.$touch ()
        this.$emit ('input', value.map (s => s.trim ()))
        this.input = ''
      },

      /**
       * Edit an entered IP address.
       *
       * @param {Object} data     the data record representing the IP to be
       *                          edited
       */
      onEdit (data) {
        this.input = data.item
        this.onDelete (data)
      },

      test (val) {
        return Object.keys (this.validationProp).every ((key) => {
          const prop = this.validationProp[key]

          if (typeof prop === 'function') {
            return prop (val)
          }
          if (prop instanceof RegExp) {
            return prop.test (val)
          }
          return true
        })
      },

      /**
       * Delete an entered IP address.
       *
       * @param {Object} data     the data record representing the IP to be
       *                          deleted
       */
      onDelete (data) {
        const newVal = [...this.value]

        newVal.splice (data.index, 1)
        this.internalValue = newVal
      },

      focus () {
        this.$refs.input.focus ()
      },

      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return !this.internalValue.every ((val) => {
          return this.test (val)
        })
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return this.internalValue.every ((val) => {
          return this.test (val)
        })
      },

      /**
       * Validate the given field.
       *
       * @param {Object} field      the meta data object identifying the field
       *                            to be validated
       */
      doValidate () {
        this.$v.$touch ()
      }

    }
  }
</script>
