<messages>["../Domain"]</messages>

<template>
  <v-autocomplete
    ref="domainState"
    v-model="internalState"
    clearable
    :loading="isLoading"
    :label="$t ('filter.domainState')"
    :items="stateOptions"/>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'DomainStatesFilter',

    props: {
      value: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        stateOptions: [],
        isLoading: false
      }
    },

    computed: {
      internalState: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      }
    },

    created () {
      this.isLoading = true
      this.getDomainStates ().then ((states) => {
        this.stateOptions = states
        this.isLoading = false
      })
    },

    methods: {
      ...mapActions ({
        getDomainStates: 'cache/getDomainStates'
      }),

      focus () {
        this.$refs.domainState.focus ()
      }
    }
  }
</script>
