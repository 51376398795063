<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <div>
    <v-row
      v-for="(fieldGroup, idx) in specificFieldGroups"
      :key="`field-group-${idx}`"
      class="mb-4">
      <v-col
        v-for="field in fieldGroup"
        :key="field.key"
        cols="6"
        class="pa-2"
        :class="colorClass (field)">
        <v-row>
          <v-col :class="[field.multiValued ? 'col-9' : 'col-12']">
            <v-tooltip
              bottom
              :disabled="!field.example && !field.validationData.pattern">
              <template #activator="{on}">
                <v-textarea
                  v-if="field.type === 'textarea'"
                  v-model.trim="field.value"
                  :class="{required: isRequired (field)}"
                  v-bind="inputProps (field)"
                  v-on="on"
                  @blur="doValidate (field)"
                  @input="updateData"/>
                <v-text-field
                  v-else
                  v-model.trim="field.value"
                  :class="{required: isRequired (field)}"
                  v-bind="inputProps (field)"
                  v-on="on"
                  @blur="doValidate (field)"
                  @input="updateData"/>
              </template>
              <span>{{ tooltipText (field) }}</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="field.multiValued" cols="3" class="text-right">
            <v-btn
              v-if="isMax (field)"
              icon
              class="px-0 mx-0"
              @click="onAdd (field)">
              <v-icon>add</v-icon>
            </v-btn>
            <v-btn
              v-if="!isSingleton (field)"
              icon
              class="px-0 mx-0"
              @click="onDelete (field)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {required} from 'vuelidate/lib/validators'

  import {keyValueArrayToObject} from '@/app/utils/array'
  import {regExp} from '@/app/validators'

  export default {
    name: 'RegistrySpecificData',

    props: {
      value: {
        type: Array,
        default: () => []
      }
    },

    data () {
      return {
        colorClasses: [
          'cgwng-bg-color-1', 'cgwng-bg-color-2', 'cgwng-bg-color-3'
        ]
      }
    },

    computed: {
      validationFields () {
        return keyValueArrayToObject (this.value)
      },

      specificFieldGroups () {
        const fieldGroups = []
        let singleValued = []
        let multiValued = []

        this.value.forEach (field => {
          if (!field.multiValued) {
            singleValued.push (field)
          } else {
            if (this.isFirst (field)) {
              if (singleValued.length > 0) {
                fieldGroups.push (singleValued)
                singleValued = []
              }

              multiValued = [field]
            } else {
              multiValued.push (field)
            }

            if (this.isMax (field)) fieldGroups.push (multiValued)
          }
        })

        if (singleValued.length > 0) fieldGroups.push (singleValued)
        return fieldGroups
      }
    },

    watch: {
      '$v.validationFields': {
        handler (v) {
          this.$emit ('validation', Object.keys (v.$params).reduce (
            function (valid, p) {
              return valid && !v[p].$error
            },
            true))
        },
        deep: true
      }
    },

    validations () {
      const vf = {}

      for (const field in this.validationFields) {
        if (this.validationFields.hasOwnProperty (field)) {
          const fieldData = this.value.find (element => element.key === field)

          vf[field] = {}

          if (fieldData) {
            const validationData = fieldData.validationData

            if (validationData?.pattern) {
              const exactMatchPattern = `^${validationData.pattern}$`

              vf[field] = Object.assign ({
                regExp: validationData.caseSensitive
                  ? regExp (new RegExp (exactMatchPattern))
                  : regExp (new RegExp (exactMatchPattern, 'i'))
              }, vf[field])
            }

            if (validationData?.required) {
              vf[field] = Object.assign ({required}, vf[field])
            }
          }
        }
      }

      return {validationFields: vf}
    },

    methods: {
      /**
       * Determine the background color class for the given registry-specific
       * field.
       *
       * @param {Object} field      the field to determine the background
       *                            color for
       * @return {String}           the background color class
       */
      colorClass (field) {
        const idx = this.value.indexOf (field)
        if (idx === 0) return this.colorClasses[0]

        const numberOfClasses = this.colorClasses.length

        if (field.multiValued) {
          const {s: keyIndex} = this.getPrefixAndSuffix (field.key)

          if (+keyIndex === 1) {
            return this.colorClasses[idx % numberOfClasses]
          } else {
            // same color for all instances of multi-valued fields
            return this.colorClass (this.value[idx - 1])
          }
        } else {
          let lastIndex =
            this.colorClasses.indexOf (this.colorClass (this.value[idx - 1]))

          return this.colorClasses[++lastIndex % numberOfClasses]
        }
      },

      /**
       * Decompose the given (Payload) key into prefix and suffix.
       *
       * The prefix is the key part before the last dot, and the suffix is the
       * key part after the last dot.
       *
       * Note that for multi-valued fields, the suffix corresponds to the index
       * of the field instance.
       *
       * @param {String} key    the key to decompose
       * @return {Object}       an object containing the prefix p and the
       *                        suffix s
       */
      getPrefixAndSuffix (key) {
        const lastDotPosition = key.lastIndexOf ('.')

        return {
          p: key.substring (0, lastDotPosition),
          s: key.substring (lastDotPosition + 1)
        }
      },

      /**
       * Check whether the specified field is required.
       *
       * @param {Object} field      the field to check
       * @return {Boolean}          {@code true} if the field is required,
       *                            {@code false} otherwise
       */
      isRequired (field) {
        return field?.validationData?.required
      },

      /**
       * Check whether the specified field is the only instance of its type.
       *
       * For non-multi-valued fields, this method will always return true.
       *
       * @param {Object} field      the field to check
       * @return {Boolean}          true if the field is the only one of its
       *                            type, false if at least one more field of
       *                            the type exists
       */
      isSingleton (field) {
        if (!field.multiValued) return true

        const {p: givenKey, s: givenIndex} = this.getPrefixAndSuffix (field.key)

        return !this.value.some (f => {
          const {p: key, s: index} = this.getPrefixAndSuffix (f.key)
          return givenKey === key && givenIndex !== index
        })
      },

      /**
       * Check whether the specified field is the first instance of its type,
       * i.e., in case of multi-valued fields, the instance with the key
       * index 1.
       *
       * Non-multi-valued fields are always considered the first instance of
       * their type.
       *
       * @param {Object} field      the field to check
       * @return {Boolean}          true if the field is non-multi-valued or
       *                            the first instance of its type; else false
       */
      isFirst (field) {
        return !field.multiValued || field.key.endsWith ('1')
      },

      /**
       * Check whether the specified field is the "maximum instance" of its
       * type, i.e., in case of multi-valued fields, the instance with the
       * maximum key index.
       *
       * Non-multi-valued fields are always considered the "maximum instance"
       * of their type.
       *
       * @param {Object} field      the field to check
       * @return {Boolean}          true if the field is non-multi-valued or
       *                            the maximum instance of its type; false if
       *                            at least one field with a greater key
       *                            index exists
       */
      isMax (field) {
        if (!field.multiValued) return true

        const {s: givenIndex} = this.getPrefixAndSuffix (field.key)

        const {s: maxIndex} =
          this.getPrefixAndSuffix (this.getMaxField (field).key)

        return givenIndex === maxIndex
      },

      /**
       * Get the "maximum instance" of the type of the given field, i.e., in
       * case of multi-valued fields, the instance with the maximum key index.
       *
       * Non-multi-valued fields are always considered the "maximum instance"
       * of their type.
       *
       * @param {Object} field    the field for which the maximum instance
       *                          shall be found
       * @return {Object}         the maximum instance of the given field's
       *                          type; will be the given field if it is
       *                          non-multi-valued ot already the maximum
       *                          instance
       */
      getMaxField (field) {
        if (!field.multiValued) return field

        const {p: keyPrefix} = this.getPrefixAndSuffix (field.key)

        return this.value.reduce ((max, f) => {
          const {s: maxIndex} = this.getPrefixAndSuffix (max.key)
          const {p: key, s: index} = this.getPrefixAndSuffix (f.key)

          return (key !== keyPrefix || +index < +maxIndex) ? max : f
        }, field)
      },

      /**
       * Delete the given field.
       *
       * Only instances of multi-valued fields may be deleted.
       *
       * On deletion, the indices of subsequent field instances are decremented
       * in order to guarantee a gapless index sequence.
       *
       * @param {Object} field      the field to delete
       */
      onDelete (field) {
        if (!field.multiValued) return

        const {p: givenKey, s: givenIndex} = this.getPrefixAndSuffix (field.key)
        let idxToRemove = null

        this.value.forEach ((f, idx) => {
          const {p: key, s: index} = this.getPrefixAndSuffix (f.key)

          if (key === givenKey) {
            if (index === givenIndex) {
              idxToRemove = idx
            } else if (+index > +givenIndex) {
              f.key = key + '.' + (+index - 1)
            }

            this.updateData ()
          }
        })

        if (idxToRemove !== null) {
          this.value.splice (idxToRemove, 1)
        }
      },

      /**
       * Add a field instance of the same type as the given field.
       *
       * Only instances of multi-valued fields may be added.
       *
       * @param {Object} field      the field to use as a template for the
       *                            field to add
       */
      onAdd (field) {
        if (!field.multiValued) return

        const lastField = this.getMaxField (field)
        const lastFieldIndex = this.value.indexOf (lastField)
        const {p: key, s: index} = this.getPrefixAndSuffix (lastField.key)

        this.value.splice (lastFieldIndex + 1, 0, {
          key: key + '.' + (+index + 1),
          name: field.name,
          value: '',
          description: field.description,
          example: field.example,
          validationData: field.validationData,
          multiValued: field.multiValued
        })

        this.updateData ()
      },

      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        const valFields = this.$v.validationFields

        return Object.keys (valFields.$params).reduce (
          function (valid, p) {
            return valid || valFields[p].$error
          }, false)
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        const valFields = this.$v.validationFields

        return Object.keys (valFields.$params).reduce (
          function (valid, p) {
            return valid && !valFields[p].$invalid
          }, true)
      },

      /**
       * Validate the given field.
       *
       * @param {Object} field      the meta data object identifying the field
       *                            to be validated
       */
      doValidate (field) {
        this.$v.validationFields[field.key].$touch ()
      },

      /**
       * Fire an "input" event in case the form data has been updated.
       */
      updateData () {
        this.$emit ('input', this.value)
      },

      inputProps (field) {
        return {
          class: field.required ? 'required' : '',
          slot: 'activator',
          spellcheck: false,
          label: (field.name || field.key),
          hint: field.description,
          'persistent-hint': true,
          'error-messages': this.getValidationErrors (field)
        }
      },

      /**
       * Determine the validation errors for the given field (if any).
       *
       * @param {Object} field      the meta data object identifying the field
       *                            to be validated
       * @return {Array}            the validation errors; may be emtpy if no
       *                            errors exist
       */
      getValidationErrors (field) {
        const valField = this.$v.validationFields[field.key]
        const errors = []

        if (valField?.$dirty) {
          'required' in valField && !valField.required && errors.push (
            this.$t ('general.required'))

          const regExpString = this.value.find (element => {
            return element.key === field.key
          }).validationData.pattern

          'regExp' in valField && !valField.regExp && errors.push (
            this.$t ('general.invalid.regExp', {
              regExp: this.prepareRegExpForDisplay (regExpString)
            })
          )
        }

        return errors
      },

      /**
       * Improve the display of the given regular expression string by allowing
       * line breaks at certain positions.
       *
       * Technically, this method adds zero-width non-joiners after pipes and
       * closing parentheses in order to allow line breaks at these positions
       * in the regular expression string.
       *
       * @param {String} regExpString     the regular expression string to be
       *                                  displayed
       * @return {String}                 the regular expression string prepared
       *                                  for display
       */
      prepareRegExpForDisplay (regExpString) {
        return regExpString.replace (/([|)])/g, '$&\u200C')
      },

      /**
       * Prepare the tooltip text for the given field.
       * @param {Object} field       the field
       * @return {String}            the tooltip text
       */
      tooltipText (field) {
        let text
        const example = 'example: ' + field.example
        const pattern = 'pattern: ' + field.validationData?.pattern

        if (field.example) {
          text = example

          if (field.validationData?.pattern) {
            text += '; ' + pattern
          }
        } else if (field.validationData?.pattern) {
          text = pattern
        }

        return text
      }
    }
  }
</script>
