<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <div>
    <v-row
      v-for="(host, idx) in internalHosts"
      :key="idx"
      class="cgwng-bg-color-1">
      <v-col cols="12" sm="5">
        <v-text-field
          v-model.trim="host.name"
          class="host-data required"
          :label="$t ('create.label.host.name')"
          :error-messages="nameErrors (idx)"
          @blur="$v.internalHosts.$each[idx].name.$touch ()"/>
      </v-col>
      <v-col cols="12" sm="5">
        <multi-ip-input
          v-model="host.ips"
          class="host-data"
          :label="$t ('create.label.host.ips')"/>
      </v-col>
      <v-col cols="12" sm="2" align-self="center" class="text-no-wrap">
        <v-tooltip
          v-if="allowDeletion"
          top>
          <template #activator="{ on }">
            <v-btn
              top
              small
              icon
              class="ma-1"
              v-on="on"
              @click="onDelete (idx)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span v-t="'create.label.host.delete'"/>
        </v-tooltip>
        <v-tooltip
          v-if="idx === internalHosts.length - 1"
          top>
          <template #activator="{ on }">
            <v-btn
              top
              small
              icon
              class="ma-1"
              v-on="on"
              @click="onAdd">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span v-t="'create.label.host.add'"/>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {requiredIf} from 'vuelidate/lib/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import MultiIpInput from '@/app/core/components/Inputs/MultiIpInput'
  import IpAddressesValidation
    from '@/app/core/components/Inputs/IpAddressesValidation'

  /**
   * Create an empty object for host representation.
   *
   * @return {Object}     the empty host object
   */

  export const emptyHostElement = () => {
    return {name: '', ips: []}
  }

  export const defaultValue = () => ([
    emptyHostElement (),
    emptyHostElement ()
  ])

  export default {
    name: 'DomainHostData',

    components: {
      MultiIpInput
    },

    mixins: [validationMixins],

    props: {
      value: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        internalHosts: []
      }
    },

    computed: {
      allowDeletion () {
        return this.internalHosts.length > 1
      }
    },

    watch: {
      value () {
        this.populateInternalHostsObject ()
      },

      internalHosts () {
        this.$emit ('input', this.internalHosts)
      }
    },

    validations: {
      internalHosts: {
        $each: {
          name: {
            required: requiredIf (function (host) {
              return host.ips.length > 0
            })
          },
          ips: IpAddressesValidation
        }
      }
    },

    created () {
      this.populateInternalHostsObject ()
    },

    methods: {
      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$v.internalHosts.$error
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return !this.$v.internalHosts.$invalid
      },

      /**
       * Determine the error messages to display for the name field of the
       * specified host.
       *
       * @param {Number} idx      the index of the host to determine the error
       *                          messages for
       * @return {Array}          the error messages
       */
      nameErrors (idx) {
        const validationObject = this.$v.internalHosts
        const errors = []

        // only display error when "dirty" flag is set for name field
        if (!validationObject.$each[idx].name.$dirty) {
          return errors
        }

        if (!validationObject.$each[idx].name.required) {
          errors.push (this.$t ('create.required.hostName'))
        }

        return errors
      },

      /**
       * Delete the host with the given index.
       *
       * @param idx {Number}      the index of the host to delete
       */
      onDelete (idx) {
        this.internalHosts.splice (idx, 1)
      },

      /**
       * Add a new (empty) host object.
       */
      onAdd () {
        this.internalHosts.push (emptyHostElement ())
      },

      /**
       * Use the hosts passed as a prop from the parent as the internal hosts
       * representation; add an empty host element in case the passed array is
       * empty.
       */
      populateInternalHostsObject () {
        if (this.value.length === 0) {
          this.internalHosts = defaultValue ()
        } else {
          this.internalHosts = this.value
        }
      }
    }
  }
</script>
