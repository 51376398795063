<messages>["../Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <search-filter-wrapper
    v-model="filterVisibilityModel"
    :type="type"
    :quick-filter-items="quickFilterItems"
    :additional-quick-filter-items="additionalQuickFilterItems"
    :search-disabled="searchDisabled"
    @deleteFilter="onDeleteFilter"
    @deleteAdditionalFilter="onDeleteAdditionalFilter"
    @reset="resetSearchFilter"
    @search="doSearch"
    @focus="focus">
    <template #basic>
      <!-- domain name filters -->
      <v-row>
        <v-col cols="12">
          <v-text-field
            ref="domainName"
            v-model.trim="filterParams.domainName"
            :label="$t ('filter.domainName')"/>
        </v-col>
        <v-col
          v-if="mayViewAllObjects || (mayViewSubEntitiesObjects && hasSubClients)"
          cols="12" sm="12">
          <client-select
            ref="clientId"
            v-model="filterParams.clientId"
            show-inactive
            for-view
            nullable/>
        </v-col>
        <v-col
          cols="12"
          :class="{'col-sm-12': (mayViewAllObjects || (mayViewSubEntitiesObjects && hasSubClients))}">
          <registry-select
            ref="registryTypes"
            v-model="filterParams.registryTypes"
            include-inactive
            multiple
            clearable
            :label="$t ('filter.registryIds')"
            :hint="$t ('filter.registryIdsHint')"/>
        </v-col>
      </v-row>
    </template>
    <template #additional>
      <div class="additionalFilters pt-6">
        <!-- date filters -->
        <v-row
          v-for="(date, idx) in filterParams.dates"
          :key="`d-${date.id}`">
          <v-col>
            <v-row>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  ref="dates"
                  v-model="date.action"
                  :label="$t ('filter.date.action.label')"
                  :items="domainActions"
                  :error-messages="dateFilterActionErrors($v.filterParams.dates, idx)"/>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="date.timeRel"
                  :items="timeRelations"/>
              </v-col>
              <v-col cols="12" sm="5">
                <date-picker-field
                  v-model="date.date"
                  clearable
                  type="date"
                  :label="$t ('filter.date.label')"
                  :error-messages="dateFilterDateErrors($v.filterParams.dates, idx)"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="auto"
            align-self="center">
            <filter-delete-button
              :show-delete="filterParams.dates.length > 1"
              :disabled="!date.action && !date.date"
              @delete="onDeleteDate (date.id)"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="align-content-space-around"
            cols="5">
            <v-btn
              v-t="'filter.date.add'"
              small
              @click="onAddDate ()"/>
          </v-col>
        </v-row>

        <v-row class="shorten mt-6">
          <v-col>
            <apex-select
              ref="domainEnding"
              v-model="filterParams.domainEnding"
              :label="$t ('filter.domainEnding')"/>
          </v-col>
        </v-row>

        <!-- host filters -->
        <v-row
          v-if="showHostFilter"
          class="shorten">
          <v-col cols="12" sm="4">
            <v-text-field
              ref="hostName"
              v-model.trim="filterParams.hostName"
              :label="$t ('filter.hostName')"/>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              ref="hostNamePunycode"
              v-model.trim="filterParams.hostNamePunycode"
              :label="$t ('filter.hostNamePunycode')"/>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              ref="hostIpAddress"
              v-model.trim="filterParams.hostIpAddress"
              :label="$t ('filter.hostIpAddress')"
              :error-messages="validationErrors(
                'filterParams.hostIpAddress', {
                  ip: 'general.invalid.ip'
                })"
              @blur="$v.filterParams.hostIpAddress.$touch()"/>
          </v-col>
        </v-row>

        <h3
          v-t="'filter.section.contact'"
          class="pa-0 mb-6 mt-0"/>

        <v-row
          v-for="contact in filterParams.contacts"
          :key="`c-${contact.id}`">
          <!-- contact filters -->
          <v-col>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  ref="contacts"
                  v-model="contact.role"
                  :label="$t ('filter.contact.role.label')"
                  :items="contactRoles"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="contact.handle"
                  :label="$t ('filter.contact.handle')"
                  :items="handleItems"/>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="contact.name"
                  :label="$t ('filter.contact.name')"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="auto"
            align-self="center">
            <filter-delete-button
              :show-delete="filterParams.contacts.length > 1"
              :disabled="contact.role === -1 && !contact.name && contact.handle"
              @delete="onDeleteContact (contact.id)"/>
          </v-col>
        </v-row>

        <v-row class="mb-6">
          <v-col cols="12" xl="4">
            <v-btn
              v-t="'filter.contact.add'"
              small
              @click="onAddContact ()"/>
          </v-col>
        </v-row>

        <!-- registrar filters -->
        <template v-if="!hasClient">
          <v-subheader
            v-t="'filter.section.registrar'"
            class="pa-0"
            ma-0/>
          <v-row
            v-for="(registrar, idx) in filterParams.registrars"
            :key="`r-${registrar.id}`">
            <v-col cols="12" sm="5" lg="4" xl="12">
              <v-autocomplete
                ref="registrars"
                v-model="registrar.role"
                :label="$t ('filter.registrar.role.label')"
                :items="registrarRoles"
                :error-messages="registrarFilterRoleErrors(
                  $v.filterParams.registrars, idx)"/>
            </v-col>
            <v-col cols="10" sm="5" lg="7" xl="10">
              <v-autocomplete
                v-model="registrar.name"
                :label="$t ('filter.registrar.name')"
                :items="availableRegistrars"
                :error-messages="registrarFilterNameErrors (
                  $v.filterParams.registrars, idx)"/>
            </v-col>
            <v-col cols="1">
              <filter-delete-button
                :show-delete="filterParams.registrars.length > 1"
                :disabled="!registrar.role && !registrar.name"
                @delete="onDeleteRegistrar (registrar.id)"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                v-t="'filter.registrar.add'"
                small
                @click="onAddRegistrar ()"/>
            </v-col>
          </v-row>
        </template>

        <!-- domain life cycle filter (former domain state filter) -->

        <v-row class="shorten">
          <v-col v-if="showHostFilter" cols="12" sm="6" lg="6" xl="12">
            <lock-status-filter
              ref="lockStatus"
              v-model="filterParams.lockStatus"/>
          </v-col>
          <v-col v-if="showHostFilter" cols="12" sm="6" lg="6" xl="12">
            <phase-selector
              ref="launchPhase"
              v-model="filterParams.launchPhase"
              :selected-registries="filterParams.registryTypes"/>
          </v-col>
          <v-col cols="12" sm="6" lg="6" xl="12">
            <v-autocomplete
              ref="domainLifeCycle"
              v-model="filterParams.domainLifeCycle"
              :label="$t ('filter.domainLifeCycle')"
              :items="domainStates"/>
          </v-col>
          <v-col cols="12" sm="6" lg="6" xl="12">
            <application-status-filter
              v-if="type === TYPES.APPLICATION"
              ref="applicationStatuses"
              v-model="filterParams.applicationStatuses"/>
            <states-filter
              v-else
              ref="domainState"
              v-model="filterParams.domainState"/>
          </v-col>
        </v-row>
      </div>
    </template>
  </search-filter-wrapper>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters, mapActions} from 'vuex'

  import {requiredIf} from 'vuelidate/lib/validators'
  import {ip} from '@/app/validators'

  import DatePickerField from '@/app/core/components/DatePickerField'
  import RegistrySelect from '@/app/core/components/RegistrySelect'

  import ApexSelect
    from '@/app/core/components/RegistryObject/domain/ApexSelect'

  import SearchFilterWrapper
    from '@/app/core/components/Search/SearchFilterWrapper'

  import ClientSelect from '@/app/core/components/ClientSelect'

  import FilterDeleteButton
    from '@/app/core/components/Search/FilterDeleteButton'

  import ApplicationStatusFilter
    from '@/app/pages/Domain/components/ApplicationStatusFilter'

  import PhaseSelector
    from '@/app/pages/Domain/components/PhaseSelector'

  import StatesFilter
    from './DomainStatesFilter.vue'

  import LockStatusFilter from './LockStatusFilter'

  import SearchFilterMixinCreator from '@/app/core/mixins/SearchFilterCreator'
  import InputHelper from '@/app/core/mixins/InputHelper'
  import QuickFilterMixin from '@/app/core/mixins/QuickFilterMixin'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import {TYPES, typeProp} from '../ApplicationSupport'

  import {getLockStatusLabel, formatLauchPhase} from '../constants'

  const ApplicationActions = ['create', 'update']
  const DomainActions = ['create', 'update', 'transfer', 'expire']

  /**
   * default domain search filter parameters
   * @type {Object}
   */
  export const defaultValue = () => ({
    domainName: '',
    domainEnding: '',
    registryTypes: [],
    dates: [{id: 0, action: '', timeRel: 'on', date: null}],
    contacts: [{id: 0, role: -1, name: '', handle: true}],
    registrars: [{id: 0, role: '', name: ''}],
    hostName: '',
    hostNamePunycode: '',
    hostIpAddress: '',
    domainLifeCycle: 'any',
    domainState: '',
    applicationStatuses: [],
    clientId: null,
    lockStatus: null,
    launchPhase: []
  })

  /**
   * get the filter to find domains in any state, which associated with
   * specified contact
   *
   * @param registry    domain registry
   * @param contact     the name of associated contact
   * @return {Object}   domain search filter object
   */
  export function byRegistryContact (registry, contact) {
    return {
      registryTypes: [registry],
      contacts: [{id: 1, role: -1, name: contact, handle: true}],
      domainLifeCycle: 'any'
    }
  }

  /**
   * get the filter to find domains in any state, which a specific domain name
   *
   * @param domainName     the name domain to search
   * @return {Object}      domain search filter object
   */
  export function byDomainName (domainName) {
    return {
      domainName,
      domainLifeCycle: 'any'
    }
  }

  /**
   * get the filter to find domains in any state, which belong to specified
   * registry and associated with specified host name
   *
   * @param registry    domain registry
   * @param hostName    the name of associated host
   * @return {Object}   domain search filter object
   */
  export function byRegistryHostName (registry, hostName) {
    return {
      registryTypes: [registry],
      hostName,
      domainLifeCycle: 'any'
    }
  }

  export default {
    name: 'DomainFilter',

    components: {
      ApplicationStatusFilter,
      ApexSelect,
      RegistrySelect,
      DatePickerField,
      FilterDeleteButton,
      ClientSelect,
      SearchFilterWrapper,
      StatesFilter,
      LockStatusFilter,
      PhaseSelector
    },

    mixins: [
      SearchFilterMixinCreator (defaultValue),
      InputHelper,
      QuickFilterMixin,
      validationMixins
    ],

    props: {
      type: typeProp
    },

    data () {
      return {
        TYPES,
        additionalFilterKeys: [
          'domainEnding',
          'dates',
          'contacts',
          'registrars',
          'hostName',
          'hostNamePunycode',
          'hostIpAddress',
          'domainLifeCycle',
          'domainState',
          'applicationStatuses',
          'lockStatus',
          'launchPhase'
        ],
        basicFilter: ['domainName', 'clientId', 'registryTypes'],
        customHandlerKeys: ['registrars', 'contacts', 'applicationStatuses', 'lockStatus', 'launchPhase'],
        maxDateId: 0,
        maxContactId: 0,
        maxRegistrarId: 0,
        availableRegistrars: [],
        availableContactRoles: []
      }
    },

    validations: {
      filterParams: {
        hostIpAddress: {ip},
        dates: {
          $each: {
            action: {
              required: requiredIf (function (dateFilter) {
                return dateFilter.date
              })
            },
            date: {
              required: requiredIf (function (dateFilter) {
                return dateFilter.action
              })
            }
          }
        },
        registrars: {
          $each: {
            role: {
              required: requiredIf (function (registrarFilter) {
                return this.filterParams.registrars.length > 1 ||
                  registrarFilter.name
              })
            },
            name: {
              required: requiredIf (function (registrarFilter) {
                return this.filterParams.registrars.length > 1 ||
                  registrarFilter.role
              })
            }
          }
        }
      }
    },

    computed: {
      ...mapGetters ({
        hasClient: 'auth/hasClient',
        mayViewAllObjects: 'auth/mayViewAllObjects',
        mayViewSubEntitiesObjects: 'auth/mayViewSubEntitiesObjects',
        hasSubClients: 'auth/hasSubClients'
      }),

      registrarRoles () {
        return ['sponsor', 'creator', 'updater'].map (r => ({
          text: this.$t (`filter.registrar.role.${r}`),
          value: r
        }))
      },

      showHostFilter () {
        return this.type === TYPES.DOMAIN
      },

      domainActions () {
        let actions

        switch (this.type) {
          case TYPES.APPLICATION:
            actions = ApplicationActions
            break

          case TYPES.DOMAIN:
            actions = DomainActions
            break
        }

        return actions.map (a => ({
          text: this.$t (`filter.date.action.${a}`),
          value: a
        }))
      },

      timeRelations () {
        return ['on', 'before', 'after'].map (r => ({
          text: this.$t (`filter.date.timeRel.${r}`),
          value: r
        }))
      },

      domainStates () {
        let states

        switch (this.type) {
          case TYPES.APPLICATION:
            states = [
              'active', 'deleted', 'any'
            ]

            break

          case TYPES.DOMAIN:
            states = [
              'active', 'deleted', 'pendingDeletion',
              'deletedOrPendingDeletion',
              'any'
            ]
        }

        return states.map (s => ({
          text: this.$t (`filter.${s}`),
          value: s
        }))
      },

      basicFilterLabels () {
        return this.basicFilter.map ((label) => {
          return this.$t (`filter.${label}`)
        })
      },

      /**
       * the available contact roles
       */
      contactRoles () {
        return [
          {
            text: this.$t ('filter.contact.role.any'),
            value: -1
          },
          ...this.availableContactRoles
            ? this.availableContactRoles.map (r => ({
              text: this.$t (`filter.contact.role.${r.name}`),
              value: r.id
            })).sort ((a, b) => a.text.localeCompare (b.text))
            : []
        ]
      },

      handleItems () {
        return [
          ...['handle', 'name'].map ((c) => ({
            value: c === 'handle',
            text: this.$t (`filter.contact.${c}`)
          }))
        ]
      }
    },

    watch: {
      'filterParams.dates' (newArray) {
        if (newArray.length === 0) {
          this.maxDateId = 0
          this.filterParams.dates.push (this.newDateObject (this.maxDateId))
        } else if (newArray.length === 1) {
          const emptyDateObject = this.newDateObject (0)
          const newArrayEl = newArray[0]

          if (newArrayEl.action === emptyDateObject.action &&
            newArrayEl.timeRel === emptyDateObject.timeRel &&
            newArrayEl.date === emptyDateObject.date) {
            this.maxDateId = 0
            this.filterParams.dates[0].id = this.maxDateId
          }
        }
      },

      'filterParams.contacts' (newArray) {
        if (newArray.length === 0) {
          this.maxContactId = 0

          this.filterParams.contacts.push (
            this.newContactObject (this.maxContactId))
        } else if (newArray.length === 1) {
          const emptyContactObject = this.newContactObject (0)
          const newArrayEl = newArray[0]

          if (newArrayEl.role === emptyContactObject.role &&
            newArrayEl.name === emptyContactObject.name) {
            this.maxContactId = 0
            this.filterParams.contacts[0].id = this.maxContactId
          }
        }
      },

      'filterParams.registrars' (newArray) {
        if (newArray.length === 0) {
          this.maxRegistrarId = 0

          this.filterParams.registrars.push (
            this.newRegistrarObject (this.maxRegistrarId))
        } else if (newArray.length === 1) {
          const emptyRegistrarObject = this.newRegistrarObject (0)
          const newArrayEl = newArray[0]

          if (newArrayEl.role === emptyRegistrarObject.role &&
            newArrayEl.name === emptyRegistrarObject.name) {
            this.maxRegistrarId = 0
            this.filterParams.registrars[0].id = this.maxRegistrarId
          }
        }
      }
    },

    created () {
      this.loadRegistrars ()
      this.loadContactRoles ()
    },

    methods: {
      // --- mapped actions and mutations from vuex ----------------------------
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      // --- event handlers ----------------------------------------------------

      /**
       * Handle an add date filter event.
       */
      onAddDate () {
        this.maxDateId++
        this.filterParams.dates.push (this.newDateObject (this.maxDateId))
      },

      /**
       * Handle a delete date filter event.
       *
       * @param {Number} id     the ID of the date filter to be closed
       */
      onDeleteDate (id) {
        this.filterParams.dates.splice (
          this.getArrayIndex (this.filterParams.dates, id), 1)
      },

      /**
       * Handle an add contact filter event.
       */
      onAddContact () {
        this.maxContactId++

        this.filterParams.contacts.push (
          this.newContactObject (this.maxContactId))
      },

      /**
       * Handle a delete contact filter event.
       *
       * @param {Number} id     the ID of the contact filter to be closed
       */
      onDeleteContact (id) {
        this.filterParams.contacts.splice (
          this.getArrayIndex (this.filterParams.contacts, id), 1)
      },

      /**
       * Handle an add registrar filter event.
       */
      onAddRegistrar () {
        this.maxRegistrarId++

        this.filterParams.registrars.push (
          this.newRegistrarObject (this.maxRegistrarId))
      },

      /**
       * Handle a delete registrar filter event.
       *
       * @param {Number} id     the ID of the registrar filter to be closed
       */
      onDeleteRegistrar (id) {
        this.filterParams.registrars.splice (
          this.getArrayIndex (this.filterParams.registrars, id), 1)
      },

      customQuickFilterHandler (key, value, qFItems) {
        if (key === 'lockStatus') {
          qFItems.push ({
            prop: key,
            label: this.$t ('view.label.locking.lockStatus'),
            text: this.$t (getLockStatusLabel (value))
          })
          return
        }

        value.forEach ((val, idx) => {
          let text = val
          let label = key

          switch (key) {
            case 'contacts':
              if (!val.name) {
                return
              }
              if (val.role === -1 || val.role === '-1') {
                label = this.$t ('filter.contact.contact')
                text = val.name
              } else {
                const role = this.availableContactRoles.find (
                  it => it.id === val.role)
                const roleName = role?.name || val.role
                label = this.$t (`filter.contact.role.${roleName}`)
                text = val.name
              }
              break

            case 'registrars':
              label = this.$t (`filter.registrar.role.${val.role}`)
              text = val.name
              break

            case 'applicationStatuses':
              label = this.$t ('view.label.state')
              text =
                this.$t (`view.label.applicationState.${val.toLowerCase ()}`)
              break

            case 'launchPhase':
              label = this.$t ('create.label.launchPhase')
              text = val ? formatLauchPhase (val) : this.$t ('filter.noLaunchPhase')
              break
          }

          qFItems.push ({
            prop: key,
            idx,
            label,
            text
          })
        })
      },

      // --- filter object creation --------------------------------------------

      /**
       * Create a new date filter object
       *
       * @param {Number} id      the ID of the object to create
       * @return {Object}        the newly created filter object
       */
      newDateObject (id) {
        return {id: id, action: '', timeRel: 'on', date: null}
      },

      /**
       * Create a new contact filter object
       *
       * @param {Number} id      the ID of the object to create
       * @return {Object}        the newly created filter object
       */
      newContactObject (id) {
        return {id: id, role: -1, name: '', handle: true}
      },

      /**
       * Create a new registrar filter object
       *
       * @param {Number} id      the ID of the object to create
       * @return {Object}        the newly created filter object
       */
      newRegistrarObject (id) {
        return {id: id, role: '', name: ''}
      },

      // --- helper methods ----------------------------------------------------

      /**
       * Get the first index in the given array at which position an object with
       * the given ID is found.
       *
       * @param {Array} array     the array
       * @param {Number} id       the ID of the object to find
       * @return {Number}         the position at which the object was found
       */
      getArrayIndex (array, id) {
        return array.findIndex (function (el) {
          return el.id === id
        })
      },

      // --- backend access ----------------------------------------------------

      /**
       * Load the registrars from the backend for populating the respective
       * dropdown.
       */
      loadRegistrars () {
        return this.fetchData ({
          op: 'registrar/list',
          cb: data => {
            this.availableRegistrars = data.registrars.map (e => ({
              text: e.displayName + (e.ianaId ? ` (${e.ianaId})` : ''),
              value: e.name
            }))
          }
        })
      },

      /**
       * Load the contact roles from the backend for populating the respective
       * dropdown.
       */
      loadContactRoles () {
        this.fetchData ({
          op: 'domain/listContactRoles',
          cb: data => {
            for (const prop in data.roles) {
              if (data.roles.hasOwnProperty (prop)) {
                this.availableContactRoles.push ({
                  id: prop,
                  name: data.roles[prop]
                })
              }
            }
          }
        })
      },

      // --- validation helper methods -----------------------------------------

      dateFilterActionErrors (dateFilterObject, idx) {
        const errors = []

        if (!dateFilterObject.$each[idx].action.$dirty) {
          return errors
        }

        if (!dateFilterObject.$each[idx].action.required) {
          errors.push (this.$t ('required.date.action'))
        }

        return errors
      },

      dateFilterDateErrors (dateFilterObject, idx) {
        const errors = []

        if (!dateFilterObject.$each[idx].date.$dirty) {
          return errors
        }

        if (!dateFilterObject.$each[idx].date.required) {
          return [this.$t ('required.date.date')]
        }

        return errors
      },

      registrarFilterRoleErrors (registrarFilterObject, idx) {
        const errors = []

        if (!registrarFilterObject.$each[idx].role.$dirty) {
          return errors
        }

        if (!registrarFilterObject.$each[idx].role.required) {
          return [this.$t ('required.registrar.role')]
        }

        return errors
      },

      registrarFilterNameErrors (registrarFilterObject, idx) {
        const errors = []

        if (!registrarFilterObject.$each[idx].name.$dirty) {
          return errors
        }

        if (!registrarFilterObject.$each[idx].name.required) {
          return [this.$t ('required.registrar.name')]
        }

        return errors
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~@/app/scss/variables';

.additionalFilters > .row {
  // margin between "sections"
  margin-bottom: $_space-md;

  // reduce vertical spacing inside section
  .col, .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

:global(.container .v-card .layout).shorten {
  margin-right: 28px;
}
</style>
