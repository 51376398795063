<template>
  <v-autocomplete
    ref="input"
    v-model="apex"
    auto-select-first
    clearable
    :items="selectableApices"
    :label="label"
    spellcheck="false"
    :filter="apexFilter"/>
</template>

<script>
  import {mapActions} from 'vuex'
  import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'

  export default {
    name: 'ApexSelect',

    props: {
      value: {
        // the selected apex
        type: String,
        default: undefined
      },
      label: {
        // the dropdown label
        type: String,
        default: undefined
      }
    },

    data () {
      return {
        loadedApices: []
      }
    },

    computed: {
      apex: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      selectableApices () {
        const sort = (arr, cb) => {
          return arr.concat ().sort (cb)
        }

        return sort (this.splitApices (this.loadedApices), (a, b) => {
          return a.text > b.text
        })
      }
    },

    created () {
      this.loadApices ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      focus () {
        this.$refs.input.focus ()
      },

      /**
       * Split the given list of apices into valid domain endings. The split
       * process is performed on the level of labels, i.e., the apex ".a.b.c"
       * would be split into [".a.b.c", ".b.c", ".c"].
       *
       * @param {Array} apices      the array of apices to split
       * @return {Array}            the result array
       */
      splitApices (apices) {
        const result = []

        apices.forEach (a => {
          let pos
          let apex = a

          do {
            const puny = punycodeToASCII (apex)
            result.push ({text: puny === apex ? apex : `${apex} (${puny})`, value: apex})
            pos = apex.indexOf ('.', 1)
            apex = apex.slice (pos)
          } while (pos !== -1)
        })

        return result
      },

      /**
       * Load the supported apices from the backend.
       */
      loadApices () {
        this.$emit ('loading')

        this.fetchData ({
          op: 'registry/config/listApices',
          cb: data => {
            this.loadedApices = data.apices.map (a => `.${a}`).slice (0)
          },
          cbFinal: () => {
            this.$emit ('loaded')
          }
        })
      },

      /**
       * Filter the list of the select box according to the user input.
       *
       * The filtering is performed as a prefix search on the apices list, the
       * leading dot may be omitted. For example, when entering "uk", ".uk" is
       * found but ".co.uk" is not.
       *
       * Moreover, when a Punycode form of an apex exists, a prefix search on
       * the Punycode representation will be performed as well; again, the
       * leading dot may be omitted and also the leading "xn--". For example,
       * when entering "mgbab2bd", ".بازار (.xn--mgbab2bd)" is found.
       *
       * @param {Object} item           the select item
       * @param {String} queryText      the user input
       * @param {String} itemText       the display text of the select item
       */
      apexFilter (item, queryText, itemText) {
        const q = queryText !== null ? queryText : ''
        const i = itemText !== null ? itemText : ''

        return i.startsWith (q) || i.startsWith (`.${q}`) ||
          i.includes (`(${q}`) || i.includes (`(.${q}`) ||
          i.includes (`(xn--${q}`) || i.includes (`(.xn--${q}`)
      }
    }
  }
</script>
