<messages>["../Domain"]</messages>
<template>
  <confirmation-dialog
    v-model="showDialog"
    :error-msg="errorMsg"
    :headline="$t (`auth.title`, {name: object.name})"
    disable-cancel
    :is-loading="isLoading"
    @ok="ok">
    <template v-if="isLoading">
      <v-progress-linear
        indeterminate
        color="primary"/>
    </template>
    <template v-else>
      <i18n :path="message">
        <template #authInfo>
          <b
            class="monospaced"
            v-text="authData ? authData.authInfo : ''"/>
        </template>
      </i18n>
    </template>
  </confirmation-dialog>
</template>

<script>
  import {mapActions} from 'vuex'
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  const responseCodes = {
    Success: 'Success',
    Accepted: 'Accepted',
    SuccessSend: 'SuccessSend'
  }

  export default {

    components: {
      ConfirmationDialog
    },

    props: {
      value: {
        type: Boolean,
        default: false
      },

      object: {
        type: Object,
        default: () => ({
          v_id: null,
          name: 'n/a'
        })
      }
    },

    data () {
      return {
        isLoading: false,
        authData: null,
        message: '',
        error: false
      }
    },

    computed: {
      showDialog: {
        set (newVal) {
          this.$emit ('input', newVal)
        },
        get () {
          return this.value
        }
      },
      errorMsg () {
        return this.error ? this.$t ('auth.error') : ''
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      requestAuth () {
        this.isLoading = true
        this.fetchData ({
          op: 'domain/requestAuthInfo',
          params: {
            id: this.object.v_id
          },
          cb: ({response}) => {
            this.authData = response
            if (response.result === responseCodes.Success) {
              this.message = 'auth.success'
            } else if (response.result === responseCodes.SuccessSend) {
              this.message = 'auth.successSend'
            } else {
              this.message = 'auth.accepted'
            }
          },
          cbError: (_, next) => {
            this.error = true
            next (_)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      ok () {
        this.showDialog = false
        this.authData = null
        this.message = ''
        this.error = false
      }
    }
  }
</script>
