var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasLaunchPhase)?_c('v-row',{staticClass:"cgwng-bg-color-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{class:{required: _vm.isLaunchPhaseRequired},attrs:{"clearable":"","spellcheck":"false","disabled":!_vm.isLaunchPhaseEditable,"items":_vm.launchPhaseItems,"label":_vm.$t ('create.label.launchPhase'),"error-messages":_vm.isLaunchPhaseRequired ? _vm.requiredErrors ('launchPhase', 'required.launchPhase') : null},on:{"input":_vm.updateData},model:{value:(_vm.launchPhase),callback:function ($$v) {_vm.launchPhase=$$v},expression:"launchPhase"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"cgwng-bg-color-1"},[(_vm.isCreate)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.variantsEnabled)?_c('v-textarea',_vm._b({on:{"blur":function($event){_vm.$v.punycodeNames.$touch ()}},model:{value:(_vm.domainNames),callback:function ($$v) {_vm.domainNames=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"domainNames"}},'v-textarea',_vm.domainNamesProps,false)):_c('v-text-field',_vm._b({on:{"blur":function($event){_vm.$v.punycodeNames.$touch ()}},model:{value:(_vm.domainNames),callback:function ($$v) {_vm.domainNames=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"domainNames"}},'v-text-field',_vm.domainNamesProps,false))],1),(_vm.provideIdn)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"t",rawName:"v-t",value:('create.label.unicode'),expression:"'create.label.unicode'"}],attrs:{"small":""},on:{"click":function($event){_vm.domainNames = _vm.toUnicode (_vm.domainNames)}}},on))]}}],null,false,1963840399)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.unicodeTooltip'),expression:"'create.label.unicodeTooltip'"}]})]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"t",rawName:"v-t",value:('create.label.punycode'),expression:"'create.label.punycode'"}],staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){_vm.domainNames = _vm.toPunycode (_vm.domainNames)}}},on))]}}],null,false,2408577840)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.punycodeTooltip'),expression:"'create.label.punycodeTooltip'"}]})])],1):_vm._e()],1)],1):_vm._e(),(_vm.variantsAllowed)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.isVariantsDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-switch',_vm._g({attrs:{"hide-details":"","disabled":_vm.isVariantsDisabled,"label":_vm.$t ('create.label.enableVariants')},on:{"change":_vm.onChangeVariants},model:{value:(_vm.variantsEnabled),callback:function ($$v) {_vm.variantsEnabled=$$v},expression:"variantsEnabled"}},on))]}}],null,false,1749428945)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.enableVariantsHint'),expression:"'create.label.enableVariantsHint'"}]})])],1),(_vm.variantsEnabled)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"spellcheck":"false","label":_vm.$t ('create.label.variant'),"error-messages":_vm.nameErrors (_vm.$v.punycodeVariants)},on:{"blur":function($event){_vm.$v.punycodeVariants.$touch ()}},model:{value:(_vm.variantNames),callback:function ($$v) {_vm.variantNames=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"variantNames"}})],1):_vm._e(),(_vm.variantsEnabled && _vm.provideIdn)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"t",rawName:"v-t",value:('create.label.unicode'),expression:"'create.label.unicode'"}],attrs:{"small":""},on:{"click":function($event){_vm.variantNames = _vm.toUnicode (_vm.variantNames)}}},on))]}}],null,false,2796739631)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.unicodeTooltipVariants'),expression:"'create.label.unicodeTooltipVariants'"}]})]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"t",rawName:"v-t",value:('create.label.punycode'),expression:"'create.label.punycode'"}],attrs:{"small":""},on:{"click":function($event){_vm.variantNames = _vm.toPunycode (_vm.variantNames)}}},on))]}}],null,false,1043499055)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.punycodeTooltipVariants'),expression:"'create.label.punycodeTooltipVariants'"}]})])],1):_vm._e()],1)],1):_vm._e()],1),_c('v-row',[(_vm.showLanguageTagField)?_c('v-col',{staticClass:"cgwng-bg-color-2",attrs:{"cols":"6"}},[_c('idn-tag-selector',{class:{required: _vm.idnLangRequired},attrs:{"disabled":!_vm.languageTagFieldEditable,"supported":_vm.idnLanguages},on:{"input":_vm.updateData},model:{value:(_vm.value.domain.language),callback:function ($$v) {_vm.$set(_vm.value.domain, "language", $$v)},expression:"value.domain.language"}})],1):_vm._e(),(_vm.isCreate)?_c('v-col',{staticClass:"cgwng-bg-color-3",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slider',{staticClass:"slider-label",attrs:{"always-dirty":"","thumb-label":"always","thumb-size":"20","label":_vm.$t ('create.label.period'),"min":1,"max":10},on:{"input":_vm.updateData},model:{value:(_vm.value.domain.period),callback:function ($$v) {_vm.$set(_vm.value.domain, "period", $$v)},expression:"value.domain.period"}})],1)],1)],1):_vm._e(),(_vm.isCreate && _vm.allocationTokenAllowed)?_c('v-col',{staticClass:"cgwng-bg-color-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"spellcheck":"false","label":_vm.$t ('create.label.allocationToken')},on:{"input":_vm.updateData},model:{value:(_vm.value.domain.allocationToken),callback:function ($$v) {_vm.$set(_vm.value.domain, "allocationToken", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.domain.allocationToken"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }