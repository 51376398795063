<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <div>
    <v-row
      v-if="hasLaunchPhase"
      class="cgwng-bg-color-2">
      <v-col cols="12">
        <v-autocomplete
          v-model="launchPhase"
          :class="{required: isLaunchPhaseRequired}"
          clearable
          spellcheck="false"
          :disabled="!isLaunchPhaseEditable"
          :items="launchPhaseItems"
          :label="$t ('create.label.launchPhase')"
          :error-messages="isLaunchPhaseRequired ? requiredErrors ('launchPhase', 'required.launchPhase') : null"
          @input="updateData"/>
      </v-col>
    </v-row>

    <v-row class="cgwng-bg-color-1">
      <v-col
        v-if="isCreate"
        cols="12" sm="6">
        <!-- domain names and Unicode/Punycode switches -->
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-if="!variantsEnabled"
              v-model.trim="domainNames"
              v-bind="domainNamesProps"
              @blur="$v.punycodeNames.$touch ()"/>
            <v-text-field
              v-else
              v-model.trim="domainNames"
              v-bind="domainNamesProps"
              @blur="$v.punycodeNames.$touch ()"/>
          </v-col>
          <v-col
            v-if="provideIdn"
            cols="12">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  v-t="'create.label.unicode'"
                  small
                  v-on="on"
                  @click="domainNames = toUnicode (domainNames)"/>
              </template>
              <span v-t="'create.label.unicodeTooltip'"/>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  v-t="'create.label.punycode'"
                  class="ml-3"
                  small
                  v-on="on"
                  @click="domainNames = toPunycode (domainNames)"/>
              </template>
              <span v-t="'create.label.punycodeTooltip'"/>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="variantsAllowed"
        cols="12" sm="6">
        <!-- variant names and Unicode/Punycode switches -->
        <v-row>
          <v-col cols="12">
            <v-tooltip
              top
              :disabled="!isVariantsDisabled">
              <template #activator="{ on }">
                <v-switch
                  v-model="variantsEnabled"
                  hide-details
                  :disabled="isVariantsDisabled"
                  :label="$t ('create.label.enableVariants')"
                  v-on="on"
                  @change="onChangeVariants"/>
              </template>
              <span v-t="'create.label.enableVariantsHint'"/>
            </v-tooltip>
          </v-col>
          <v-col
            v-if="variantsEnabled"
            cols="12">
            <v-textarea
              v-model.trim="variantNames"
              spellcheck="false"
              :label="$t ('create.label.variant')"
              :error-messages="nameErrors ($v.punycodeVariants)"
              @blur="$v.punycodeVariants.$touch ()"/>
          </v-col>
          <v-col
            v-if="variantsEnabled && provideIdn"
            cols="12">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  v-t="'create.label.unicode'"
                  small
                  v-on="on"
                  @click="variantNames = toUnicode (variantNames)"/>
              </template>
              <span v-t="'create.label.unicodeTooltipVariants'"/>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  v-t="'create.label.punycode'"
                  small
                  v-on="on"
                  @click="variantNames = toPunycode (variantNames)"/>
              </template>
              <span v-t="'create.label.punycodeTooltipVariants'"/>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- further basic data -->
    <v-row>
      <v-col
        v-if="showLanguageTagField"
        class="cgwng-bg-color-2"
        cols="6">
        <idn-tag-selector
          v-model="value.domain.language"
          :disabled="!languageTagFieldEditable"
          :supported="idnLanguages"
          :class="{required: idnLangRequired}"
          @input="updateData"/>
        <!-- <v-autocomplete
          v-if="idnLanguages"
          v-model="value.domain.language"
          spellcheck="false"
          :items="idnLanguages"
          :label="$t ('create.label.language')"
          :class="{required: idnLangRequired}"
          @input="updateData"/>
        <v-text-field
          v-else
          v-model.trim="value.domain.language"
          spellcheck="false"
          :label="$t ('create.label.language')"
          :class="{required: idnLangRequired}"
          @input="updateData"/> -->
      </v-col>
      <v-col
        v-if="isCreate"
        class="cgwng-bg-color-3"
        cols="6">
        <v-row>
          <v-col cols="12">
            <v-slider
              v-model="value.domain.period"
              class="slider-label"
              always-dirty
              thumb-label="always"
              thumb-size="20"
              :label="$t ('create.label.period')"
              :min="1"
              :max="10"
              @input="updateData"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="isCreate && allocationTokenAllowed"
        class="cgwng-bg-color-1"
        cols="6">
        <v-text-field
          v-model.trim="value.domain.allocationToken"
          spellcheck="false"
          :label="$t ('create.label.allocationToken')"
          @input="updateData"/>
      </v-col>
    </v-row>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import _pick from 'lodash/pick'
  import {
    toASCII as punycodeToASCII,
    toUnicode as punycodeToUnicode
  } from 'punycode/punycode.es6'
  import {required} from 'vuelidate/lib/validators'
  import {mapGetters} from 'vuex'

  import {asciiDomain, idnDomain, tld} from '@/app/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import IdnTagSelector from './components/IdnTagSelector'

  const NEW_LINE_REGEX = /\r?\n/
  const NEW_LINE = '\n'

  export default {
    name: 'DomainBasicData',

    components: {
      IdnTagSelector
    },

    mixins: [validationMixins],

    props: {
      value: {
        type: Object,
        required: true,
        validator (v) {
          return v.hasOwnProperty ('domain')
        }
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        domainNames: '',
        launchPhase: null,
        initialRun: true,
        initialLaunchPhase: null,
        punycodeNames: [],
        variantNames: '',
        variantNamesBackup: '',
        punycodeVariants: [],
        variantsEnabled: !!this.value.domain &&
          !!this.value.domain.variants && !!this.value.domain.variants.length
      }
    },

    computed: {
      ...mapGetters ('meta', {
        launchPhases: 'getLaunchPhases',
        isLaunchPhaseRequired: 'isLaunchPhaseRequired',
        idnAllowed: 'isIdnAllowed',
        idnRequired: 'isIdnRequired',
        idnLangRequired: 'isIdnLangRequired',
        idnLangRequiredAndUpdatable: 'isIdnLangRequiredAndUpdatable',
        idnLangDisallowed: 'isIdnLangDisallowed',
        idnDisallowed: 'isIdnDisallowed',
        idnLanguages: 'getIdnLanguages',
        defaultIdnTag: 'getDefaultIdnTag',
        variantsAllowed: 'isVariantsAllowed',
        allocationTokenAllowed: 'isAllocationTokenAllowed',
        baseNames: 'getBaseNames'
      }),

      hasLaunchPhase () {
        return this.launchPhaseItems.length
      },

      isLaunchPhaseEditable () {
        return this.isCreate
      },

      launchPhaseItems () {
        const now = new Date ().getTime ()

        return this.launchPhases
          ?.filter?. (p => (p.startDate === null || p.startDate <= now) &&
            (p.endDate === null || p.endDate > now))
          .map?. (it => ({
            value: _pick (it, ['name', 'subname', 'defaultPhase']),
            text: it.subname ? it.name + ' - ' + it.subname : it.name
          })) || []
      },

      isVariantsDisabled () {
        return this.punycodeNames.length > 1
      },

      provideIdn () {
        return this.idnAllowed || this.idnRequired
      },

      domainNamesProps () {
        return {
          class: 'required',
          spellcheck: false,
          label: this.$t ('create.label.name'),
          'error-messages': this.nameErrors (this.$v.punycodeNames)
        }
      },

      showLanguageTagField () {
        return this.provideIdn && !this.idnLangDisallowed
      },

      languageTagFieldEditable () {
        return this.isCreate || this.idnLangRequiredAndUpdatable
      }
    },

    watch: {
      launchPhase (newValue) {
        // try choose the correct phase from selector
        if (this.launchPhaseItems.length) {
          const lp = this.launchPhaseItems.find (
            it => it.value.name === newValue?.name &&
              it.value.subname === newValue?.subname)?.value
          if (lp?.name) {
            this.launchPhase = lp
          }
        }

        if (newValue === this.launchPhase) {
          const lp = this.value?.domain?.launchPhase

          const isValueChanged = newValue?.name !== lp?.name ||
            newValue?.subname !== lp?.subname

          if (isValueChanged) {
            this.value.domain.launchPhase =
              _pick (newValue, ['name', 'subname'])
            this.updateData ()
          }
        }

        if (!newValue) {
          this.value.domain.launchPhase = null
          this.updateData ()
        }
      },

      domainNames (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.value.domain.names = typeof newValue === 'string'
            ? newValue.split (NEW_LINE_REGEX)
            : []

          this.updateData ()

          this.punycodeNames = this.value.domain.names
            ? this.value.domain.names.map (n => punycodeToASCII (n + ''))
            : []
        }
      },

      'value.domain.names' (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.domainNames = newValue.join (NEW_LINE)
        }
      },

      /**
       * sync the launch phase selector with the domain launch phase
       */
      'value.domain.launchPhase': {
        handler (newValue) {
          if (this.initialRun && newValue) {
            this.initialRun = false
            this.initialLaunchPhase = newValue
          }

          // if it does not exists in the lauch phase items it should be null
          this.launchPhase = this.launchPhaseItems.find (
            it => it.value.name === newValue?.name &&
              it.value.subname === newValue?.subname)?.value
        },
        immediate: true
      },

      variantNames (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.value.domain.variants = typeof newValue === 'string'
            ? newValue.split (NEW_LINE_REGEX)
            : []

          this.updateData ()

          this.punycodeVariants = this.value.domain.variants
            ? this.value.domain.variants.map (n => punycodeToASCII (n + ''))
            : []
        }
      },

      'value.domain.variants' (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.variantNames = newValue.join (NEW_LINE)
        }
      },

      variantsAllowed (newValue) {
        if (!newValue) {
          this.variantsEnabled = false
          this.variantNames = ''
        }
      },

      launchPhaseItems (newValue) {
        const defaultPhase = newValue?.find?. (p => p.value.defaultPhase)?.value

        if (this.isCreate && defaultPhase) {
          this.launchPhase = defaultPhase
        } else {
          if (!this.isCreate && this.launchPhase?.name) {
            const lp = newValue?.find?. (
              p => p.value.name === this.launchPhase?.name &&
                p.value.subname === this.launchPhase?.subname)?.value
            if (lp?.name) {
              this.launchPhase = lp
            }
          }

          this.launchPhase = this.launchPhaseItems.find (
            it => it.value.name === this.initialLaunchPhase?.name &&
              it.value.subname === this.initialLaunchPhase?.subname)?.value
        }
      },

      idnLanguages () {
        const isInvalidLanguage = this.idnLanguages?.length &&
          !this.idnLanguages.includes (this.value.domain.language)

        if (isInvalidLanguage) {
          this.value.domain.language = this.defaultIdnTag
        }
      }
    },

    validations () {
      const validations = {
        punycodeNames: {
          required,
          $each: {
            required,
            tld: tld (this.baseNames),
            domain: this.provideIdn ? idnDomain : asciiDomain
          }
        }
      }

      if (this.variantsAllowed) {
        validations.punycodeVariants = {
          $each: {
            tld: tld (this.baseNames),
            domain: this.provideIdn ? idnDomain : asciiDomain
          }
        }
      }

      if (this.hasLaunchPhase && this.isLaunchPhaseRequired) {
        validations.launchPhase = {
          required
        }
      }

      return validations
    },

    created () {
      const domain = this.value.domain

      this.domainNames = Array.isArray (domain.names)
        ? domain.names.join (NEW_LINE)
        : domain.name

      this.variantNames = Array.isArray (domain.variants)
        ? domain.variants.join (NEW_LINE)
        : ''
    },

    methods: {
      /**
       * Determine the domain name related error messages of the given
       * validations object.
       *
       * @param {Object} validations      the validations object to evaluate
       * @return {Array}                  the error messages
       */
      nameErrors (validations) {
        let errors = []

        if (validations) {
          const NOT_A_DOMAIN = this.$t ('create.invalid.domainName')
          const REQUIRED = this.$t ('create.required.domainName')
          const WRONG_BASE_NAME = this.$t ('create.invalid.baseName')

          if (validations.$dirty) {
            if (validations.required !== undefined && !validations.required) {
              errors.push (REQUIRED)
            }

            for (const i in validations.$model) {
              if (!validations.$each[i].domain &&
                !errors.includes (NOT_A_DOMAIN)) {
                errors.push (NOT_A_DOMAIN)
              }

              if (validations.$each[i].required !== undefined &&
                !validations.$each[i].required &&
                !errors.includes (REQUIRED)) {
                errors.push (REQUIRED)
              }

              if (!validations.$each[i].tld &&
                !errors.includes (WRONG_BASE_NAME)) {
                errors.push (WRONG_BASE_NAME)
              }
            }
          }

          if (errors.length > 1) {
            errors = [
              `${this.$t ('general.multipleErrors')}: ${errors.join ('. ')}.`
            ]
          }
        }

        return errors
      },

      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        const domainErrors = this.$v.punycodeNames.$error ||
          this.$v.punycodeNames.$each.$error

        const variantErrors = this.$v.punycodeVariants &&
          (this.$v.punycodeVariants.$error ||
            this.$v.punycodeVariants.$each.$error)

        const launchPhaseError = this.$v.launchPhase &&
          this.$v.launchPhase.$error

        return domainErrors || variantErrors || launchPhaseError
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        const domainValid = !this.$v.punycodeNames.$invalid &&
          !this.$v.punycodeNames.$each.$invalid

        const variantValid = !this.$v.punycodeVariants ||
          (!this.$v.punycodeVariants.$invalid &&
            !this.$v.punycodeVariants.$each.$invalid)

        const launchPhaseValid =
          !this.$v.launchPhase || !this.$v.launchPhase.$invalid

        return domainValid && variantValid && launchPhaseValid
      },

      /**
       * Modify the internal domain or variant names representation using the
       * given modificator function.
       *
       * Since the domain/variant names are represented as a single
       * ((CR)LF-separated) string, this method first splits it up into single
       * name strings, modifies them and then joins the modified strings using
       * the LF character.
       *
       * @param {Array} names             the names to modify
       * @param {Object} modificator      the modificator function operating on
       *                                  single names
       * @return {String}                 the modified domain or variant names
       */
      modifyNames (names, modificator) {
        if (names) {
          return names.split (NEW_LINE_REGEX).map (n =>
            modificator (n)).join (NEW_LINE)
        }
      },

      /**
       * Convert the given names to Unicode. Has no effect if the names are
       * already in Unicode representation.
       *
       * @param {Array} names     the names array to convert
       * @return {Array}          the converted names
       */
      toUnicode (names) {
        return this.modifyNames (names, punycodeToUnicode)
      },

      /**
       * Convert the given names to Punycode. Has no effect if the names are
       * already in Punycode representation.
       *
       * @param {Array} names     the names array to convert
       * @return {Array}          the converted names
       */
      toPunycode (names) {
        return this.modifyNames (names, punycodeToASCII)
      },

      /**
       * Delete variant names if variant creation is switched off.
       */
      onChangeVariants () {
        if (!this.variantsEnabled) {
          this.variantNamesBackup = this.variantNames
          this.variantNames = ''
        } else {
          this.variantNames = this.variantNamesBackup
        }
      },

      /**
       * Emit an input event if anything in this data section is changed.
       */
      updateData () {
        this.$emit ('input', this.value)
      }
    }
  }
</script>

<!--
================================================================================
  Style
================================================================================
-->

<style>
.slider-label label {
  font-size: 12pt !important;
}

.slider-value input {
  text-align: right;
}
</style>
