<messages>["./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-row>
    <v-col
      v-if="isContactAuthInfoSupported"
      cols="6"
      class="cgwng-bg-color-1 pa-2">
      <auth-info
        v-model="value.contact.authInfo"
        :registry-id="value.contact.registryId"
        @input="updateData"/>
    </v-col>
    <v-col
      v-if="!isFieldProhibited (fieldNamesMap.maintainer)"
      cols="6"
      class="cgwng-bg-color-2 pa-2">
      <v-text-field
        v-model.trim="value.contact.maintainer"
        spellcheck="false"
        :label="$t ('label.maintainer')"
        :class="{required: isFieldPresent(fieldNamesMap.maintainer) && isFieldRequired(fieldNamesMap.maintainer)}"
        :error-messages="requiredErrors (
          'value.contact.maintainer',
          'required.maintainer')"
        @blur="$v.value.contact.maintainer.$touch"
        @input="updateData"/>
    </v-col>
    <v-col
      v-if="isCreate"
      cols="6"
      class="cgwng-bg-color-2 pa-2">
      <v-text-field
        v-model.trim="value.contact.handle"
        spellcheck="false"
        :label="$t ('label.handle')"
        :disabled="isFieldProhibited(fieldNamesMap.contactHandle)"
        :class="{required: isFieldPresent(fieldNamesMap.contactHandle) && isFieldRequired(fieldNamesMap.contactHandle)}"
        :error-messages="requiredErrors (
          'value.contact.handle',
          'required.handle')"
        :hint="value.contact.handle === null || value.contact.handle === '' ?
          $t ('create.autoGeneratedHint') : ''"
        persistent-hint
        @input="updateData"/>
    </v-col>
    <v-col
      v-if="!isFieldProhibited (fieldNamesMap.policy)"
      cols="6"
      class="cgwng-bg-color-1 pa-2">
      <v-autocomplete
        v-model="value.contact.policy"
        clearable
        :class="{required: isFieldPresent(fieldNamesMap.policy) && isFieldRequired(fieldNamesMap.policy)}"
        :label="$t ('label.policy.title')"
        :items="dataPolicyItems"
        :error-messages="requiredErrors (
          'value.contact.policy',
          'required.policy')"
        @blur="$v.value.contact.policy.$touch"
        @input="updateData"/>
    </v-col>
    <v-col
      cols="12"
      class="cgwng-bg-color-3">
      <provider-chain
        ref="contactProviderChain"
        v-model="chainModel"
        :edit="editProviderChain"
        @edit="onEditProviderChain"
        @input="updateData"/>
    </v-col>
    <v-col
      cols="12"
      class="cgwng-bg-color-1">
      <add-on
        v-model="value.addOnFieldData"
        :v="$v.value.addOnFieldData"
        @input="updateData"/>
    </v-col>
  </v-row>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters} from 'vuex'

  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import ContactFieldMixin from './components/ContactFieldMixin'

  import AddOn from '@/app/core/components/RegistryObject/AddOn'
  import AddOnValidation
    from '@/app/core/components/RegistryObject/AddOnValidation'
  import AuthInfo from '@/app/core/components/RegistryObject/AuthInfo'
  import ProviderChain from '@/app/core/components/RegistryObject/ProviderChain'

  import {CONTACT_POLICIES} from '@/app/pages/Contact/constants'

  export default {
    name: 'ContactAdministrativeData',

    components: {
      AddOn,
      AuthInfo,
      ProviderChain
    },

    mixins: [ContactFieldMixin, validationMixins],

    validations () {
      return {
        value: {
          contact: {
            maintainer: {
              ...this.createValidators (this.fieldNamesMap.maintainer)
            },
            policy: {
              ...this.createValidators (this.fieldNamesMap.policy)
            },
            handle: {
              ...this.createValidators (this.fieldNamesMap.handle)
            }
          },
          addOnFieldData: AddOnValidation

        }
      }
    },

    props: {
      isCreate: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        required: true,
        validator (v) {
          return v.hasOwnProperty ('contact')
        }
      }
    },

    data () {
      return {
        chainModel: {
          spec: '',
          type: '',
          clientId: 0
        },
        editProviderChain: false
      }
    },

    computed: {
      ...mapGetters ({
        isContactAuthInfoSupported: 'meta/isContactAuthInfoSupported'
      }),

      dataPolicyItems () {
        return CONTACT_POLICIES.map (value => ({
          text: this.$t (`label.policy.${value}`), value
        }))
      }
    },

    watch: {
      'value.contact' () {
        this.populateModel ()
      }
    },

    created () {
      this.editProviderChain = this.value.editProviderChain
      this.populateModel ()
    },

    methods: {
      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$refs.contactProviderChain.hasErrors () ||
          this.$v.value.contact.maintainer.$error ||
          this.$v.value.contact.policy.$error
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return this.$refs.contactProviderChain.isValid () &&
          !this.$v.value.contact.maintainer.$invalid &&
          !this.$v.value.contact.policy.$invalid
      },

      /**
       * Emit an "input" event in case of changed data.
       */
      updateData () {
        this.value.editProviderChain = this.editProviderChain
        this.value.contact.providerChainSpec = this.chainModel.spec
        this.value.contact.providerChainType = this.chainModel.type
        this.$emit ('input', this.value)
      },

      /**
       * Emit an "input" event in case the "edit provider chain" switch is
       * toggled.
       */
      onEditProviderChain (e) {
        this.editProviderChain = e
        this.updateData ()
      },

      /**
       * Populate the internal model with the values from the passed prop.
       */
      populateModel () {
        this.chainModel.spec = this.value.contact.providerChainSpec
        this.chainModel.type = this.value.contact.providerChainType
        this.chainModel.clientId = this.value.contact.clientId
      }
    }
  }
</script>
