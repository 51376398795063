<messages>["./CurrencySelect"]</messages>

<template>
  <v-autocomplete
    ref="currency"
    v-model="currency"
    spellcheck="false"
    :loading="isLoading || isLoadingCodes"
    :label="labelString"
    :items="currencyItems"
    :disabled="disabled"
    :readonly="readonly"
    :class="{required}"
    :error-messages="errorMessagesString"
    @blur="$emit ('blur')"/>
</template>

<script>
  import {required} from 'vuelidate/lib/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  /**
   * @typedef {Object} TextValueItem
   * @property {String} text - the text
   * @property {String} value - the value
   */

  /**
   * validation object for currency selection input
   */
  export const validation = {
    required
  }

  /**
   * validation type message attributes for the validation object
   */
  export const validationMessages = {
    required: 'validation.currency.required'
  }

  // frequently used currencies
  export const FrequentlyUsedCurrencies = ['USD', 'EUR', 'GBP', 'CHF']

  // "currency-like" units (precious metals, testing unit etc.)
  const NonCurrencies = [
    'XAG', 'XAU', 'XBA', 'XBB', 'XBC', 'XBD', 'XDR', 'XFU', 'XPD', 'XPT', 'XTS',
    'XUA', 'XXX'
  ]

  const labelKey = 'label.component'
  const frequentlyUsedLabelKey = 'label.frequentlyUsed'
  const otherLabelKey = 'label.other'

  export default {
    name: 'CurrencySelect',

    mixins: [validationMixins],

    props: {
      value: {
        // the selected currency (ISO 4217 code)
        type: String,
        default: undefined
      },
      label: {
        // the component label
        type: String,
        default: undefined
      },
      frequentlyUsed: {
        // the frequently used currency codes (sorting will be preserved)
        type: Array,
        default: () => FrequentlyUsedCurrencies
      },
      frequentlyUsedLabel: {
        // the "frequently used currencies" subheading of the select list
        type: String,
        default: undefined
      },
      // is the component is in loading state
      isLoading: {
        type: Boolean,
        default: false
      },
      otherLabel: {
        // the "other currencies" subheading of the select list
        type: String,
        default: undefined
      },
      additionalItems: {
        // custom items, which should also be selectable
        // (e.g [{text: 'All (*)', value: '*'}])
        type: Array,
        default: () => []
      },
      exclude: {
        // the currency codes, which should not be used
        type: Array,
        default: () => []
      },
      disabled: {
        // whether the component is disabled
        type: Boolean,
        default: false
      },
      readonly: {
        // whether the component is read-only
        type: Boolean,
        default: false
      },
      required: {
        // whether a selection is required
        type: Boolean,
        default: false
      },
      errorMessages: {
        // the messages to display upon failed validation
        type: Array,
        default: undefined
      },
      /**
       * the vuelidate validation object (normally the part of parent's
       * {@code this.$v})
       */
      v: {
        type: Object,
        default: undefined
      },
      /**
       *  the validation messages applicable to specified validation object,
       *  which can override validation messages, specified in the component
       *  ({@code validationMessages})
       */
      vMessages: {
        type: Object,
        default: () => validationMessages
      }
    },

    data () {
      return {
        currencyCodes: [],
        isLoadingCodes: false
      }
    },

    computed: {
      currency: {
        get () {
          return this.value !== undefined ? this.value : this.v?.$model
        },

        set (newValue) {
          if (this.value === undefined && this.v?.$model !== undefined) {
            this.v.$model = newValue
          } else {
            this.$emit ('input', newValue)
          }
        }
      },

      labelString () {
        return this.label !== undefined
          ? this.label
          : this.$t (labelKey)
      },

      frequentlyUsedLabelString () {
        return this.frequentlyUsedLabel !== undefined
          ? this.frequentlyUsedLabel
          : this.$t (frequentlyUsedLabelKey)
      },

      otherLabelString () {
        return this.otherLabel !== undefined
          ? this.otherLabel
          : this.$t (otherLabelKey)
      },

      currencyItems () {
        const allItems = this.currencyCodes.map (c =>
          this.toItem (c))

        if (Array.isArray (this.additionalItems)) {
          allItems.push (...this.additionalItems)
        }

        const frequentlyUsedItems = Array.isArray (this.frequentlyUsed)
          ? allItems.filter (i =>
            this.frequentlyUsed.filter (it =>
              !this.exclude.includes (it)
            ).includes (i.value)
          ).sort (this.compareFrequentlyUsed)
          : []

        const otherItems = (
          Array.isArray (this.frequentlyUsed)
            ? allItems.filter (i => !this.frequentlyUsed.includes (i.value))
            : allItems
        ).filter (i => !this.exclude.includes (i.value)).sort ((a, b) =>
          a.text.localeCompare (b.text))

        const currencyItems = []

        if (frequentlyUsedItems.length > 0) {
          currencyItems.push ({header: this.frequentlyUsedLabelString})
          currencyItems.push (...frequentlyUsedItems)

          if (otherItems.length > 0) {
            currencyItems.push ({divider: true})
            currencyItems.push ({header: this.otherLabelString})
          }
        }

        if (otherItems.length > 0) {
          currencyItems.push (...otherItems)
        }

        return currencyItems
      },

      /**
       * wrapper for {@code this.vMessages} property to be able to implement
       * custom logic for choosing/preparation of validation messages
       */
      validationMessages () {
        return this.vMessages
      },

      /**
       * error messages, calculated according to provided component properties
       */
      errorMessagesString () {
        return this.errorMessages !== undefined
          ? this.errorMessages
          : this.validationErrors ('', this.validationMessages)
      }
    },

    created () {
      this.isLoadingCodes = true
      import ('currency-codes/data').then (({default: ccData}) => {
        this.currencyCodes = ccData.map (it => it.code).filter (it => !NonCurrencies.includes (it))
      }).catch (() => 'An error occurred while loading currency codes')
        .finally (() => { this.isLoadingCodes = false })
    },

    methods: {
      /**
       * set focus to the component input
       */
      focus () {
        this.$refs.currency.focus ()
      },

      /**
       * Convert the given currency code into a select item.
       *
       * @param {String} value            the ISO 4217 currency code
       * @return {TextValueItem}          the select item
       */
      toItem (value) {
        return {text: this.formatCurrency (value), value}
      },

      /**
       * Compare the given frequently used currency codes.
       *
       * @param {TextValueItem} cc1       the first currency code to compare
       * @param {TextValueItem} cc2       the second currency code to compare
       * @return {Number}                 a negative value if cc1 occurs before
       *                                  cc2 in the frequently used currencies;
       *                                  a positive value if cc2 occurs before
       *                                  cc1 in the frequently used currencies;
       *                                  zero if cc1 equals cc2
       */
      compareFrequentlyUsed (cc1, cc2) {
        return this.frequentlyUsed.indexOf (cc1.value) -
          this.frequentlyUsed.indexOf (cc2.value)
      }
    }
  }
</script>
