<messages>["./StatesDialog"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t (`title`)"
    :error-msg="errorMsg"
    :is-loading="isLoading"
    @ok="request">
    <v-row>
      <v-col cols="12" class="py-0">
        <p v-text="$t ('intro', {object: object.name})"/>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-row>
          <v-col
            v-for="stateType in accessibleTypes"
            :key="stateType"
            cols="12" sm="6">
            <h4 v-t="`label.type.${stateType}`"/>
            <div
              v-for="state in states[stateType]"
              :key="state">
              <v-checkbox
                v-model="stateSelection[state]"
                :disabled="isLoading"
                :label="$t (`label.${state}`)"
                hide-details/>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="orderErrorResponse.length" cols="12">
        <v-btn text color="error" @click="showDetails = !showDetails">
          {{ $t(`general.label.${showDetails ? 'hideErrorDetails' : 'showErrorDetails'}`) }}
        </v-btn>
      </v-col>
      <v-col v-if="orderErrorResponse.length && showDetails" cols="12">
        <order-processing-response :response="orderErrorResponse" :show-card-border="false" hide-title/>
      </v-col>
    </v-row>
  </confirmation-dialog>
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  import OrderProcessingResponse
    from '@/app/core/components/RegistryObject/OrderProcessingResponse'

  const MEMBER_LOCK = 'member-lock'
  const MEMBER_DELETE_PROHIBITED = 'member-delete-prohibited'
  const MEMBER_TRANSFER_PROHIBITED = 'member-transfer-prohibited'
  const MEMBER_UPDATE_PROHIBITED = 'member-update-prohibited'
  const MEMBER_RENEW_PROHIBITED = 'member-renew-prohibited'
  const MEMBER_HOLD = 'member-hold'
  const MEMBER_AUTORENEW_DISABLED = 'member-autorenew-disabled'
  const MEMBER_HOLD_PENDING_VERIFICATION = 'member-hold-pending-verification'
  const SECRETARIAT_LOCK = 'secretariat-lock'
  const SECRETARIAT_DELETE_PROHIBITED = 'secretariat-delete-prohibited'
  const SECRETARIAT_TRANSFER_PROHIBITED = 'secretariat-transfer-prohibited'
  const SECRETARIAT_UPDATE_PROHIBITED = 'secretariat-update-prohibited'
  const SECRETARIAT_RENEW_PROHIBITED = 'secretariat-renew-prohibited'
  const SECRETARIAT_HOLD = 'secretariat-hold'
  const UNDER_DISPUTE = 'under-dispute'

  const UNDELETABLE = new Set ([
    MEMBER_LOCK,
    MEMBER_DELETE_PROHIBITED,
    SECRETARIAT_LOCK,
    SECRETARIAT_DELETE_PROHIBITED
  ])
  const UNRENEWABLE = new Set ([
    MEMBER_RENEW_PROHIBITED,
    SECRETARIAT_RENEW_PROHIBITED
  ])
  const UNTRANSFERABLE = new Set ([
    MEMBER_LOCK,
    MEMBER_TRANSFER_PROHIBITED,
    SECRETARIAT_LOCK,
    SECRETARIAT_TRANSFER_PROHIBITED
  ])
  const UNUPDATABLE = new Set ([
    MEMBER_LOCK,
    MEMBER_UPDATE_PROHIBITED,
    SECRETARIAT_LOCK,
    SECRETARIAT_UPDATE_PROHIBITED,
    UNDER_DISPUTE
  ])

  function isIntersectionEmpty (setA, setB) {
    let isEmpty = true

    for (const elem of setB) {
      if (setA.has (elem)) {
        isEmpty = false
        break
      }
    }

    return isEmpty
  }

  export function isDeleteProhibited (states) {
    return !isIntersectionEmpty (UNDELETABLE, new Set (states))
  }

  export function isRenewProhibited (states) {
    return !isIntersectionEmpty (UNRENEWABLE, new Set (states))
  }

  export function isTransferProhibited (states) {
    return !isIntersectionEmpty (UNTRANSFERABLE, new Set (states))
  }

  export function isUpdateProhibited (states) {
    return !isIntersectionEmpty (UNUPDATABLE, new Set (states))
  }

  export const HOST_STATES = {
    client: [
      MEMBER_LOCK,
      MEMBER_DELETE_PROHIBITED,
      MEMBER_UPDATE_PROHIBITED
    ],
    extended: [
      SECRETARIAT_LOCK,
      SECRETARIAT_DELETE_PROHIBITED,
      SECRETARIAT_UPDATE_PROHIBITED,
      UNDER_DISPUTE
    ]
  }

  export const CONTACT_STATES = {
    client: [
      ...HOST_STATES.client,
      MEMBER_TRANSFER_PROHIBITED
    ],
    extended: [
      ...HOST_STATES.extended,
      SECRETARIAT_TRANSFER_PROHIBITED
    ]
  }

  export const DOMAIN_STATES = {
    client: [
      ...CONTACT_STATES.client,
      MEMBER_RENEW_PROHIBITED,
      MEMBER_HOLD,
      MEMBER_AUTORENEW_DISABLED,
      MEMBER_HOLD_PENDING_VERIFICATION
    ],
    extended: [
      ...CONTACT_STATES.extended,
      SECRETARIAT_RENEW_PROHIBITED,
      SECRETARIAT_HOLD
    ]
  }

  export default {
    name: 'StatesDialog',

    components: {
      ConfirmationDialog,
      OrderProcessingResponse
    },

    props: {
      object: {
        type: Object,
        required: true,
        validator (value) {
          return ['id', 'name'].every (key => value[key])
        }
      },
      type: {
        type: String,
        required: true,
        validator (value) {
          return ['domain', 'contact', 'host'].includes (value)
        }
      },
      value: {
        type: Boolean,
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: '',
        stateSelection: {},
        orderErrorResponse: [],
        showDetails: false
      }
    },

    computed: {
      ...mapState ('auth', [
        'clientPermissions'
      ]),

      isAdmin () {
        return this.clientPermissions?.includes?. ('ManageExtendedObjectStates')
      },

      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      states () {
        let states
        switch (this.type) {
          case 'domain':
            states = DOMAIN_STATES
            break

          case 'contact':
            states = CONTACT_STATES
            break

          case 'host':
            states = HOST_STATES
            break

          default:
            console.error (`Unknown object type ${this.type}`)
        }

        return states
      },

      accessibleTypes () {
        const types = ['client']

        if (this.isAdmin) {
          types.push ('extended')
        }

        return types
      }
    },

    watch: {
      value (newValue) {
        if (!newValue) {
          this.errorMsg = ''
          this.orderErrorResponse = []
        }
      },

      showDialog (newVal) {
        if (!newVal) {
          this.isLoading = false
          this.errorMsg = ''
          this.stateSelection = {}
          this.orderErrorResponse = []
          this.showDetails = false
        }
      },

      object: {
        handler () {
          this.loadStates ()
        },
        immediate: true,
        deep: true
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      request () {
        this.isLoading = true

        const states = []

        for (const prop in this.stateSelection) {
          if (this.stateSelection.hasOwnProperty (prop)) {
            if (this.stateSelection[prop]) {
              states.push (prop)
            }
          }
        }

        this.fetchData ({
          op: `${this.type}/updateStates`,
          params: {
            id: this.object.id,
            states
          },
          cb: () => {
            this.displaySuccessMessage (this.$t ('success'))

            this.addEvent ({
              type: 'success',
              message: this.$t ('success'),
              objects: [{name: this.object.name, link: {name: `${this.type}.view.id`, params: {id: this.object.id}}}]
            })

            this.errorMsg = ''
            this.orderErrorResponse = []
            this.showDialog = false
            this.$emit ('success')
          },
          cbError: data => {
            this.orderErrorResponse = data.errors

            if (data.errors ? data.errors.length > 0 : false) {
              this.errorMsg = data.errors[0].message
            } else {
              this.errorMsg = this.$t ('error')
            }
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      /**
       * Load the object states.
       */
      loadStates () {
        const id = this.object.id

        if (id) {
          this.isLoading = true
          this.invoice = null
          this.stateSelection = {}

          this.fetchData ({
            op: `${this.type}/loadStates`,
            params: {
              id
            },
            cb: data => {
              data.strings.forEach (it => {
                this.stateSelection[it] = true
              })
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        }
      }
    }
  }
</script>
