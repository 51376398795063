<messages>["./AddOn"]</messages>

<template>
  <div>
    <v-row
      v-for="(addOn, idx) in addOnFields"
      :key="idx">
      <v-col
        class="pa-2"
        cols="12" sm="6">
        <v-text-field
          v-model.trim="addOn.key"
          class="input-group--focused"
          spellcheck="false"
          prefix="addon."
          :label="$t ('key')"
          :error-messages="keyErrors (idx)"
          @blur="onValidate (idx)"/>
      </v-col>
      <v-col
        class="pa-2"
        cols="12" sm="6">
        <v-text-field
          v-model.trim="addOn.value"
          spellcheck="false"
          :append-icon="isDeleteAllowed? 'delete' : ''"
          :label="$t ('value')"
          :error-messages="valueErrors (idx)"
          @click:append="onDelete (idx)">
          <template #append-outer>
            <v-tooltip
              v-if="idx === addOnFields.length - 1"
              top>
              <template #activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  @click="onAdd">
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span v-t="'add'"/>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'AddOn',

    props: {
      value: {
        // the model, i.e. the add-on key/value pairs
        type: Array,
        required: true,
        validator (v) {
          return v.every (e =>
            e.hasOwnProperty ('key') && e.hasOwnProperty ('value'))
        }
      },
      v: {
        // the validations sub-tree to associate with this component
        type: Object,
        required: true
      }
    },

    data () {
      return {
        addOnFields: []
      }
    },

    computed: {
      isDeleteAllowed () {
        return this.addOnFields.length > 1
      }
    },

    watch: {
      value () {
        this.populateInternalModel ()
      },

      addOnFields: {
        handler () {
          this.$emit ('input', this.addOnFields)
        },
        deep: true
      }
    },

    created () {
      this.populateInternalModel ()
    },

    methods: {
      /**
       * Create an empty add-on element.
       *
       * @return {Object}     the empty element (key-value pair)
       */
      createEmptyElement () {
        return {
          key: '',
          value: ''
        }
      },

      /**
       * Add a new (empty) element to the add-on fields.
       */
      onAdd () {
        this.addOnFields.push (this.createEmptyElement ())
      },

      /**
       * Delete the element with the given index from the add-on fields.
       *
       * @param {Number} idx      the index of the element to delete
       */
      onDelete (idx) {
        this.addOnFields.splice (idx, 1)
      },

      /**
       * Validate the add-on field with the given index.
       *
       * @param {Number} idx      the index of the field to validate
       */
      onValidate (idx) {
        this.v.$each[idx].$touch (idx)
      },

      /**
       * Determine the key-related errors of the add-on field with the given
       * index.
       *
       * @param {Number} idx      the index
       */
      keyErrors (idx) {
        const val = this.v.$each[idx]

        if (!val || !val.key.$dirty) {
          return []
        }

        const errors = []

        if (!val.key.required) {
          errors.push (this.$t ('required.key'))
        }

        if (!val.key.payloadKey) {
          errors.push (this.$t ('invalid.key'))
        }

        const returnValue = errors.join ('.')
        return returnValue !== '' ? [returnValue] : []
      },

      /**
       * Determine the value-related errors of the add-on field with the given
       * index.
       *
       * @param {Number} idx      the index
       */
      valueErrors (idx) {
        const val = this.v.$each[idx]

        if (!val || !val.value.$dirty) {
          return []
        }

        if (!val.value.required) {
          return [this.$t ('required.value')]
        }
      },

      /**
       * Populate the component-internal add-on model with the v-model value
       * passed to this component or, if not exists, with an empty add-on
       * element.
       */
      populateInternalModel () {
        if (this.value.length === 0) {
          this.addOnFields = [this.createEmptyElement ()]
        } else {
          this.addOnFields = this.value
        }
      }
    }
  }
</script>
