<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-autocomplete
    ref="input"
    v-model="countryCode"
    :label="label"
    :items="countryCodes"
    :class="{required}"
    :clearable="clearable"
    :error-messages="errorMessages"
    :disabled="disabled"
    @blur="$emit ('blur')"/>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {locales, defaultLocale} from '@/app/i18n/locales'

  export default {
    name: 'CountrySelect',

    props: {
      label: {
        type: String,
        default: undefined
      },
      required: Boolean,
      clearable: Boolean,
      errorMessages: {
        type: Array,
        default: undefined
      },
      disabled: {
        type: Boolean,
        default: false
      },
      value: {
        type: String,
        default: undefined
      }
    },

    data () {
      return {
        // supported country codes
        countryCodeKeys: []
      }
    },

    computed: {
      countryCode: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit ('input', val)
        }
      },

      countryCodes () {
        const countryList = this.countryCodeKeys.map (key => ({
          value: key,
          text: this.$t (key)
        }))

        countryList.sort ((c1, c2) => c1.text.localeCompare (c2.text))
        return countryList
      }
    },

    async created () {
      // load country names for every supported locale
      for (const locale of locales) {
        const countryMessages = await import ('i18n-iso-countries/langs/' + locale)
        const reduceArray = (arr) => (Array.isArray (arr) ? arr[0] : arr)

        const messages = Object.keys (countryMessages.countries)
          .reduce ((acc, c) => ({...acc, [c]: reduceArray (countryMessages.countries[c])}), {})

        this.$i18n.mergeLocaleMessage (locale, messages)

        // store supported country codes from default locale translations
        if (locale === defaultLocale) {
          this.countryCodeKeys = Object.keys (messages)
        }
      }
    },

    mounted () {
      this.$refs.input.lastItem = 300
    },

    methods: {
      focus () {
        this.$refs.input.focus ()
      }
    }
  }
</script>
