<messages>["../Domain"]</messages>

<template>
  <v-card
    height="100%"
    class="elevation-1"
    width="100%"
    append>
    <v-card-text class="py-0">
      <v-container>
        <v-row justify="space-between">
          <v-col class="ma-0 pa-0" cols="10">
            <div>
              <strong>
                {{ role }}
              </strong>
              <br>
              (<contact-link v-bind="contact" :history="history"/>)
            </div>
          </v-col>
          <v-col class="ma-0 pa-0" cols="2">
            <v-icon x-large>
              person
            </v-icon>
          </v-col>
        </v-row>
        <v-row
          v-if="!history"
          justify="space-between">
          <v-col class="ma-0 pa-0" cols="12">
            <div
              v-if="contact.status === 'active'"
              class="contactData">
              <v-tooltip
                v-for="(line, idx) in [
                  contact.title,
                  contact.name,
                  contact.organization,
                  contact.street,
                  contact.postalCode,
                  contact.city,
                  contact.province,
                  $t (contact.country),
                  contact.email
                ].filter (it => !!it)"
                :key="'cc-line-' + idx"
                bottom>
                <template #activator="{ on }">
                  <span
                    class="contactLine"
                    v-on="on"
                    v-text="line"/>
                </template>
                <span v-text="line"/>
              </v-tooltip>
            </div>
            <template
              v-else-if="contact.status === 'deleted'"
              v-t="'view.label.contactdeleted'"/>
            <template
              v-else
              v-t="'view.label.contactforeign'"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import {locales} from '@/app/i18n/locales'

  import ContactLink from './ContactLink'

  export default {
    name: 'ContactCard',

    components: {ContactLink},

    props: {
      contact: {
        type: Object,
        required: true
      },
      history: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      role () {
        return this.$t (`create.contactRole.${this.contact.role}`)
      }
    },

    async created () {
      // load country names for every supported locale
      for (const locale of locales) {
        const countryMessages = await import ('i18n-iso-countries/langs/' + locale)
        this.$i18n.mergeLocaleMessage (locale, countryMessages.countries)
      }
    }
  }
</script>

<style scoped>
.contactLine {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
