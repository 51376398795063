<messages>["../Domain"]</messages>
<template>
  <v-combobox
    v-model="selected"
    :disabled="disabled"
    :return-object="false"
    :label="$t ('create.label.language')"
    :items="filteredSupported"
    :loading="isLoading"/>
</template>

<script>
  import {mapGetters, mapActions, mapState} from 'vuex'
  import {locales} from '@/app/i18n/locales'

  export default {
    props: {
      value: {
        type: String,
        default: null
      },
      supported: {
        type: Array,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isLoading: false
      }
    },

    computed: {
      ...mapGetters ({
        tags: 'meta/getIdnTags'
      }),

      ...mapState ({
        appLocale: state => state.locale.appLocale
      }),

      selected: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      visualTags () {
        return this.tags
          ? this.tags.map ((t) => {
            return {
              text: t.tag + this.getCountryPart (t.countries),
              value: t.tag
            }
          })
          : []
      },

      filteredSupported () {
        if (this.supported && this.supported.length > 0) {
          return [
            ...this.visualTags.filter ((i) => this.supported.includes (i.value)),
            ...this.additional
          ]
        }
        return this.visualTags
      },

      additional () {
        return this.supported
          ? this.supported.filter ((s) => {
            return !this.visualTags.find ((i) => s === i.value)
          }).map ((i) => ({text: i, value: i}))
          : []
      }
    },

    created () {
      if (!this.tags) {
        this.isLoading = true
        this.loadTags (locales).finally (() => {
          this.isLoading = false
        })
      }
    },

    methods: {
      ...mapActions ({
        loadTags: 'meta/loadIdnTags'
      }),

      getCountryPart (countries) {
        return countries[this.appLocale]
          ? ` (${countries[this.appLocale]})`
          : countries.en
            ? ` (${countries.en})`
            : ''
      }
    }
  }
</script>
