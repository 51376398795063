var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('confirmation-dialog',{attrs:{"headline":_vm.$t ("create.label.dnssec.data")},on:{"ok":_vm.onSave},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.add'),expression:"'create.label.dnssec.add'"}],staticClass:"ma-0",on:{"click":_vm.onAdd}},on))]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('form',{attrs:{"novalidate":""}},[(!_vm.dsDisallowed && !_vm.dnskeyDisallowed)?_c('div',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.comment'),expression:"'create.label.dnssec.comment'"}],staticClass:"text-body-1"}):_vm._e(),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[(!_vm.dsDisallowed)?_c('v-row',{staticClass:"cgwng-bg-color-1"},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.dnskeyDisallowed)?_c('v-switch',{attrs:{"label":_vm.$t ('create.label.dnssec.ds')},model:{value:(_vm.enterDsRecordData),callback:function ($$v) {_vm.enterDsRecordData=$$v},expression:"enterDsRecordData"}}):_c('div',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.ds'),expression:"'create.label.dnssec.ds'"}],staticClass:"text-h6"})],1),(_vm.enterDsRecordData)?[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t ('create.label.dnssec.keytag'),"error-messages":_vm.validationErrors (
                  'dnssecData.dsData.keyTag', {
                    required: 'create.required.keytag',
                    range: {
                      key: 'general.invalid.range',
                      params: { min: 0, max: 65535 }
                    }
                  })},model:{value:(_vm.dnssecData.dsData.keyTag),callback:function ($$v) {_vm.$set(_vm.dnssecData.dsData, "keyTag", $$v)},expression:"dnssecData.dsData.keyTag"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t ('create.label.dnssec.algorithm'),"hint":_vm.dsAlgorithmHint,"persistent-hint":"","error-messages":_vm.validationErrors (
                  'dnssecData.dsData.algorithm', {
                    required: 'create.required.algorithm',
                    range: {
                      key: 'general.invalid.range',
                      params: { min: 0, max: 255 }
                    }
                  })},model:{value:(_vm.dnssecData.dsData.algorithm),callback:function ($$v) {_vm.$set(_vm.dnssecData.dsData, "algorithm", $$v)},expression:"dnssecData.dsData.algorithm"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t ('create.label.dnssec.maxsiglifetime'),"error-messages":_vm.validationErrors (
                  'dnssecData.dsData.maxSigLife', {
                    minValue: {
                      key: 'general.invalid.min',
                      params: { min: 0 }
                    }
                  })},model:{value:(_vm.dnssecData.dsData.maxSigLife),callback:function ($$v) {_vm.$set(_vm.dnssecData.dsData, "maxSigLife", $$v)},expression:"dnssecData.dsData.maxSigLife"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t ('create.label.dnssec.digesttype'),"hint":_vm.digestTypeHint,"persistent-hint":"","error-messages":_vm.validationErrors (
                  'dnssecData.dsData.digestType', {
                    required: 'create.required.digesttype',
                    range: {
                      key: 'general.invalid.range',
                      params: { min: 0, max: 255 }
                    }
                  })},model:{value:(_vm.dnssecData.dsData.digestType),callback:function ($$v) {_vm.$set(_vm.dnssecData.dsData, "digestType", $$v)},expression:"dnssecData.dsData.digestType"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"binary-data required",attrs:{"outlined":"","label":_vm.$t ('create.label.dnssec.hash'),"error-messages":_vm.validationErrors (
                  'dnssecData.dsData.hash', {
                    hex: 'create.invalid.hex',
                    required: 'create.required.hash'
                  })},model:{value:(_vm.dnssecData.dsData.hash),callback:function ($$v) {_vm.$set(_vm.dnssecData.dsData, "hash", $$v)},expression:"dnssecData.dsData.hash"}})],1)]:_vm._e()],2):_vm._e(),(!_vm.dnskeyDisallowed)?_c('v-row',{staticClass:"cgwng-bg-color-2"},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.dsDisallowed)?_c('v-switch',{attrs:{"label":_vm.$t ('create.label.dnssec.dnskey')},model:{value:(_vm.enterDnskeyData),callback:function ($$v) {_vm.enterDnskeyData=$$v},expression:"enterDnskeyData"}}):_c('div',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.dnskey'),expression:"'create.label.dnssec.dnskey'"}],staticClass:"text-h6"})],1),(_vm.enterDnskeyData)?[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t ('create.label.dnssec.flags'),"hint":_vm.flagsHint,"persistent-hint":"","error-messages":_vm.validationErrors (
                  'dnssecData.dnskeyData.flags', {
                    required: 'create.required.flags',
                    range: {
                      key: 'general.invalid.range',
                      params: { min: 0, max: 65535 }
                    }
                  })},model:{value:(_vm.dnssecData.dnskeyData.flags),callback:function ($$v) {_vm.$set(_vm.dnssecData.dnskeyData, "flags", $$v)},expression:"dnssecData.dnskeyData.flags"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t ('create.label.dnssec.protocol'),"hint":_vm.protocolHint,"persistent-hint":"","error-messages":_vm.validationErrors (
                  'dnssecData.dnskeyData.protocol', {
                    required: 'create.required.protocol',
                    range: {
                      key: 'general.invalid.range',
                      params: { min: 0, max: 255 }
                    }
                  })},model:{value:(_vm.dnssecData.dnskeyData.protocol),callback:function ($$v) {_vm.$set(_vm.dnssecData.dnskeyData, "protocol", $$v)},expression:"dnssecData.dnskeyData.protocol"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t ('create.label.dnssec.algorithm'),"hint":_vm.dnskeyAlgorithmHint,"persistent-hint":"","error-messages":_vm.validationErrors (
                  'dnssecData.dnskeyData.algorithm', {
                    required: 'create.required.algorithm',
                    range: {
                      key: 'general.invalid.range',
                      params: { min: 0, max: 255 }
                    }
                  })},model:{value:(_vm.dnssecData.dnskeyData.algorithm),callback:function ($$v) {_vm.$set(_vm.dnssecData.dnskeyData, "algorithm", $$v)},expression:"dnssecData.dnskeyData.algorithm"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"binary-data required",attrs:{"outlined":"","label":_vm.$t ('create.label.dnssec.key'),"error-messages":_vm.validationErrors (
                  'dnssecData.dnskeyData.publicKey', {
                    base64: 'create.invalid.base64',
                    required: 'create.required.key'
                  })},model:{value:(_vm.dnssecData.dnskeyData.publicKey),callback:function ($$v) {_vm.$set(_vm.dnssecData.dnskeyData, "publicKey", $$v)},expression:"dnssecData.dnskeyData.publicKey"}})],1)]:_vm._e()],2):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }