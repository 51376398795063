<messages>["./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-row class="cgwng-bg-color-1">
    <v-col
      v-if="!isFieldProhibited (fieldNamesMap.phoneNumber)"
      cols="12"
      sm="8"
      md="4"
      class="pa-2">
      <v-text-field
        v-model.trim="value.phoneNumber"
        :label="$t ('label.voicePhoneNumber')"
        :class="{required: isFieldPresent(fieldNamesMap.phoneNumber) && isFieldRequired(fieldNamesMap.phoneNumber)}"
        :error-messages="validationErrors (
          'value.phoneNumber',
          {
            phone: 'general.invalid.phone',
            required: 'required.phoneNumber'
          })"
        @input="updateData"
        @blur="$v.value.phoneNumber.$touch ()"/>
    </v-col>
    <v-col
      v-if="!isFieldProhibited (fieldNamesMap.phoneNumberExt)"
      cols="12"
      sm="4"
      md="2"
      class="pa-2">
      <v-text-field
        v-model.trim="value.phoneNumberExt"
        :class="{required: isFieldPresent(fieldNamesMap.phoneNumberExt) && isFieldRequired(fieldNamesMap.phoneNumberExt)}"
        :label="$t ('label.voicePhoneNumberExt')"
        :error-messages="validationErrors (
          'value.phoneNumberExt',
          {
            extention: 'required.extentionError',
            required: 'required.phoneNumberExt'
          })"
        @input="updateData"
        @blur="$v.value.phoneNumberExt.$touch ()"/>
    </v-col>
    <v-col
      v-if="!isFieldProhibited (fieldNamesMap.faxNumber)"
      cols="12"
      sm="8"
      md="4"
      class="pa-2">
      <v-text-field
        v-model.trim="value.faxNumber"
        :class="{required: isFieldPresent(fieldNamesMap.faxNumber) && isFieldRequired(fieldNamesMap.faxNumber)}"
        :label="$t ('label.faxNumber')"
        :error-messages="validationErrors (
          'value.faxNumber',
          {
            phone: 'general.invalid.fax',
            required: 'required.faxNumber'
          })"
        @input="updateData"
        @blur="$v.value.faxNumber.$touch ()"/>
    </v-col>
    <v-col
      v-if="!isFieldProhibited (fieldNamesMap.faxNumberExt)"
      cols="12"
      sm="4"
      md="2"
      class="pa-2">
      <v-text-field
        v-model.trim="value.faxNumberExt"
        :class="{required: isFieldPresent(fieldNamesMap.faxNumberExt) && isFieldRequired(fieldNamesMap.faxNumberExt)}"
        :label="$t ('label.faxNumberExt')"
        :error-messages="validationErrors (
          'value.faxNumberExt',
          {
            extention: 'required.extentionError',
            required: 'required.faxNumberExt'
          })"
        @input="updateData"
        @blur="$v.value.faxNumberExt.$touch ()"/>
    </v-col>
    <v-col
      v-if="!isFieldProhibited (fieldNamesMap.emailAddress)"
      cols="12"
      sm="6"
      class="pa-2">
      <v-text-field
        v-model.trim="value.emailAddress"
        spellcheck="false"
        :class="{required: isFieldPresent(fieldNamesMap.emailAddress) && isFieldRequired(fieldNamesMap.emailAddress)}"
        :label="$t ('label.emailAddress')"
        :error-messages="validationErrors (
          'value.emailAddress',
          {
            email: 'general.invalid.email',
            required: 'required.email'
          })"
        @input="updateData"
        @blur="$v.value.emailAddress.$touch ()"/>
    </v-col>
  </v-row>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {helpers} from 'vuelidate/lib/validators'
  import {phone, email} from '@/app/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import ContactFieldMixin from './components/ContactFieldMixin'

  const extention = helpers.regex ('extention', /^\d{0,8}$/)

  export default {
    name: 'ContactCommunicationData',

    mixins: [ContactFieldMixin, validationMixins],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    validations () {
      return {
        value: {
          phoneNumber: {
            phone,
            ...this.createValidators (this.fieldNamesMap.phoneNumber)
          },
          phoneNumberExt: {
            extention,
            ...this.createValidators (this.fieldNamesMap.phoneNumberExt)
          },
          faxNumber: {
            phone,
            ...this.createValidators (this.fieldNamesMap.faxNumber)
          },
          faxNumberExt: {
            extention,
            ...this.createValidators (this.fieldNamesMap.faxNumberExt)
          },
          emailAddress: {
            email,
            ...this.createValidators (this.fieldNamesMap.emailAddress)
          }
        }
      }
    },

    methods: {
      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$v.value.phoneNumber.$error ||
          this.$v.value.faxNumber.$error || this.$v.value.emailAddress.$error ||
          this.$v.value.phoneNumberExt.$error ||
          this.$v.value.faxNumberExt.$error
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return !this.$v.value.phoneNumber.$invalid &&
          !this.$v.value.faxNumber.$invalid &&
          !this.$v.value.emailAddress.$invalid &&
          !this.$v.value.phoneNumberExt.$invalid &&
          !this.$v.value.faxNumberExt.$invalid
      },

      updateData () {
        this.$emit ('input', this.value)
      }
    }
  }
</script>
