/** allowed types for domain-like objects */
export const TYPES = {
  DOMAIN: 'domain',
  APPLICATION: 'application'
}

/** `type` property for components, working with domain-like objects */
export const typeProp = {
  type: String,
  default: TYPES.DOMAIN,
  validator (v) {
    return Object.entries (TYPES).some (it => it[1] === v)
  }
}
