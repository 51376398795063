<messages>["./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <div v-if="value">
    <v-row>
      <v-col v-if="isMultiple" cols="12">
        <v-checkbox
          v-model="enabled"
          :disabled="enabled && !isDeactivatable"
          :label="$t ('label.enableAddress')"/>
      </v-col>
      <v-col v-if="isTitleAllowed" cols="12">
        <v-text-field
          v-model.trim="value.title"
          :disabled="!enabled"
          :label="$t ('label.title')"
          @input="updateAddress"/>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-if="!isFieldProhibited (fieldNamesMap.name)"
          v-model.trim="value.name"
          :class="{required: isFieldPresent(fieldNamesMap.name) ? isFieldRequired(fieldNamesMap.name) : true}"
          :label="$t ('label.name')"
          :disabled="!enabled"
          :error-messages="requiredErrors ('value.name', 'required.name')"
          @blur="$v.value.name.$touch"
          @input="updateAddress"/>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-if="!isFieldProhibited (fieldNamesMap.organization)"
          v-model.trim="value.organization"
          :class="{required: isFieldPresent(fieldNamesMap.organization) && isFieldRequired(fieldNamesMap.organization)}"
          :label="$t ('label.organization')"
          :disabled="!enabled"
          :error-messages="requiredErrors ('value.organization', 'required.organization')"
          @blur="$v.value.organization.$touch"
          @input="updateAddress"/>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-if="!isFieldProhibited (fieldNamesMap.street)"
          v-model.trim="streetString"
          :class="{required: isFieldPresent(fieldNamesMap.street) ? isFieldRequired(fieldNamesMap.street) : true}"
          :label="$t ('label.streets')"
          :disabled="!enabled"
          rows="3"
          auto-grow
          :error-messages="requiredErrors ('streetString', 'required.street')"
          @blur="$v.streetString.$touch"
          @input="onStreetInput"/>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-if="!isFieldProhibited (fieldNamesMap.city)"
          v-model.trim="value.city"
          :class="{required: isFieldPresent(fieldNamesMap.city) ? isFieldRequired(fieldNamesMap.city) : true}"
          :label="$t ('label.city')"
          :disabled="!enabled"
          :error-messages="requiredErrors ('value.city', 'required.city')"
          @blur="$v.value.city.$touch"
          @input="updateAddress"/>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-if="!isFieldProhibited (fieldNamesMap.postalCode)"
          v-model.trim="value.postalCode"
          :class="{required: isFieldPresent(fieldNamesMap.postalCode) ? isFieldRequired(fieldNamesMap.postalCode) : true}"
          spellcheck="false"
          :disabled="!enabled"
          :label="$t ('label.postalCode')"
          :error-messages="requiredErrors (
            'value.postalCode',
            'required.postalCode')"
          @blur="$v.value.postalCode.$touch"
          @input="updateAddress"/>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-if="!isFieldProhibited (fieldNamesMap.stateOrProvince)"
          v-model.trim="value.stateOrProvince"
          :label="$t ('label.stateOrProvince')"
          :disabled="!enabled"
          :class="{required: isFieldPresent(fieldNamesMap.stateOrProvince) && isFieldRequired(fieldNamesMap.stateOrProvince)}"
          :error-messages="requiredErrors ('value.stateOrProvince', 'required.stateOrProvince')"
          @blur="$v.value.stateOrProvince.$touch"
          @input="updateAddress"/>
      </v-col>
      <v-col cols="12">
        <country-select
          v-if="!isFieldProhibited (fieldNamesMap.countryCode)"
          v-model.trim="value.countryCode"
          clearable
          :required="isFieldRequired(fieldNamesMap.countryCode)"
          :disabled="!enabled"
          :label="$t ('label.countryCode')"
          :class="{required: isFieldPresent(fieldNamesMap.countryCode) ? isFieldRequired(fieldNamesMap.countryCode) : true}"
          :error-messages="requiredErrors ('value.countryCode', 'required.country')"
          @blur="$v.value.countryCode.$touch"
          @input="updateCountry"/>
      </v-col>
    </v-row>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {required} from 'vuelidate/lib/validators'

  import CountrySelect from '@/app/core/components/CountrySelect'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import ContactFieldMixin from './components/ContactFieldMixin'

  export default {
    name: 'ContactAddress',

    components: {
      CountrySelect
    },

    mixins: [ContactFieldMixin, validationMixins],

    props: {
      value: {
        type: Object,
        required: true
      },
      required: {
        type: Boolean,
        default: false
      },
      isTitleAllowed: {
        type: Boolean,
        default: false
      },
      isMultiple: {
        type: Boolean,
        default: false
      },
      isDeactivatable: {
        type: Boolean,
        default: false
      },
      isEnabled: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        streetString: ''
      }
    },

    computed: {
      enabled: {
        get () {
          return this.isEnabled
        },
        set (newVal) {
          this.$emit ('update:isEnabled', newVal)
        }
      }
    },

    watch: {
      streetString (newString) {
        this.$nextTick (function () {
          this.value.street = this.streetStringToArray (newString)
        })
      },

      'value.street' (newValue) {
        this.streetString = this.streetArrayToString (newValue)
      }
    },

    validations () {
      return {
        streetString: {
          ...!this.isEnabled
            ? {}
            : {
              ...this.isFieldPresent (this.fieldNamesMap.street)
                ? this.createValidators (this.fieldNamesMap.street)
                : {required}
            }
        },
        value: {
          name: {
            ...!this.isEnabled
              ? {}
              : {
                ...this.isFieldPresent (this.fieldNamesMap.name)
                  ? this.createValidators (this.fieldNamesMap.name)
                  : {required}
              }
          },
          organization: {
            ...!this.isEnabled ? {} : {...this.createValidators (this.fieldNamesMap.organization)}
          },
          city: {
            ...!this.isEnabled
              ? {}
              : {
                ...this.isFieldPresent (this.fieldNamesMap.city)
                  ? this.createValidators (this.fieldNamesMap.city)
                  : {required}
              }
          },
          postalCode: {
            ...!this.isEnabled
              ? {}
              : {
                ...this.isFieldPresent (this.fieldNamesMap.postalCode)
                  ? this.createValidators (this.fieldNamesMap.postalCode)
                  : {required}
                }
          },
          stateOrProvince: {
            ...!this.isEnabled ? {} : {...this.createValidators (this.fieldNamesMap.stateOrProvince)}
          },
          countryCode: {
            ...!this.isEnabled
              ? {}
              : {
                ...this.isFieldPresent (this.fieldNamesMap.countryCode)
                  ? this.createValidators (this.fieldNamesMap.countryCode)
                  : {required}
              }
          }
        }
      }
    },

    created () {
      if (this.value && Array.isArray (this.value.street)) {
        this.streetString = this.streetArrayToString (this.value.street)
      }
    },

    methods: {
      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$v.streetString.$error || this.$v.value.name.$error ||
          this.$v.value.city.$error || this.$v.value.postalCode.$error ||
          this.$v.value.countryCode.$error || this.$v.value.stateOrProvince.$error ||
          this.$v.value.organization.$error
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return !this.$v.streetString.$invalid && !this.$v.value.name.$invalid &&
          !this.$v.value.city.$invalid && !this.$v.value.postalCode.$invalid &&
          !this.$v.value.countryCode.$invalid && !this.$v.value.stateOrProvince.$invalid &&
          !this.$v.value.organization.$invalid
      },

      /**
       * Emit an input event in case the address has been updated.
       */
      updateAddress () {
        this.$emit ('input', this.value)
      },

      /**
       * Validate the country selection and emit an input event in case the
       * country has been updated.
       */
      updateCountry () {
        this.$v.value.countryCode.$touch ()
        this.updateAddress ()
      },

      /**
       * Convert the given street array into a string, array elements separated
       * by line breaks and number of lines restricted to 3.
       *
       * @param {array} array     the array to convert
       * @return {string}         the resulting string
       */
      streetArrayToString (array) {
        return array.slice (0, 3).join ('\n')
      },

      /**
       * Convert the given street string into an array.
       *
       * @param {string} string     the string to convert
       * @return {array}            the resulting array
       */
      streetStringToArray (string) {
        return string.replace (/\r?\n/, '\n').split (/\n/)
      },

      /**
       * Restrict the street text area to three lines.
       *
       * @param {string} input      the current text area input
       */
      onStreetInput (input) {
        this.$nextTick (function () {
          const lines = this.streetStringToArray (input)

          if (lines.length > 3) {
            this.streetString = this.streetArrayToString (lines)
          }

          this.updateAddress ()
        })
      }
    }
  }
</script>
