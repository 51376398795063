<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t (`create.label.dnssec.data`)"
    @ok="onSave">
    <template #activator="{ on }">
      <v-btn
        v-t="'create.label.dnssec.add'"
        class="ma-0"
        v-on="on"
        @click="onAdd"/>
    </template>
    <form novalidate>
      <div
        v-if="!dsDisallowed && !dnskeyDisallowed"
        v-t="'create.label.dnssec.comment'"
        class="text-body-1"/>
      <v-card-text>
        <v-container fluid>
          <v-row
            v-if="!dsDisallowed"
            class="cgwng-bg-color-1">
            <v-col cols="12">
              <v-switch
                v-if="!dnskeyDisallowed"
                v-model="enterDsRecordData"
                :label="$t ('create.label.dnssec.ds')"/>
              <div
                v-else v-t="'create.label.dnssec.ds'"
                class="text-h6"/>
            </v-col>
            <template v-if="enterDsRecordData">
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="dnssecData.dsData.keyTag"
                  class="required"
                  :label="$t ('create.label.dnssec.keytag')"
                  :error-messages="validationErrors (
                    'dnssecData.dsData.keyTag', {
                      required: 'create.required.keytag',
                      range: {
                        key: 'general.invalid.range',
                        params: { min: 0, max: 65535 }
                      }
                    })"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="dnssecData.dsData.algorithm"
                  class="required"
                  :label="$t ('create.label.dnssec.algorithm')"
                  :hint="dsAlgorithmHint"
                  persistent-hint
                  :error-messages="validationErrors (
                    'dnssecData.dsData.algorithm', {
                      required: 'create.required.algorithm',
                      range: {
                        key: 'general.invalid.range',
                        params: { min: 0, max: 255 }
                      }
                    })"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="dnssecData.dsData.maxSigLife"
                  :label="$t ('create.label.dnssec.maxsiglifetime')"
                  :error-messages="validationErrors (
                    'dnssecData.dsData.maxSigLife', {
                      minValue: {
                        key: 'general.invalid.min',
                        params: { min: 0 }
                      }
                    })"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="dnssecData.dsData.digestType"
                  class="required"
                  :label="$t ('create.label.dnssec.digesttype')"
                  :hint="digestTypeHint"
                  persistent-hint
                  :error-messages="validationErrors (
                    'dnssecData.dsData.digestType', {
                      required: 'create.required.digesttype',
                      range: {
                        key: 'general.invalid.range',
                        params: { min: 0, max: 255 }
                      }
                    })"/>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="dnssecData.dsData.hash"
                  class="binary-data required"
                  outlined
                  :label="$t ('create.label.dnssec.hash')"
                  :error-messages="validationErrors (
                    'dnssecData.dsData.hash', {
                      hex: 'create.invalid.hex',
                      required: 'create.required.hash'
                    })"/>
              </v-col>
            </template>
          </v-row>
          <v-row
            v-if="!dnskeyDisallowed"
            class="cgwng-bg-color-2">
            <v-col cols="12">
              <v-switch
                v-if="!dsDisallowed"
                v-model="enterDnskeyData"
                :label="$t ('create.label.dnssec.dnskey')"/>
              <div
                v-else v-t="'create.label.dnssec.dnskey'"
                class="text-h6"/>
            </v-col>
            <template v-if="enterDnskeyData">
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="dnssecData.dnskeyData.flags"
                  class="required"
                  :label="$t ('create.label.dnssec.flags')"
                  :hint="flagsHint"
                  persistent-hint
                  :error-messages="validationErrors (
                    'dnssecData.dnskeyData.flags', {
                      required: 'create.required.flags',
                      range: {
                        key: 'general.invalid.range',
                        params: { min: 0, max: 65535 }
                      }
                    })"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="dnssecData.dnskeyData.protocol"
                  class="required"
                  :label="$t ('create.label.dnssec.protocol')"
                  :hint="protocolHint"
                  persistent-hint
                  :error-messages="validationErrors (
                    'dnssecData.dnskeyData.protocol', {
                      required: 'create.required.protocol',
                      range: {
                        key: 'general.invalid.range',
                        params: { min: 0, max: 255 }
                      }
                    })"/>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="dnssecData.dnskeyData.algorithm"
                  class="required"
                  :label="$t ('create.label.dnssec.algorithm')"
                  :hint="dnskeyAlgorithmHint"
                  persistent-hint
                  :error-messages="validationErrors (
                    'dnssecData.dnskeyData.algorithm', {
                      required: 'create.required.algorithm',
                      range: {
                        key: 'general.invalid.range',
                        params: { min: 0, max: 255 }
                      }
                    })"/>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="dnssecData.dnskeyData.publicKey"
                  class="binary-data required"
                  outlined
                  :label="$t ('create.label.dnssec.key')"
                  :error-messages="validationErrors (
                    'dnssecData.dnskeyData.publicKey', {
                      base64: 'create.invalid.base64',
                      required: 'create.required.key'
                    })"/>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>
    </form>
  </confirmation-dialog>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {and, between, integer, minValue, requiredIf}
    from 'vuelidate/lib/validators'

  import _cloneDeep from 'lodash/cloneDeep'
  import {base64, hex, validateIf} from '@/app/validators'
  import {removeWhitespace} from '@/app/utils/string'

  import {mapGetters} from 'vuex'

  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import dnssecMixins from './DomainDnssecHelper'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  export default {
    name: 'DomainDnssecDialog',

    components: {
      ConfirmationDialog
    },

    mixins: [validationMixins, dnssecMixins],

    props: {
      value: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        required: true
      },
      showDs: {
        type: Boolean
      },
      showDnskey: {
        type: Boolean
      }
    },

    data () {
      return {
        isAdd: false,
        enterDsRecordData: true,
        enterDnskeyData: false,
        dnssecData: {
          dsData: {},
          dnskeyData: {}
        }
      }
    },

    computed: {
      ...mapGetters ('meta', {
        dsDisallowed: 'isDsDataDisallowed',
        dnskeyDisallowed: 'isDnskeyDataDisallowed'
      }),

      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      /**
       * Get the hint for the flags field (depending on actual input).
       *
       * @return {String}     the hint
       */
      flagsHint () {
        return this.flagsString (this.dnssecData.dnskeyData?.flags)
      },

      /**
       * Get the hint for the protocol field (depending on actual input).
       *
       * @return {String}     the hint
       */
      protocolHint () {
        return this.protocolString (this.dnssecData.dnskeyData?.protocol)
      },

      /**
       * Get the hint for the DNSKEY algorithm field (depending on actual
       * input).
       *
       * @return {String}     the hint
       */
      dnskeyAlgorithmHint () {
        return this.algorithmString (this.dnssecData.dnskeyData?.algorithm)
      },

      /**
       * Get the hint for the DS Record algorithm field (depending on actual
       * input).
       *
       * @return {String}     the hint
       */
      dsAlgorithmHint () {
        return this.algorithmString (this.dnssecData.dsData?.algorithm)
      },

      /**
       * Get the hint for the digest type field (depending on actual input).
       *
       * @return {String}     the hint
       */
      digestTypeHint () {
        return this.digestTypeString (this.dnssecData.dsData?.digestType)
      }
    },

    watch: {
      value () {
        if (!this.isAdd) {
          this.populateForm ()
        } else {
          this.isAdd = false
        }

        this.determineVisibleSections ()
      },

      'dnssecData.dnskeyData.publicKey' (newValue) {
        if (newValue) {
          this.dnssecData.dnskeyData.publicKey = removeWhitespace (newValue)
        }
      },

      'dnssecData.dsData.hash' (newValue) {
        if (newValue) {
          this.dnssecData.dsData.hash = removeWhitespace (newValue)
        }
      },

      showDs (newValue) {
        if (newValue) this.enterDsRecordData = true
      },

      showDnskey (newValue) {
        if (newValue) this.enterDnskeyData = true
      }
    },

    validations: {
      dnssecData: {
        dnskeyData: {
          flags: {
            required: requiredIf (function () {
              return this.enterDnskeyData
            }),
            range: validateIf (function () {
              return this.enterDnskeyData
            }, and (integer, between (0, 65535)))
          },
          protocol: {
            required: requiredIf (function () {
              return this.enterDnskeyData
            }),
            range: validateIf (function () {
              return this.enterDnskeyData
            }, and (integer, between (0, 255)))
          },
          algorithm: {
            required: requiredIf (function () {
              return this.enterDnskeyData
            }),
            range: validateIf (function () {
              return this.enterDnskeyData
            }, and (integer, between (0, 255)))
          },
          publicKey: {
            required: requiredIf (function () {
              return this.enterDnskeyData
            }),
            base64: validateIf (function () {
              return this.enterDnskeyData
            }, base64)
          }
        },
        dsData: {
          keyTag: {
            required: requiredIf (function () {
              return this.enterDsRecordData
            }),
            range: validateIf (function () {
              return this.enterDsRecordData
            }, and (integer, between (0, 65535)))
          },
          algorithm: {
            required: requiredIf (function () {
              return this.enterDsRecordData
            }),
            range: validateIf (function () {
              return this.enterDsRecordData
            }, and (integer, between (0, 255)))
          },
          maxSigLife: {
            minValue: validateIf (function () {
              return this.enterDsRecordData
            }, and (integer, minValue (0)))
          },
          digestType: {
            required: requiredIf (function () {
              return this.enterDsRecordData
            }),
            range: validateIf (function () {
              return this.enterDsRecordData
            }, and (integer, between (0, 255)))
          },
          hash: {
            required: requiredIf (function () {
              return this.enterDsRecordData
            }),
            hex: validateIf (function () {
              return this.enterDsRecordData
            }, hex)
          }
        }
      }
    },

    created () {
      this.populateForm ()
      this.determineVisibleSections ()
    },

    methods: {
      /**
       * Get a default DNSKEY data record.
       *
       * For convenience, the protocol field of the DNSKEY record will be
       * pre-populated with the only valid protocol value (3) and the DNSKEY
       * flags field with the value 257.
       *
       * @return {Object}     the DNSKEY data record
       */
      getDefaultDnskeyData () {
        return {
          flags: 257,
          protocol: 3,
          algorithm: '',
          publicKey: ''
        }
      },

      /**
       * Get a default DS data record.
       *
       * @return {Object}     the DS data record
       */
      getDefaultDsData () {
        return {
          keyTag: '',
          algorithm: '',
          maxSigLife: '',
          digestType: '',
          hash: ''
        }
      },

      /**
       * Prepare the DNSSEC data dialog for entering a new DNSKEY/DS data set by
       * resetting the validation and pre-populating the dialog with default
       * data.
       */
      onAdd () {
        this.$v.dnssecData.$reset ()
        this.dnssecData.dnskeyData = this.getDefaultDnskeyData ()
        this.dnssecData.dsData = this.getDefaultDsData ()
        this.isAdd = true
      },

      /**
       * Save the DNSKEY/DS data from the form to the DNSSEC data table (if
       * valid).
       */
      onSave () {
        this.$v.dnssecData.$touch ()

        if (!this.$v.dnssecData.$invalid) {
          if (!this.enterDnskeyData) this.dnssecData.dnskeyData = {}
          if (!this.enterDsRecordData) this.dnssecData.dsData = {}
          this.$emit ('ok', this.dnssecData)
          this.$v.dnssecData.$reset ()
          this.showDialog = false
        }
      },

      /**
       * Populate the DNSSEC data form with the data passed as value parameter
       * (if any).
       */
      populateForm () {
        this.dnssecData = _cloneDeep (this.data)
      },

      /**
       * Determine which of the DNSKEY/DS data sections shall be visible.
       */
      determineVisibleSections () {
        const hasDnskeyData =
          this.dnssecData.dnskeyData?.publicKey !== undefined &&
          this.dnssecData.dnskeyData?.publicKey !== ''

        const hasDsData =
          this.dnssecData.dsData?.hash !== undefined &&
          this.dnssecData.dsData?.hash !== ''

        if (hasDsData) {
          this.enterDsRecordData = true
        } else {
          this.dnssecData.dsData = this.getDefaultDsData ()
          this.enterDsRecordData = !this.dsDisallowed && !hasDnskeyData
        }

        if (hasDnskeyData) {
          this.enterDnskeyData = true
        } else {
          this.dnssecData.dnskeyData = this.getDefaultDnskeyData ()
          this.enterDnskeyData = false
        }

        if (this.dsDisallowed) {
          this.enterDsRecordData = false
          this.enterDnskeyData = true
        }

        if (this.dnskeyDisallowed) {
          this.enterDsRecordData = true
          this.enterDnskeyData = false
        }
      }
    }
  }
</script>

<!--
================================================================================
  Styling
================================================================================
-->

<style scoped>
.binary-data div.input-group__input {
  border: 1px solid !important;
}
</style>
