<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-autocomplete
    v-model.trim="handle"
    class="required"
    spellcheck="false"
    :disabled="disabled"
    :loading="loadingContacts"
    :search-input.sync="searchString"
    :items="sortedHandleItems"
    :error-messages="errorMessages"
    :label="label"
    :hint="hint"
    persistent-hint
    @input.native="onInput">
    <template #item="{item, on, attr}">
      <v-list-item v-bind="attr" v-on="on">
        <v-list-item-content>
          <v-list-item-title :class="{deletedItemHeadline: item.deleted}">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import _debounce from 'lodash/debounce'
  import {mapActions} from 'vuex'

  export default {
    name: 'ContactHandleSelect',

    props: {
      value: {
        type: String,
        default: undefined
      },
      registryId: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      hint: {
        type: String,
        default: undefined
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      includeDeleted: {
        type: Boolean,
        default: false
      },
      errorMessages: {
        type: Array,
        default: undefined
      }
    },

    data () {
      return {
        searchString: '',
        handle: undefined,
        handleItems: [],
        loadingContacts: false,
        contacts: []
      }
    },

    computed: {
      sortedHandleItems () {
        const arrayCopy = this.handleItems
          .slice (0)
          .filter (e => e.text !== this.searchString)
          .sort ((e1, e2) => e1.text.localeCompare (e2.text))

        return [
          ...(
            this.searchString
              ? [{text: this.searchString, value: this.searchString}]
              : []
          ),
          ...arrayCopy
        ]
      }
    },

    watch: {
      value: {
        handler (newValue) {
          this.handle = newValue
          this.searchString = newValue

          if (!newValue || newValue.length === 0) {
            this.handleItems = []
          }
        },
        immediate: true
      },

      registryId () {
        this.handle = ''
        this.searchString = ''
        this.handleItems = []
      },

      handle () {
        this.$emit ('input', this.handle)
      },

      searchString (newValue) {
        if (newValue !== null && newValue !== undefined) {
          if (newValue.length === 0) {
            this.handleItems = []
          } else {
            this.handleItems = [{text: newValue, value: newValue}]
          }
        }
        this.handle = newValue
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Load contact suggestions from the backend when the user types a
       * contact handle.
       *
       * Suggestions are loaded at most each second and only if at least three
       * characters have been entered. The entered value will be used for a
       * prefix search on the contact name and on the contact handle. The
       * entered value itself will stay in the list of suggested handles in case
       * the literal handle is entered by the user and not a name/handle prefix.
       */
      onInput: _debounce (function () {
        if (this.searchString && this.searchString.length > 2) {
          this.fetchContactData ({name: this.searchString})
          this.fetchContactData ({handle: this.searchString})
        }
      }, 1000),

      /**
       * Load contact suggestions matching the given search criterion.
       */
      fetchContactData (searchCriterion) {
        const params = {
          // maximal amount of results
          size: 20,
          filter: {
            registryTypes: [this.registryId],
            ...this.includeDeleted
              ? {
                contactState: 'any'
              }
              : {
                  contactState: 'active'
              },
            ...searchCriterion
          }
        }

        this.loadingContacts = true

        this.fetchData ({
          op: 'contact/list',
          params,
          cb: data => {
            this.handleItems.push (...data.list
              .map (function (c) {
                let itemText = c.refID

                if (c.name) {
                  itemText += ' (' + c.name + ')'
                }

                return {text: itemText, value: c.refID, deleted: c.deleted}
              })
            )
          },
          cbError: () => {
            // silent error handling: do not provide any suggestions
          },
          cbFinal: () => {
            this.loadingContacts = false
          }
        })
      }
    }
  }
</script>
