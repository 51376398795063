<messages>["./AuthInfo"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-text-field
    v-model.trim="authInfo"
    spellcheck="false"
    :label="$t ('label')"
    append-icon="text_fields"
    :hint="authInfo === null || authInfo === '' ? $t ('generationHint') : ''"
    persistent-hint
    @click:append="generateAuthInfo"
    @input="updateData"/>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'AuthInfo',

    props: {
      value: {
        type: String,
        default: undefined
      },
      registryId: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        authInfo: null
      }
    },

    watch: {
      value (newValue) {
        this.authInfo = newValue
      },

      registryId () {
        this.authInfo = null
      }
    },

    created () {
      this.authInfo = this.value
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      updateData () {
        this.$emit ('input', this.authInfo)
      },

      /**
       * Fetch a generated authinfo from the backend.
       */
      generateAuthInfo () {
        this.fetchData ({
          op: 'registry/getAuthInfo',
          params: {
            registryId: this.registryId
          },
          cb: data => {
            this.authInfo = data.authInfo
            this.updateData ()
          }
        })
      }
    }
  }
</script>
