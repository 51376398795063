<messages>["./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <registry-object-create-update
    type="contact"
    :is-create="isCreate"
    :name="contactObject.contact.handle"
    :registry-id="contactObject.contact.registryId"
    :client-id="contactObject.contact.clientId"
    :show-buttons="showStepper && !inline"
    :show-client-and-registry="!inline"
    :show-registry-warning="incompleteRegistrySupport"
    :show-card-border="!inline"
    :response="orderProcessingResponse"
    :is-loading="isLoading"
    :color="color"
    @registrySelected="onRegistrySelected"
    @loaded="onCreateUpdateComponentLoaded"
    @clientSelected="onClientSelected"
    @submit="onSubmit"
    @reset="onReset">
    <!-- contact data stepper -->
    <template #content>
      <v-stepper
        v-if="showStepper"
        v-model="step"
        vertical
        class="mb-12">
        <!-- address data -->
        <v-stepper-step
          step="1"
          editable
          :rules="[() => isAddressDataValid ()]"
          :complete="step > 1">
          <span v-t="'create.addressData'"/>
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-card class="elevation-0">
            <form
              novalidate
              @submit.prevent>
              <v-card-text>
                <v-row class="cgwng-bg-color-1">
                  <v-col
                    v-if="intAddressEnabled && !!contactObject.contact.addresses.int"
                    class="pa-2"
                    :class="[locAddressEnabled && !$vuetify.breakpoint.xs ? 'col-6' : 'col-12']">
                    <v-subheader
                      v-t="'label.intAddress'"
                      class="pa-0"/>
                    <contact-address
                      ref="contactAddressInt"
                      v-model="contactObject.contact.addresses.int"
                      :is-multiple="bothAddressesEnabled"
                      :is-enabled.sync="addressIntEnabled"
                      :is-deactivatable="intDeactivatable"
                      required
                      :is-title-allowed="thinRegistry"/>
                  </v-col>
                  <v-col
                    v-if="locAddressEnabled && !!contactObject.contact.addresses.loc"
                    class="pa-2"
                    :class="[intAddressEnabled && !$vuetify.breakpoint.xs ? 'col-6' : 'col-12']">
                    <v-subheader
                      v-t="'label.locAddress'"
                      class="pa-0"/>
                    <contact-address
                      ref="contactAddressLoc"
                      v-model="contactObject.contact.addresses.loc"
                      :is-multiple="bothAddressesEnabled"
                      :is-enabled.sync="addressLocEnabled"
                      :is-deactivatable="locDeactivatable"
                      :required="!intAddressEnabled"
                      :is-title-allowed="thinRegistry"/>
                  </v-col>
                </v-row>
                <!-- copy/delete buttons -->
                <v-row
                  v-if="bothAddressesEnabled"
                  class="cgwng-bg-color-1">
                  <v-col
                    cols="6"
                    class="pa-2">
                    <div class="text-right">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            class="mr-1"
                            rounded
                            color="primary"
                            v-on="on"
                            @click="deleteAddress (
                              contactObject.contact.addresses.int)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span v-t="'label.deleteInt'"/>
                      </v-tooltip>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            rounded
                            color="primary"
                            v-on="on"
                            @click="copyAddress (
                              contactObject.contact.addresses.int,
                              contactObject.contact.addresses.loc)">
                            <v-icon>content_copy</v-icon>
                            <v-icon>keyboard_arrow_right</v-icon>
                          </v-btn>
                        </template>
                        <span v-t="'label.copyToLoc'"/>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pa-2">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          class="mr-1"
                          rounded
                          color="primary"
                          v-on="on"
                          @click="copyAddress (
                            contactObject.contact.addresses.loc,
                            contactObject.contact.addresses.int)">
                          <v-icon>keyboard_arrow_left</v-icon>
                          <v-icon>content_copy</v-icon>
                        </v-btn>
                      </template>
                      <span v-t="'label.copyToInt'"/>
                    </v-tooltip>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          rounded
                          color="primary"
                          v-on="on"
                          @click="deleteAddress (
                            contactObject.contact.addresses.loc)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span v-t="'label.deleteLoc'"/>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <navigation-buttons
                  :step="1"
                  @focus="step=1"
                  @next="step = 2"/>
              </v-card-actions>
            </form>
          </v-card>
        </v-stepper-content>

        <!-- communication data -->
        <v-stepper-step
          step="2"
          editable
          :rules="[() => isCommunicationDataValid ()]"
          :complete="step > 2">
          <span v-t="'create.communicationData'"/>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-card class="elevation-0">
            <form
              novalidate
              @submit.prevent>
              <v-card-text>
                <contact-communication-data
                  ref="contactCommunicationData"
                  v-model="communicationData"/>
                <v-row v-if="!isCreate">
                  <v-col>
                    <v-switch
                      v-model="contactObject.contact.wapOverride"
                      color="red"
                      class="mt-6"
                      :class="{ 'red-label': contactObject.contact.wapOverride }"
                      :label="$t ('create.wapOverride.title')"
                      hide-details/>
                  </v-col>
                  <v-col>
                    <div class="grey--text text--darken-1 text-body-1">
                      <p
                        v-for="p in [1, 2, 3, 4]"
                        :key="p"
                        v-html="$t (`create.wapOverride.${p}`)"/>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <navigation-buttons
                  :step="2"
                  @focus="step=2"
                  @prev="step = 1"
                  @next="step = 3"/>
              </v-card-actions>
            </form>
          </v-card>
        </v-stepper-content>

        <!-- registry-specific data -->
        <template v-if="showRegistrySpecificDataStep">
          <v-stepper-step
            step="3"
            editable
            :rules="[() => isSpecificDataValid ()]"
            :complete="step > 3">
            <span v-t="'create.specificData'"/>
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card class="elevation-0">
              <form
                novalidate
                @submit.prevent>
                <v-card-text>
                  <registry-specific-data
                    ref="contactRegistrySpecificData"
                    v-model="contactObject.specificFieldData"/>
                </v-card-text>
                <v-card-actions>
                  <navigation-buttons
                    :step="3"
                    @prev="step = 2"
                    @next="step = 4"/>
                </v-card-actions>
              </form>
            </v-card>
          </v-stepper-content>
        </template>

        <!-- administrative data -->
        <v-stepper-step
          :step="administrativeDataStepNumber"
          editable
          :rules="[() => isAdministrativeDataValid ()]">
          <span v-t="'create.furtherData'"/>
        </v-stepper-step>
        <v-stepper-content :step="administrativeDataStepNumber">
          <v-card class="elevation-0">
            <form
              novalidate
              @submit.prevent>
              <v-card-text>
                <contact-administrative-data
                  ref="contactAdministrativeData"
                  v-model="administrativeData"
                  :is-create="isCreate"/>
              </v-card-text>
              <v-card-actions>
                <navigation-buttons
                  :step="administrativeDataStepNumber"
                  last
                  @prev="step = administrativeDataStepNumber - 1"/>
              </v-card-actions>
            </form>
          </v-card>
        </v-stepper-content>
      </v-stepper>
      <v-row v-if="!isCreate" justify="end">
        <v-btn class="mr-3" @click="openAssosiatedDomain">
          {{ $t('general.button.associated') }}
        </v-btn>
      </v-row>
    </template>
  </registry-object-create-update>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import ContactAddress from './ContactAddress'
  import ContactAdministrativeData from './ContactAdministrativeData'
  import ContactCommunicationData from './ContactCommunicationData'

  import ValidationParent from '@/app/core/mixins/ValidationParent'
  import StepValidatorMixin from '@/app/core/mixins/StepValidatorMixin'

  import NavigationButtons
    from '@/app/core/components/RegistryObject/NavigationButtons'

  import RegistryObjectCreateUpdate
    from '@/app/core/components/RegistryObject/RegistryObjectCreateUpdate'

  import RegistrySpecificData
    from '@/app/core/components/RegistryObject/RegistrySpecificData'

  import {
    getDefaultContactAddress,
    getDefaultContactParams,
    getDefaultCreateParams,
    DefaultEditProviderChain,
    getAddOnFieldData
  } from './constants'

  import {mapActions, mapGetters, mapMutations} from 'vuex'

  import _cloneDeep from 'lodash/cloneDeep'
  import _isEmpty from 'lodash/isEmpty'
  import _isEqual from 'lodash/isEqual'
  import _pick from 'lodash/pick'

  import {keyValueArrayToObject, objectToKeyValueArray} from '@/app/utils/array'
  import {removePrefix} from '@/app/utils/string'
  import errorTranslator from '@/app/services/errortranslator'

  import {CONTACT_ADDRESS_TYPES} from '@/app/store/modules/meta'

  import {byRegistryContact as getDomainSearchFilterByContact}
    from '@/app/pages/Domain/components/DomainFilter'

  import {isUpdateProhibited} from '@/app/core/components/RegistryObject/StatesDialog'

  export default {
    name: 'ContactCreateUpdate',

    components: {
      ContactAddress,
      ContactAdministrativeData,
      ContactCommunicationData,
      NavigationButtons,
      RegistryObjectCreateUpdate,
      RegistrySpecificData
    },

    mixins: [ValidationParent, StepValidatorMixin],

    props: {
      isCreate: {type: Boolean, default: false},
      inline: {type: Boolean, default: false},
      contactData: {
        type: Object,
        default: undefined
      },
      registryId: {
        type: String,
        default: undefined
      },
      clientId: {
        type: String,
        default: undefined,
        validator (value) {
          return !isNaN (Number (value))
        }
      },
      color: {type: String, default: 'white'}
    },

    data () {
      return {
        incompleteRegistrySupport: false,
        addressLocEnabled: true,
        savedLocAddress: getDefaultContactAddress (),
        addressIntEnabled: true,
        savedIntAddress: getDefaultContactAddress (),
        step: 1,
        showRegistrySpecificDataStep: false,
        orderProcessingResponse: null,
        contactObject: {
          contact: {},
          editProviderChain: false,
          specificFieldData: [],
          addOnFieldData: []
        },
        isLoading: false,
        defaultContact: null
      }
    },

    computed: {
      ...mapGetters ({
        contactCreateData: 'create/getContactCreateData',
        metaDataSupported: 'meta/isMetaDataSupported',
        thinRegistry: 'meta/isThinRegistry',
        mandatoryContactAddress: 'meta/getMandatoryContactAddress',
        supportedContactAddress: 'meta/getSupportedContactAddress'
      }),

      domainSearchLink () {
        return {
          name: 'domain.search',
          query: {
            filter: JSON.stringify (
              getDomainSearchFilterByContact (
                this.contactObject.contact.registryId, this.contactObject.contact.handle))
          }
        }
      },

      locDeactivatable () {
        return this.addressIntEnabled && !this.isLocAddressMandatory
      },

      intDeactivatable () {
        return this.addressLocEnabled && !this.isIntAddressMandatory
      },

      locAddressEnabled () {
        return this.supportedContactAddress?.includes (CONTACT_ADDRESS_TYPES.LOC)
      },

      intAddressEnabled () {
        return this.supportedContactAddress?.includes (CONTACT_ADDRESS_TYPES.INT)
      },

      isLocAddressMandatory () {
        return this.mandatoryContactAddress?.includes (CONTACT_ADDRESS_TYPES.LOC)
      },

      isIntAddressMandatory () {
        return this.mandatoryContactAddress?.includes (CONTACT_ADDRESS_TYPES.INT)
      },

      // value for StepValidatorMixin
      stepToRefMap () {
        return {
          1: this.$refs.contactAddressInt,
          2: this.$refs.contactCommunicationData,
          3: this.showRegistrySpecificDataStep
            ? this.$refs.contactRegistrySpecificData
            : this.$refs.contactAdministrativeData,
          4: this.$refs.contactAdministrativeData
        }
      },

      updateOnlyContactFields () {
        return this.contactObject?.contact
          ? _pick (this.contactObject.contact, ['id', 'handle', 'versionId'])
          : {}
      },

      bothAddressesEnabled () {
        return this.intAddressEnabled && this.locAddressEnabled
      },

      communicationData: {
        get () {
          return {
            phoneNumber: this.contactObject.contact.phoneNumber,
            phoneNumberExt: this.contactObject.contact.phoneNumberExt,
            faxNumber: this.contactObject.contact.faxNumber,
            faxNumberExt: this.contactObject.contact.faxNumberExt,
            emailAddress: this.contactObject.contact.emailAddress
          }
        },
        set (newValue) {
          this.contactObject.contact.phoneNumber = newValue.phoneNumber
          this.contactObject.contact.phoneNumberExt = newValue.phoneNumberExt
          this.contactObject.contact.faxNumber = newValue.faxNumber
          this.contactObject.contact.faxNumberExt = newValue.faxNumberExt
          this.contactObject.contact.emailAddress = newValue.emailAddress
        }
      },

      administrativeDataStepNumber () {
        return this.showRegistrySpecificDataStep ? 4 : 3
      },

      administrativeData: {
        get () {
          return {
            contact: {
              handle: this.contactObject.contact.handle,
              authInfo: this.contactObject.contact.authInfo,
              policy: this.contactObject.contact.policy,
              maintainer: this.contactObject.contact.maintainer,
              clientId: this.contactObject.contact.clientId,
              registryId: this.contactObject.contact.registryId,
              providerChainType: this.contactObject.contact.providerChainType,
              providerChainSpec: this.contactObject.contact.providerChainSpec
            },
            editProviderChain: this.contactObject.editProviderChain,
            addOnFieldData: this.contactObject.addOnFieldData
          }
        },
        set (newValue) {
          this.contactObject.contact.handle = newValue.contact.handle
          this.contactObject.contact.authInfo = newValue.contact.authInfo
          this.contactObject.contact.policy = newValue.contact.policy
          this.contactObject.contact.maintainer = newValue.contact.maintainer

          this.contactObject.contact.providerChainType =
            newValue.contact.providerChainType

          this.contactObject.contact.providerChainSpec =
            newValue.contact.providerChainSpec

          this.contactObject.editProviderChain = newValue.editProviderChain
          this.contactObject.addOnFieldData = newValue.addOnFieldData
        }
      },

      addOnFields: {
        get () {
          return this.contactObject?.addOnFieldData
            ? this.contactObject.addOnFieldData
            : []
        },

        set (newValue) {
          if (Array.isArray (newValue)) {
            this.contactObject.contact.addOnFields =
              keyValueArrayToObject (newValue)
          }
        }
      },

      showStepper () {
        return !!(this.contactObject.contact.registryId &&
          this.contactObject.contact.clientId)
      }
    },

    watch: {
      'contactData.contact.handle' () {
        if (this.contactData?.contact?.handle &&
          this.contactData?.contact?.registryType) {
          this.populateContactObject (this.contactData)
        }
      },

      'contactData.contact.registryType' () {
        if (this.contactData?.contact?.handle &&
          this.contactData?.contact?.registryType) {
          this.populateContactObject (this.contactData)
        }
      },

      clientId (newId) {
        this.contactObject.contact.clientId = newId
      },

      registryId (newId) {
        this.contactObject.contact.registryId = newId
      },

      locAddressEnabled (newVal) {
        if (newVal) {
          this.contactObject.contact.addresses.loc =
            _cloneDeep (this.savedLocAddress)
        } else {
          this.savedLocAddress =
            _cloneDeep (this.contactObject.contact.addresses.loc)
          this.contactObject.contact.addresses.loc =
            getDefaultContactAddress ()
        }
      },

      intAddressEnabled (newVal) {
        if (newVal) {
          this.contactObject.contact.addresses.int =
            _cloneDeep (this.savedIntAddress)
        } else {
          this.savedIntAddress =
            _cloneDeep (this.contactObject.contact.addresses.int)
          this.contactObject.contact.addresses.int =
            getDefaultContactAddress ()
        }
      },

      updateOnlyContactFields (newValue) {
        if (this.isCreate && !_isEmpty (newValue)) {
          // remove existing object properties
          // (appearing after loading by clone)
          for (const key in newValue) {
            if (newValue.hasOwnProperty (key)) {
              delete this.contactObject.contact[key]
            }
          }
        }
      },

      contactObject: {
        handler () {
          if (this.isCreate) {
            this.storeData ({
              contact: this.contactObject.contact,
              editProviderChain: this.contactObject.editProviderChain,
              specificFieldData: this.contactObject.specificFieldData,
              addOnFieldData: this.contactObject.addOnFieldData
            })
          }

          if (this.defaultContact &&
            !_isEqual (this.contactObject.contact, this.defaultContact)) {
            this.$emit ('unsavedChanges', true)
          } else {
            this.$emit ('unsavedChanges', false)
          }
        },
        deep: true
      },

      'contactObject.contact.registryId' (newValue) {
        if (newValue) {
          this.loadRegistryData (newValue)
          this.orderProcessingResponse = null
        }
      },

      'contactObject.specificFieldData': {
        handler (newValue) {
          if (Array.isArray (newValue)) {
            this.contactObject.contact.specificFields =
              keyValueArrayToObject (newValue)

            this.showRegistrySpecificDataStep =
              !_isEmpty (this.contactObject.specificFieldData)
          }
        },
        deep: true
      },

      'contactObject.addOnFieldData': {
        handler (newValue) {
          if (Array.isArray (newValue)) {
            this.contactObject.contact.addOnFields =
              keyValueArrayToObject (newValue)
          }
        },
        deep: true
      },

      metaDataSupported (newValue) {
        this.incompleteRegistrySupport =
          this.contactObject.contact.registryId && !newValue
      }
    },

    created () {
      if (this.contactData?.contact?.handle &&
        this.contactData?.contact?.registryType) {
        this.populateContactObject (this.contactData)
      } else {
        this.contactObject = getDefaultCreateParams ()

        if (this.clientId) {
          this.contactObject.contact.clientId = Number (this.clientId)
        }

        if (this.registryId) {
          this.contactObject.contact.registryId = this.registryId
        }
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        showError: 'notification/setError',
        storeData: 'create/setContactCreateData'
      }),

      openAssosiatedDomain () {
        const routeData = this.$router.resolve (this.domainSearchLink)
        window.open (routeData.href, '_blank')
      },

      /**
       * Copy the data of one contact address object to another.
       *
       * @param {Object} from     the address object to copy from
       * @param {Object} to       the address object to copy to
       */
      copyAddress (from, to) {
        to.title = from.title
        to.name = from.name
        to.organization = from.organization
        to.street = from.street
        to.city = from.city
        to.postalCode = from.postalCode
        to.stateOrProvince = from.stateOrProvince
        to.countryCode = from.countryCode

        // Activate both versions
        this.addressLocEnabled = true
        this.addressIntEnabled = true
      },

      /**
       * Delete the data of a contact address object.
       *
       * @param {Object} address      the object to delete
       */
      deleteAddress (address) {
        address.name = null
        address.organization = null
        address.street = []
        address.city = null
        address.postalCode = null
        address.stateOrProvince = null
        address.countryCode = null
      },

      /**
       * Pre-select registry if only one registry is available for the current
       * user.
       */
      onRegistriesLoaded (registryTypes) {
        const typeKeys = Object.keys (registryTypes)

        if (typeKeys.length === 1) {
          this.contactObject.contact.registryId = typeKeys[0]
        }
      },

      /**
       * Store the registry ID after a registry has been selected.
       *
       * @param {String} registryId     the ID of the selected registry
       */
      onRegistrySelected (registryId) {
        this.contactObject.contact.registryId = registryId
      },

      /**
       * Load the specific data for the registry with the given ID, i.e. the
       * registry-specific fields and the address type information.
       *
       * @param {Number} registryId     the registry ID
       */
      loadRegistryData (registryId) {
        this.loadRegistrySpecificFields (registryId)
        this.loadAddressTypeData (registryId)
      },

      /**
       * Load default provider chain when a new client has been selected.
       */
      onClientSelected (clientId) {
        if (clientId !== this.contactObject.contact.clientId) {
          this.contactObject.contact.clientId = clientId
        }
      },

      // populate component's `contactObject`
      populateContactObject (dataObject) {
        this.contactObject = Object.assign (
          getDefaultCreateParams (), dataObject)

        const {handle, registryType} = this.contactObject.contact

        if (handle && registryType) {
          this.loadContact ({
            handle,
            registryType,
            includeRemote: true
          })
        }
      },

      // check address vartiants for presence and deactivate non present
      checkAddressVariants () {
        const check = (a) => (a.name === null &&
          a.organization === null &&
          (a.street?.lenght === 0 || a.street === null || a.street[0] === '') &&
          a.city === null &&
          a.postalCode === null &&
          a.stateOrProvince === null &&
          a.countryCode === null)

        this.addressLocEnabled = !check (this.contactObject.contact.addresses.loc)

        this.addressIntEnabled = !check (this.contactObject.contact.addresses.int)
      },

      /**
       * Load the contact with the given parameters.
       *
       * @param {Object} params     the contact load parameters
       */
      loadContact (params) {
        this.isLoading = true

        this.fetchData ({
          op: 'contact/load',
          params: {
            ...params,
            purpose: 'update'
          },
          cb: data => {
            this.savedLocAddress = data.contactData.addresses.loc
            this.savedIntAddress = data.contactData.addresses.int
            this.contactObject.contact = data.contactData
            this.checkAddressVariants ()
            this.populateAddOnFields ()

            if (!this.isCreate) {
              const states = data.viewData.states

              if (states.length && isUpdateProhibited (states)) {
                this.showError (this.$t ('update.prohibited'))
                this.$router.replace ({
                  name: 'contact.view',
                  params: {
                    registry: data.contactData.registryId,
                    handle: data.contactData.handle
                  }
                })
              }
            }
          },
          // cbError: () => {
          //   this.contact = null
          // },
          cbFinal: () => {
            this.isLoading = false

            // FIXME: addon field are not recognized as default fields on the domain
            //        therefore the changes on addon field are not recognized as unsaved changes
            this.defaultContact = _cloneDeep (this.contactObject.contact)
          }
        })
      },

      /**
       * Load the meta data of the Payload fields that are specific for the
       * registry with the given ID.
       *
       * @param {Integer} registryId      the registry ID
       */
      loadRegistrySpecificFields (registryId) {
        this.fetchData ({
          op: 'contact/loadSpecificFields',
          params: {
            registryId: registryId,
            operation: this.isCreate ? 'create' : 'update'
          },
          cb: data => {
            const specificFields = this.contactObject.contact.specificFields

            this.contactObject.specificFieldData = data.fieldData.map (
              function (d) {
                return {
                  key: d.key,
                  name: d.name,
                  value: specificFields[d.key] || '',
                  description: d.description,
                  example: d.example,
                  validationData: d.validationData,
                  multiValued: d.multiValued
                }
              })
          },
          cbError: () => {
            this.contactObject.specificFieldData = []
          }
        })
      },

      /**
       * Load the information whether the internationalized and/or the localized
       * address data fields may be specified by the contact creation.
       *
       * If the information cannot be obtained, it is assumed that both address
       * types may be specified.
       *
       * @param {Integer} registryId      the ID of the registry for which the
       *                                  contact shall be created
       */
      loadAddressTypeData (registryId) {
        if (this.locAddressEnabled && !this.intAddressEnabled) {
          if (!Object.keys (this.contactObject.contact.addresses.loc).find (
            key => (key !== 'street' &&
              !!this.contactObject.contact.addresses.loc[key]))) {
            this.copyAddress (
              this.contactObject.contact.addresses.int,
              this.contactObject.contact.addresses.loc)
          }
        }

        // this.fetchData ({
        //   op: 'contact/loadAddressTypeData',
        //   params: {
        //     registryId: registryId
        //   },
        //   cb: data => {
        //     this.locAddressEnabled = data.addressTypeData.locAllowed
        //     this.intAddressEnabled = data.addressTypeData.intAllowed

        //     if (this.locAddressEnabled && !this.intAddressEnabled) {
        //       if (!Object.keys (this.contactObject.contact.addresses.loc).find (
        //         key => (key !== 'street' &&
        //           !!this.contactObject.contact.addresses.loc[key]))) {
        //         this.copyAddress (
        //           this.contactObject.contact.addresses.int,
        //           this.contactObject.contact.addresses.loc)
        //       }
        //     }
        //   },
        //   cbError: () => console.warn (
        //     `address type data for registry ${registryId} could not be loaded`)
        // })
      },

      /**
       * Create a new contact from the entered contact data.
       *
       * Clear the contact create form in case of success, display the error
       * message(s) otherwise.
       */
      onSubmit () {
        this.$emit ('submit')

        if (this.isDataValid ()) {
          this.$emit ('dataValidityChanged', true)
          this.isLoading = true

          const contactData = {
            ..._cloneDeep (this.contactObject.contact),
            addresses: {
              loc: this.locAddressEnabled && this.addressLocEnabled
                ? _cloneDeep (this.contactObject.contact.addresses.loc)
                : getDefaultContactAddress (),
              int: this.intAddressEnabled && this.addressIntEnabled
                ? _cloneDeep (this.contactObject.contact.addresses.int)
                : getDefaultContactAddress ()
            }
          }

          if (!this.contactObject.editProviderChain) {
            delete contactData.providerChainSpec
            delete contactData.providerChainType
          }

          this.fetchData ({
            op: `contact/${this.isCreate ? 'create' : 'update'}`,
            params: {contactData},
            cb: data => {
              this.orderProcessingResponse = null
              const registryId = this.contactObject.contact.registryId

              this.$emit ('success', {
                handle: data.handle,
                registry: registryId
              })

              if (this.isCreate) {
                this.clearFormData ()
                this.step = 1
              }
              this.$emit ('unsavedChanges', false)
            },
            cbError: data => {
              if (data.errors) {
                this.orderProcessingResponse = data.errors
              } else {
                this.showError (errorTranslator (data))
              }

              this.$emit ('failure')
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        } else {
          this.$emit ('dataValidityChanged', false)
          this.touchData ()
        }
      },

      /**
       * Reset the contact create form to default values.
       */
      onReset () {
        this.clearFormData ()
        this.$emit ('dataValidityChanged', true)
        this.orderProcessingResponse = null
        this.storeData (this.contactObject)
      },

      /**
       * Clear the data entered into the form but keep selected client and
       * registry.
       */
      clearFormData () {
        const registryId = this.contactObject.contact.registryId
        const clientId = this.contactObject.contact.clientId
        this.contactObject.contact = getDefaultContactParams ()

        if (this.contactObject.specificFieldData) {
          this.contactObject.specificFieldData.forEach (
            function (f) {
              f.value = ''
            })
        }

        this.contactObject.addOnFieldData = getAddOnFieldData ()
        this.editProviderChain = DefaultEditProviderChain
        this.contactObject.contact.registryId = registryId
        this.contactObject.contact.clientId = clientId
        this.resetData ()
      },

      onCreateUpdateComponentLoaded () {
        if (this.isCreate) {
          this.contactObject.contact = this.contactCreateData.contact ||
            getDefaultContactParams ()

          this.contactObject.specificFieldData =
            this.contactCreateData.specificFieldData

          this.contactObject.addOnFieldData =
            this.contactCreateData.addOnFieldData

          this.contactObject.editProviderChain =
            this.contactCreateData.editProviderChain ||
            DefaultEditProviderChain
        }
      },

      populateAddOnFields () {
        this.contactObject.addOnFieldData = objectToKeyValueArray (
          this.contactObject.contact.addOnFields, k => removePrefix (k, '.'),
          v => v)
      },

      /**
       * Get the references of all sub-component.
       *
       * @return {Array}      the reference objects
       */
      getComponentRefs () {
        return [
          this.$refs.contactAddressInt,
          this.$refs.contactAddressLoc,
          this.$refs.contactCommunicationData,
          this.$refs.contactAdministrativeData,
          this.$refs.contactRegistrySpecificData
        ]
      },

      isAddressDataValid () {
        function isAddrValid (addr) {
          return !addr || !addr.hasErrors ()
        }

        const int = this.$refs.contactAddressInt
        const loc = this.$refs.contactAddressLoc

        return isAddrValid (int) && isAddrValid (loc)
      },

      isCommunicationDataValid () {
        return !this.$refs.contactCommunicationData ||
          !this.$refs.contactCommunicationData.hasErrors ()
      },

      isAdministrativeDataValid () {
        return !this.$refs.contactAdministrativeData ||
          !this.$refs.contactAdministrativeData.hasErrors ()
      },

      isSpecificDataValid () {
        return !this.$refs.contactRegistrySpecificData ||
          !this.$refs.contactRegistrySpecificData.hasErrors ()
      }
    }
  }
</script>

<style scoped>
  .red-label label {
    color: red !important;
    font-weight: bold
  }
</style>
