<messages>["../Domain"]</messages>

<template>
  <v-autocomplete
    ref="applicationStatus"
    v-model="model"
    multiple
    clearable
    :label="$t ('view.label.state')"
    :items="statusOptions"/>
</template>

<script>
  export default {
    name: 'ApplicationStatusFilter',

    props: {
      value: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      model: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      statusOptions () {
        return ['pending', 'accepted', 'rejected'].map (it => ({
          text: this.$t (`view.label.applicationState.${it}`),
          value: it.charAt (0).toUpperCase () + it.slice (1)
        })).sort ((a, b) => a.text.localeCompare (b.text))
      }
    },

    methods: {
      focus () {
        this.$refs.applicationStatus.focus ()
      }
    }
  }
</script>
