var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"height":"100%","width":"100%","append":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"10"}},[_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.role)+" ")]),_c('br'),_vm._v(" ("),_c('contact-link',_vm._b({attrs:{"history":_vm.history}},'contact-link',_vm.contact,false)),_vm._v(") ")],1)]),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" person ")])],1)],1),(!_vm.history)?_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[(_vm.contact.status === 'active')?_c('div',{staticClass:"contactData"},_vm._l(([
                _vm.contact.title,
                _vm.contact.name,
                _vm.contact.organization,
                _vm.contact.street,
                _vm.contact.postalCode,
                _vm.contact.city,
                _vm.contact.province,
                _vm.$t (_vm.contact.country),
                _vm.contact.email
              ].filter (function (it) { return !!it; })),function(line,idx){return _c('v-tooltip',{key:'cc-line-' + idx,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({staticClass:"contactLine",domProps:{"textContent":_vm._s(line)}},on))]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(line)}})])}),1):(_vm.contact.status === 'deleted')?void 0:void 0],2)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }