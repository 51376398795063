<messages>["../Domain"]</messages>

<template>
  <v-select
    ref="input"
    v-model="status"
    :items="options"
    :label="$t('view.label.locking.lockStatus')"/>
</template>

<script>
  import {
    LOCKED_STATE,
    LOCK_REQUESTED_STATE,
    UNLOCKED_STATE,
    UNLOCK_REQUESTED_STATE,
    getLockStatusLabel
  } from '../constants'

  export default {
    props: {
      value: {
        type: [Number, String],
        default: null
      }
    },

    computed: {
      status: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      options () {
        return [
          null,
          LOCKED_STATE,
          LOCK_REQUESTED_STATE,
          UNLOCKED_STATE,
          UNLOCK_REQUESTED_STATE
        ].map ((i) => ({
          text: this.$t (i !== null ? getLockStatusLabel (i) : 'general.label.any'),
          value: i
        }))
      }
    },
    methods: {
      focus () {
        this.$refs.input.focus ()
      }
    }
  }
</script>
