<messages>["./RegistryObjectCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout :mw="response ? '3' : '1'">
    <v-col
      v-if="loadingClients || isLoading"
      cols="12">
      <v-progress-linear
        indeterminate
        color="primary"/>
    </v-col>

    <v-col
      v-if="!mayManageOnlyOwnObjects && !loadingClients &&
        (!clientId && numberOfClients === 0) & !isLoading"
      cols="12">
      <v-alert type="error">
        {{ qualifiedMessage ('noClients') }}
      </v-alert>
    </v-col>

    <v-col
      v-show="mayManageOnlyOwnObjects || clientId || numberOfClients !== 0"
      :class="{ 'col-12': !response, 'col-8': response }">
      <v-card
        :class="{ 'elevation-0': !showCardBorder }"
        :color="color">
        <form @submit.prevent="$emit ('submit')">
          <v-card-title primary-title>
            <div
              v-if="isCreate"
              class="text-h5 mr-1"
              v-text="qualifiedMessage ('dataType')"/>
            <div v-else>
              <div>
                <span
                  class="text-h5"
                  v-text="name"/>
                <template v-if="description">
                  (<span
                    class="cgwng-subheading"
                    v-text="description"/>)
                </template>
              </div>
              <div class="cgwng-subheading">
                <registry-type-name :value="registryId" cut-tlds :max-tlds="5"/>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <!-- client and registry selection -->
            <template v-if="showClientAndRegistry">
              <v-row>
                <v-col
                  v-if="!mayManageOnlyOwnObjects"
                  v-show="hasSubClients"
                  cols="6">
                  <client-select
                    v-model="internalClientId"
                    :disabled="!isCreate"
                    @loading="onClientsLoading"
                    @loaded="onClientsLoaded"/>
                </v-col>
                <v-col
                  v-if="isCreate"
                  cols="6">
                  <registry-select
                    v-model="internalRegistryId"
                    spellcheck="false"
                    :label="$t ('registry')"
                    :disabled="registrySelectionDisabled"
                    @registryItemsLoaded="onRegistryItems"/>
                </v-col>
              </v-row>
              <template v-if="limitPrice && showButtons">
                <v-row class="cgwng-bg-color-2">
                  <v-col cols="6">
                    <v-text-field
                      v-model.trim="price"
                      :label="$t('label.price')"
                      :error-messages="validationErrors(
                        'price',
                        {
                          decimal: 'label.invalid.price'
                        })"
                      @blur="$v.price.$touch()">
                      <template #append>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-icon
                              color="primary"
                              dark
                              v-on="on">
                              help
                            </v-icon>
                          </template>
                          <span v-t="'label.priceTooltip'"/>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <currency-select :v="$v.currency"/>
                  </v-col>
                </v-row>
              </template>
              <template v-if="showPromocode && showButtons">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="internalPromoCode"
                      :label="$t('label.promotionCode')"
                      clearable/>
                  </v-col>
                </v-row>
              </template>
            </template>
            <v-row
              v-if="showRegistryWarning"
              v-show="showClientAndRegistry">
              <v-col cols="12">
                <v-alert
                  type="warning">
                  <i18n :path="`incompleteRegistrySupport.${type}`">
                    <template #link>
                      <router-link
                        :to="{name: 'order'}"
                        v-text="$t ('payloadOrderLinkText')"/>
                    </template>
                  </i18n>
                </v-alert>
              </v-col>
            </v-row>

            <slot name="content"/>
          </v-card-text>
          <v-card-actions>
            <template v-if="showButtons">
              <floating-button
                color="primary"
                :l-offset="2"
                :b-offset="2"
                :loading="isLoading"
                type="submit">
                {{
                  qualifiedMessage (`submit.${isCreate ? 'create' : 'update'}`)
                }}
              </floating-button>
              <v-spacer/>
              <v-btn
                v-if="isCreate"
                v-t="'general.button.reset'"
                text
                @click="$emit ('reset')"/>
            </template>
            <v-spacer v-else/>
            <slot name="actions"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>

    <v-col
      v-if="response"
      cols="4">
      <order-processing-response
        :response="response"
        :provide-select="provideResponseSelection"
        :select-type="type"
        :show-card-border="showCardBorder"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import OrderProcessingResponse from './OrderProcessingResponse'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import ClientSelect from '@/app/core/components/ClientSelect'
  import CurrencySelect from '@/app/core/components/CurrencySelect'
  import FloatingButton from '@/app/core/components/FloatingButton'
  import RegistrySelect from '@/app/core/components/RegistrySelect'

  import ContentHeightReporter from '@/app/core/mixins/ContentHeightReporter'

  import {requiredIf} from 'vuelidate/lib/validators'

  import {decimal} from '@/app/validators'

  import {mapActions, mapGetters, mapMutations} from 'vuex'
  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import RegistryTypeName from '@/app/core/components/RegistryTypeName'

  import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'

  /**
   * @typedef {Object} ValidationData
   * @property {String} pattern - the regular expression pattern string
   * @property {boolean} caseSensitive - flag whether the validation is case-sensitive
   * @property {boolean} required - flag whether the field is required
   */

  /**
   * @typedef {Object} FieldData
   * @property {String} key - the field key
   * @property {String} name - the field name; may be {@code null}
   * @property {String} description - the field description; may be {@code null}
   * @property {String} example - an example for valid field content; may be {@code null}
   * @property {ValidationData} validationData - the validation information for the field
   */

  /**
   * @typedef {Object} LaunchPhase
   * @property {Array.<FieldData>} eligibilityFields - the eligibility fields
   * @property {Boolean} claimsRequired - the flag whether this phase checks for claims
   * @property {Boolean} signedMarkRequired - the flag whether the phase requires SMD files
   */

  /**
   * @typedef {Object} RegistryMetaData
   * @property {Boolean} metaDataSupported - whether the retrieval of meta data from the registry is supported
   * @property {Array.<String>} baseNames - the operated base names
   * @property {Array.<String>} contactRoles - the registry-specific contact roles
   * @property {Boolean} icannRegulated - whether the operated TLD is ICANN regulated
   * @property {Boolean} variantsAllowed - whether the operated TLD allows for domain variants (as attributes)
   * @property {Boolean} thinRegistry - whether the registry is "thin"
   * @property {String} idnRequirement - whether IDNs are allowed/required/disallowed
   * @property {String} idnLangRequirement - whether IDN language/script tags are allowed/required/disallowed
   * @property {Array.<String>} idnLanguages - the list of available IDN languages/scripts
   * @property {String} dsRequirement - whether DS data are allowed/required/disallowed
   * @property {String} dnskeyRequirement - whether DNSKEY data are allowed/required/disallowed
   * @property {Array.<LaunchPhase>} launchPhases - the registry specific launch phases
   * @property {Boolean} privacySettingsAllowed - whether registrant data privacy settings are supported
   * @property {Boolean} allocationTokenAllowed - whether the submission of allocation tokens is supported
   */

  export default {
    name: 'RegistryObjectCreateUpdate',

    components: {
      RegistryTypeName,
      BaseLayout,
      ClientSelect,
      FloatingButton,
      OrderProcessingResponse,
      RegistrySelect,
      CurrencySelect
    },

    mixins: [ContentHeightReporter, validationMixins],

    props: {
      type: {
        type: String,
        required: true,
        validator (v) {
          return [
            'domain', 'contact', 'host', 'zone', 'domainTransfer'
          ].includes (v)
        }
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      // object identifier, domain name for domain and host, handle for contact
      name: {
        type: String,
        default: undefined
      },
      registryId: {
        type: String,
        default: undefined
      },
      clientId: {
        type: Number,
        default: undefined
      },
      showClientAndRegistry: {
        type: Boolean,
        default: true
      },
      showButtons: {
        type: Boolean,
        default: false
      },
      showRegistryWarning: {
        type: Boolean,
        default: false
      },
      showCardBorder: {
        type: Boolean,
        default: true
      },
      response: {
        type: Array,
        default: undefined
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'white'
      },
      limitPrice: {
        type: Boolean,
        default: false
      },
      promoCode: {
        type: String,
        default: undefined
      },
      showPromocode: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        internalClientId: 0,
        availableClients: [],
        internalRegistryId: '',
        loadingClients: false,
        numberOfClients: 0,
        registrySelectionDisabled: false,
        registryTypeName: '',
        price: '',
        currency: ''
      }
    },

    validations: {
      currency: {
        required: requiredIf (function () {
          return this.price
        })
      },
      price: {
        decimal
      }
    },

    computed: {
      ...mapGetters ({
        actingClientId: 'auth/actingClientId',
        hasAnyOfPermissions: 'auth/hasAnyOfPermissions',
        hasNoneOfPermissions: 'auth/hasNoneOfPermissions',
        hasSubClients: 'auth/hasSubClients'
      }),

      description () {
        let description

        if (this.type === 'domain' && this.name) {
          const punycodeName = punycodeToASCII (this.name)

          if (this.name !== punycodeName) {
            description = punycodeName
          }
        }

        return description
      },

      internalPromoCode: {
        get () {
          return this.promoCode
        },
        set (newVal) {
          this.$emit ('update:promoCode', newVal)
        }
      },

      clientName () {
        let clientName = ''

        if (this.clientId) {
          clientName = `CORE-${this.clientId}`

          if (this.availableClients?.length) {
            const client = this.availableClients.find (c =>
              c.value === this.clientId)

            if (client) {
              clientName = client.text
            }
          }
        }

        return clientName
      },

      mayManageOwnObjects () {
        return this.hasAnyOfPermissions (
          ['ManageOwnObjects', 'ManageAllObjects'])
      },

      mayManageOnlyOwnObjects () {
        return this.mayManageOwnObjects && this.hasNoneOfPermissions (
          ['ManageAllObjects', 'ManageSubClientObjects'])
      },

      provideResponseSelection () {
        return this.type === 'domain'
      }
    },

    watch: {
      registryId (newValue) {
        if (newValue !== null) {
          this.onRegistryIdChanged (newValue)
        }
      },

      clientId (newValue) {
        this.internalClientId = newValue || this.preSelectOwnClient ()
      },

      internalRegistryId () {
        this.$emit ('registrySelected', this.internalRegistryId)
      },

      internalClientId (newValue) {
        this.$emit ('clientSelected', newValue)
      },

      price () {
        this.priceModelChanged ()
      },
      currency () {
        this.priceModelChanged ()
      }
    },

    created () {
      this.internalClientId = this.isCreate
        ? this.clientId || this.actingClientId
        : this.clientId

      if (this.registryId) {
        this.onRegistryIdChanged (this.registryId)
      }

      this.$emit ('clientSelected', this.internalClientId)

      if (this.mayManageOnlyOwnObjects) {
        this.$emit ('loaded', 1)
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData',
        getRegistryTypeName: 'cache/getRegistryTypeName'
      }),

      ...mapMutations ('meta', {
        resetRegistryMetaData: 'resetRegistryMetaData',
        setIcannRegulated: 'setIcannRegulated',
        setLaunchPhases: 'setLaunchPhases',
        setLaunchPhaseRequired: 'setLaunchPhaseRequired',
        setContactRoles: 'setContactRoles',
        setIdn: 'setIdnRequirementSpec',
        setIdnLang: 'setIdnLangRequirementSpec',
        setIdnLanguages: 'setIdnLanguages',
        setDefaultIdnTag: 'setDefaultIdnTag',
        setDnskeyData: 'setDnskeyDataRequirementSpec',
        setDsData: 'setDsDataRequirementSpec',
        setBaseNames: 'setBaseNames',
        setVariants: 'setVariantsAllowed',
        setThin: 'setThinRegistry',
        setRegistrantPrivacyAllowed: 'setRegistrantPrivacyAllowed',
        setAllocationTokenAllowed: 'setAllocationTokenAllowed',
        setSupported: 'setMetaDataSupported',
        setAuthInfoSettable: 'setAuthInfoSettable',
        setContactAuthInfoSupported: 'setContactAuthInfoSupported',
        setTransferRequiresAuthInfo: 'setTransferRequiresAuthInfo',
        setRequiredContactFields: 'setRequiredContactFields',
        setOptionalContactFields: 'setOptionalContactFields',
        setProhibitedContactFields: 'setProhibitedContactFields',
        setPromotionCodeSupported: 'setPromotionCodeSupported',
        setTransferMode: 'setTransferMode',
        setContactSupport: 'setContactSupport',
        setHostSupport: 'setHostSupport',
        setIdnLangRequirement: 'setIdnLangRequirement',
        setMandatoryContactAddress: 'setMandatoryContactAddress',
        setSupportedContactAddress: 'setSupportedContactAddress'
      }),

      onRegistryIdChanged (newValue) {
        this.loadMetaData (newValue)
        this.populateRegistryTypeName (newValue)
        this.internalRegistryId = newValue
      },

      resetLimitPrice () {
        this.price = null
        this.currency = null
        this.$v.$reset ()
      },

      priceModelChanged () {
        if (this.$v.$invalid) {
          this.$v.$touch ()
          this.$emit ('limitPriceChanged', {
            value: null,
            currency: null
          })
        } else {
          const price = Number.parseFloat (this.price)
          const isPriceInvalid = Number.isNaN (price)

          this.$emit (
            'limitPriceChanged',
            {
              value: isPriceInvalid ? null : price,
              currency: isPriceInvalid ? null : this.currency
            }
          )
        }
      },

      async populateRegistryTypeName (registryType) {
        this.registryTypeName = await this.getRegistryTypeName ({id: registryType})
      },

      /**
       * Pre-select the client of the currently acting user (if any).
       */
      preSelectOwnClient () {
        if (!this.internalClientId && this.actingClientId &&
          this.mayManageOwnObjects) {
          this.$emit ('clientSelected', this.actingClientId)
          return this.actingClientId
        }
      },

      /**
       * Pre-select registry if only one registry is available for the current
       * user.
       */
      onRegistryItems (registryItems) {
        if (registryItems.length === 1) {
          this.internalRegistryId = registryItems[0].value
          this.registrySelectionDisabled = true
        }
      },

      /**
       * Show progress bar when clients are being loaded.
       */
      onClientsLoading () {
        this.loadingClients = true
      },

      /**
       * Stop showing progress bar when clients have been loaded and emit a
       * 'loaded' event
       */
      onClientsLoaded (clients) {
        this.loadingClients = false
        this.numberOfClients = clients ? clients.length : 0
        this.availableClients = clients
        this.$emit ('loaded')
      },

      /**
       * Get the message for the given key in combination with the registry
       * object type for which this component is used.
       *
       * @param {String} baseKey      the message key
       * @return {String}             the message for the given key in
       *                              combination with the object type
       */
      qualifiedMessage (baseKey) {
        return this.$t (`${baseKey}.${this.type}`)
      },

      /**
       * Load the registry-specific meta data for the registry with the given
       * ID.
       *
       * @param {String} registryId     the registry ID
       */
      loadMetaData (registryId) {
        if (!registryId) return
        // initially assume that registry is supported to avoid premature alert
        this.setSupported (true)

        this.fetchData ({
          op: 'registry/loadMetaData',
          params: {
            registryId: registryId
          },
          /**
           * @param {RegistryMetaData} data
           */
          cb: data => {
            if (data.metaDataSupported) {
              const rmd = data.registryMetaData
              const roles = rmd.contactRoles?.length
                ? rmd.contactRoles
                : ['admin', 'billing', 'tech', 'registrant']

              this.setContactRoles (roles)

              this.setIcannRegulated (rmd.icannRegulated)
              this.setLaunchPhases (rmd.launchPhases)
              this.setLaunchPhaseRequired (rmd.phaseRequired)
              this.setIdn (rmd.idnRequirement)
              this.setIdnLang (rmd.idnRequirement)
              this.setIdnLanguages (rmd.idnLanguages)
              this.setDefaultIdnTag (rmd.defaultIdnTag)
              this.setDnskeyData (rmd.dnskeyRequirement)
              this.setDsData (rmd.dsRequirement)
              this.setBaseNames (rmd.baseNames)
              this.setVariants (rmd.variantsAllowed)
              this.setThin (rmd.thinRegistry)
              this.setRegistrantPrivacyAllowed (rmd.privacySettingsAllowed)
              this.setAllocationTokenAllowed (rmd.allocationTokenAllowed)
              this.setAuthInfoSettable (rmd.authInfoSettable)
              this.setContactAuthInfoSupported (rmd.contactAuthInfoSupported)
              this.setTransferRequiresAuthInfo (rmd.transferRequiresAuthInfo)
              this.setSupported (data.metaDataSupported)
              this.setRequiredContactFields (rmd.requiredContactFields)
              this.setOptionalContactFields (rmd.optionalContactFields)
              this.setProhibitedContactFields (rmd.prohibitedContactFields)
              this.setPromotionCodeSupported (rmd.supportsPromotionCode)
              this.setTransferMode (rmd.transferMode)
              this.setContactSupport (rmd.contactSupport)
              this.setHostSupport (rmd.hostSupport)
              this.setIdnLangRequirement (rmd.idnLangRequirement)
              this.setSupportedContactAddress (rmd.supportedContactAddress)
              this.setMandatoryContactAddress (rmd.mandatoryContactAddress)
            } else {
              this.resetRegistryMetaData ()
            }
          }
        })
      }
    }
  }
</script>
