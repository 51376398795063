<messages>["./Input"]</messages>

<template>
  <multi-tag-input
    ref="input"
    v-model="ips"
    :label="labelStr"
    :hint="hint"
    :error-map="{ip: 'general.invalid.ip'}"
    :validation-prop="validationProp"
    :additional-error-messages="errorMessages"
    @blur="blur"
    @reset="reset"/>
</template>

<script>
  import _cloneDeep from 'lodash/cloneDeep'

  import {ip} from '@/app/validators'
  import MultiTagInput from './MultiTagInput'

  export default {
    name: 'MultiIpInput',

    components: {
      MultiTagInput
    },

    props: {
      value: {
        type: Array,
        default: () => []
      },
      label: {
        type: String,
        default: undefined
      },
      hint: {
        type: String,
        default: undefined
      },
      errorMessages: {
        type: Array,
        default: () => []
      }
    },

    data () {
      return {
        ips: []
      }
    },

    computed: {
      labelStr () {
        return this.label || this.$t ('label.ips')
      },

      validationProp () {
        return {ip}
      }
    },

    watch: {
      ips (newValue) {
        this.$emit ('input', newValue)
      },

      value (newValue) {
        this.ips = newValue
      }
    },

    created () {
      this.ips = _cloneDeep (this.value)
    },

    methods: {
      blur () {
        this.$emit ('blur')
      },
      reset () {
        this.$emit ('reset')
      },
      focus () {
        this.$refs.input.focus ()
      },
      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$refs.input.hasErrors ()
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return this.$refs.input.isValid ()
      },

      /**
       * Validate the given field.
       *
       * @param {Object} field      the meta data object identifying the field
       *                            to be validated
       */
      doValidate () {
        return this.$refs.input.doValidate ()
      }
    }
  }
</script>
