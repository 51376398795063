<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <div>
    <!-- role and handle selection -->
    <v-tooltip
      top
      :disabled="!isRoleItemsEmpty">
      <span v-t="'create.label.contact.controlsDisabled'"/>
      <template #activator="{ on }">
        <v-row class="cgwng-bg-color-1" v-on="on">
          <v-col cols="12" sm="5">
            <v-autocomplete
              v-model="selectedRole"
              :search-input.sync="roleSearch"
              class="required"
              :disabled="isRoleItemsEmpty"
              :label="$t ('create.label.contact.role')"
              :items="roleItems"/>
          </v-col>
          <v-col cols="12" sm="7" class="py-0">
            <v-row>
              <v-col class="notTooSmall">
                <v-text-field
                  v-if="showContactHandleField"
                  v-model.trim="handle"
                  append-icon="clear"
                  readonly
                  class="required"
                  :label="$t ('create.label.contact.handle')"
                  @click:append="onClearHandle"/>
                <contact-handle-select
                  v-else
                  v-model="handle"
                  required
                  :disabled="isRoleItemsEmpty || showInlineContactCreation"
                  :registry-id="value.domain.registryId"
                  :label="$t ('create.label.contact.handle')"
                  :hint="$t ('create.label.contact.hint')"/>
              </v-col>
              <v-col class="shrink" align-self="center">
                <v-btn
                  v-t="'create.label.contact.add'"
                  small
                  :disabled="!dataComplete"
                  v-on="on"
                  @click="onAdd (handle, selectedRole)"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="cgwng-bg-color-1" v-on="on">
          <v-col class="offset-sm-5 py-0" cols="12" sm="5">
            <div
              v-t="'general.label.or'"
              class="text-subtitle-1"/>
            <v-btn
              v-t="'create.label.contact.inline'"
              color="lightgray"
              class="elevation-0 ma-0"
              :disabled="isRoleItemsEmpty"
              @click="showInlineContactCreation = true"/>
          </v-col>
        </v-row>
      </template>
    </v-tooltip>

    <!-- inline contact creation -->
    <v-row class="cgwng-bg-color-1">
      <base-dialog
        v-model="showInlineContactCreation"
        mw1
        scrollable
        persistent
        close-on-esc>
        <v-card class="elevation-0">
          <v-card-text>
            <contact-create-update
              ref="inlineContactCreation"
              inline
              :is-create="true"
              :contact-data="contactCloningData"
              :registry-id="value.domain.registryId"
              :client-id="value.domain.clientId.toString ()"
              @submit="onContactCreate"
              @success="onContactCreated"
              @failure="isCreatingContact = false"
              @dataValidityChanged="isCreatingContact = false"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.cancel'"
              text
              @click.native="onContactCreateCancelled"/>
            <v-btn
              v-t="'create.label.contact.inlineCreate'"
              color="primary"
              :loading="isCreatingContact"
              @click.native="$refs.inlineContactCreation.onSubmit"/>
          </v-card-actions>
        </v-card>
      </base-dialog>
      <v-col cols="12">
        <v-alert
          type="success"
          dismissible
          :value="showInlineContactCreated"
          @click="showInlineContactCreated = false">
          {{
            $t ('create.label.contact.inlineSuccess',
                { handle: createdContactHandle })
          }}
        </v-alert>
      </v-col>
    </v-row>

    <!-- contact cards -->
    <v-row class="cgwng-bg-color-1">
      <v-col
        v-for="(contact, cIdx) in contacts"
        :key="cIdx"
        class="d-flex"
        cols="12" md="6">
        <v-row>
          <!-- contact card buttons -->
          <v-col class="shrink pb-0" cols="12">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  @click="onDelete (cIdx)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span v-t="'create.label.contact.delete'"/>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on: tooltip }">
                <v-menu
                  offset-y
                  :disabled="isRoleItemsEmpty">
                  <template #activator="{ on: menu }">
                    <v-btn
                      small
                      icon
                      :disabled="isRoleItemsEmpty"
                      v-on="{ ...tooltip, ...menu }">
                      <v-icon>cached</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="role in roleItems"
                      :key="role.value"
                      @click="onChangeRole (contact, role.value)">
                      <v-list-item-title>{{ role.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <span v-t="'create.label.contact.changeRole'"/>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on: tooltip }">
                <v-menu
                  offset-y
                  :disabled="isRoleItemsEmpty">
                  <template #activator="{ on: menu }">
                    <v-btn
                      small
                      icon
                      :disabled="isRoleItemsEmpty"
                      v-on="{ ...tooltip, ...menu }">
                      <v-icon>content_copy</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="role in roleItems"
                      :key="role.value"
                      @click="onCopy (contact, role.value)">
                      <v-list-item-title>{{ role.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <span v-t="'create.label.contact.copy'"/>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  small
                  icon
                  :disabled="allRolesUseContact (contact)"
                  v-on="on"
                  @click="onUseForAllRoles (contact)">
                  <v-icon>group</v-icon>
                </v-btn>
              </template>
              <span v-t="'create.label.contact.useForAll'"/>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  @click="startClone(contact)">
                  <v-icon>person_add</v-icon>
                </v-btn>
              </template>
              <span v-t="'create.label.contact.clone'"/>
            </v-tooltip>
          </v-col>

          <!-- contact cards -->
          <v-col class="grow pt-0" cols="12">
            <contact-card :contact="contact"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- registrant data privacy settings -->
    <v-row
      v-if="showPrivacySettings"
      class="cgwng-bg-color-2">
      <v-col cols="12" sm="6">
        <radio-group
          v-model="registrantType"
          class="pt-4"
          :option-values="['natural', 'legal']"
          :default-value="'natural'"
          :option-labels="[$t ('create.label.contact.naturalRegistrant'), $t ('create.label.contact.legalRegistrant')]"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-switch
          v-model="publishRegistrantData"
          class="pt-4"
          :label="$t ('create.label.contact.publishRegistrantData')"
          :disabled="registrantType === 'legal'"/>
      </v-col>
    </v-row>

    <!-- WAP verification override switch -->
    <v-row v-if="provideWapOverride && !noWap">
      <v-col cols="12">
        <v-switch
          v-model="wapOverride"
          color="red"
          class="mt-6"
          :class="{ 'red-label': wapOverride }"
          :label="$t ('create.label.contact.wapOverride')"
          hide-details/>
      </v-col>
      <v-col cols="12">
        <div class="grey--text text--darken-1 text-body-1">
          <p
            v-for="p in [1, 2, 3, 4]"
            :key="p"
            v-html="$t (`create.wapOverride.${p}`)"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapGetters} from 'vuex'

  import {createContactDataObject} from '@/app/pages/Contact/constants'

  import BaseDialog from '@/app/core/components/BaseDialog'

  import ContactCard from './components/ContactCard'
  import ContactHandleSelect from '@/app/core/components/ContactHandleSelect'

  import ContactCreateUpdate from '@/app/pages/Contact/ContactCreateUpdate'
  import RadioGroup from '@/app/core/components/RadioGroup'

  export default {
    name: 'DomainContactData',

    components: {
      BaseDialog,
      ContactCard,
      ContactCreateUpdate,
      ContactHandleSelect,
      RadioGroup
    },

    props: {
      value: {
        type: Object,
        required: true,
        validator (v) {
          return v.hasOwnProperty ('domain') &&
            v.domain.hasOwnProperty ('clientId') &&
            v.domain.hasOwnProperty ('registryId') &&
            v.domain.hasOwnProperty ('contacts')
        }
      },
      extraData: {
        type: null,
        default: undefined
      },
      noWap: {
        type: Boolean,
        default: false
      },
      resetInlineSuccessMessage: Boolean
    },

    data () {
      return {
        wapOverride: false,
        selectedRole: '',
        handle: '',
        loadingContacts: false,
        contacts: [],
        showInlineContactCreation: false,
        showInlineContactCreated: false,
        showContactHandleField: false,
        isCreatingContact: false,
        createdContactHandle: '',
        registrantType: null,
        publishRegistrantData: false,
        roleSearch: '',
        contactCloningData: null
      }
    },

    computed: {
      ...mapGetters ('meta', {
        contactRoles: 'getContactRoles',
        icannRegulated: 'isIcannRegulated',
        showPrivacySettings: 'isRegistrantPrivacyAllowed'
      }),

      dataComplete () {
        return this.handle && this.handle !== '' && this.selectedRole &&
          this.selectedRole !== ''
      },

      roleItems () {
        return this.contactRoles
          ? this.contactRoles.filter (r => !this.doesRoleExist (r)).map (r => ({
            text: this.$te (`create.contactRole.${r}`)
              ? this.$t (`create.contactRole.${r}`)
              : r,
            value: r
          }))
          : []
      },

      isRoleItemsEmpty () {
        return !this.roleItems.length
      },

      provideWapOverride () {
        return this.icannRegulated && this.contacts.some (
          c => c.role === 'registrant' && !c.wapVerified)
      }
    },

    watch: {
      'value.domain.registryId' () {
        this.value.domain.contacts = []
        this.wapOverride = null
      },

      'value.domain.wapOverride' (newValue) {
        this.wapOverride = newValue
      },

      'value.domain.registrantType' (newValue) {
        this.registrantType = newValue
      },

      'value.domain.publishRegistrantData' (newValue) {
        this.publishRegistrantData = newValue
      },

      'value.domain.contacts': {
        handler () {
          this.deleteRemovedContacts ()
          this.loadNewContactData ()
        },
        deep: true
      },

      wapOverride (newValue) {
        this.value.domain.wapOverride = newValue
        this.updateData ()
      },

      registrantType (newValue) {
        if (newValue === 'legal') {
          this.publishRegistrantData = true
        }

        this.value.domain.registrantType = newValue
        this.updateData ()
      },

      publishRegistrantData (newValue) {
        this.value.domain.publishRegistrantData = newValue
        this.updateData ()
      },

      value: {
        handler () {
          this.updateData ()
        },
        deep: true
      },

      isRoleItemsEmpty (newValue) {
        if (newValue) this.showInlineContactCreation = false
      },

      resetInlineSuccessMessage (newValue) {
        if (newValue) this.showInlineContactCreated = false
      }
    },

    created () {
      this.loadNewContactData ()
      this.wapOverride = this.value.domain.wapOverride
      this.registrantType = this.value.domain.registrantType

      if (this.showPrivacySettings &&
        this.value.domain.publishRegistrantData === null) {
        this.value.domain.publishRegistrantData = false
      }

      this.publishRegistrantData = this.value.domain.publishRegistrantData
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return false
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return true
      },

      /**
       * Emit an input event to be consumed by the parent component in case of a
       * changed value property.
       */
      updateData () {
        this.$emit ('input', this.value)
      },

      /**
       * Check whether a contact with the given role does already exist.
       *
       * @param {String} role       the role
       * @return {Boolean}          true if the specified role exists
       */
      doesRoleExist (role) {
        return this.contacts.some (function (c) {
          return c.role === role
        })
      },

      /**
       * Add a contact for the specificed role.
       *
       * @param {String} handle     the contact handle
       * @param {String} role       the role
       */
      onAdd (handle, role) {
        this.value.domain.contacts.push ({handle, role})
        this.selectedRole = null
        this.roleSearch = ''
        this.showContactHandleField = false
      },

      /**
       * Copy a contact for the specificed role.
       *
       * @param {String} contact    the contact to copy
       * @param {String} role       the role
       */
      onCopy (contact, role) {
        this.value.domain.contacts.push ({handle: contact.value, role})
      },

      /**
       * Change the role for the given contact.
       *
       * @param {Object} contact    the contact
       * @param {String} role       the new role
       */
      onChangeRole (contact, role) {
        this.value.domain.contacts.forEach (e => {
          if (e.role === contact.role && e.handle === contact.value) {
            e.role = role
          }
        })

        contact.role = role
      },

      /**
       * Use the given contact for all roles.
       *
       * @param {Object} contact    the contact
       */
      onUseForAllRoles (contact) {
        this.value.domain.contacts.forEach (c => {
          if (!c.handle) { c.handle = contact.value }
        })

        this.roleItems.forEach (i => {
          this.value.domain.contacts.push ({
            handle: contact.value,
            role: i.value
          })
        })
      },

      /**
       * Check whether all roles supported by the registry have assigned the
       * same contact (which is given as a parameter).
       *
       * @param {Object} contact      the contact to check the condition for
       * @return {Boolean}            true if all roles supported by the
       *                              registry have assigned the given contact;
       *                              false otherwise
       */
      allRolesUseContact (contact) {
        return this.roleItems.length === 0 &&
          this.value.domain.contacts.every (c => c.handle === contact.value)
      },

      /**
       * Delete the contact with the given index
       *
       * @param {Integer} idx     the contact index
       */
      onDelete (idx) {
        const toDelete = this.contacts[idx]

        const index = this.value.domain.contacts.findIndex (function (e) {
          return e.handle === toDelete.value && e.role === toDelete.role
        })

        if (index !== -1) {
          this.value.domain.contacts =
            this.value.domain.contacts.splice (index, 1)
        }
      },

      /**
       * Set internal state when a new inline contact creation is triggered.
       */
      onContactCreate () {
        this.isCreatingContact = true
        this.showInlineContactCreated = false
      },

      /**
       * When a new contact has been generated inline, show success message and
       * populate contact handle field with newly generated contact's handle.
       *
       * @param {Object} contactData      the data of the created contact
       */
      onContactCreated (contactData) {
        this.contactCloningData = null
        this.isCreatingContact = false
        this.handle = contactData.handle
        this.createdContactHandle = contactData.handle
        this.showInlineContactCreation = false
        this.showInlineContactCreated = true
        this.showContactHandleField = true
      },

      startClone (c) {
        this.contactCloningData = {
          contact: {
            handle: c.link.params.handle,
            registryType: this.value.domain.registryId
          }
        }

        this.showInlineContactCreation = true
      },

      /**
       * Reset data and hide dialog when inline contact creation is cancelled.
       */
      onContactCreateCancelled () {
        this.$refs.inlineContactCreation.onReset ()
        this.contactCloningData = this.contactCloningData = {
          contact: {
            handle: null,
            registryType: this.value.domain.registryId
          }
        }
        this.showInlineContactCreation = false
      },

      /**
       * Load a contact or populate it from the `extraData`.
       *
       * @param {String} handle           the contact handle
       * @param {String} registryId       the registry ID
       * @param {String} role             the role for which the contact shall
       *                                  be populated
       */
      populateContact (handle, registryId, role) {
        const pushContact = (contactData, wapVerifiedEMail) => {
          this.contacts.push (
            createContactDataObject (contactData, role, wapVerifiedEMail))
        }

        const contactData = this.extraData[handle]

        if (contactData) {
          pushContact (contactData, contactData.wapVerifiedEMail)
        } else {
          this.fetchData ({
            op: 'contact/load',
            params: {
              handle: handle,
              registryType: registryId,
              purpose: 'view'
            },
            cb: data => {
              pushContact (data.contactData, data.wapVerifiedEMail)
            },
            cbError: () => {
              // create placeholder card when contact is not availble locally
              this.contacts.push ({
                value: handle,
                role,
                status: 'active',
                wapVerified: false
              })
            },
            cbFinal: () => {
              this.handle = ''
              this.selectedRole = ''
            }
          })
        }
      },

      /**
       * Delete all internal contact data objects that are no longer represented
       * in the component model.
       */
      deleteRemovedContacts () {
        const newContacts = []

        for (const idx in this.contacts) {
          const contact = this.contacts[idx]

          const contactExists = this.value.domain.contacts.some (function (c) {
            return c.role === contact.role && c.handle === contact.value
          })

          if (contactExists) {
            newContacts.push (contact)
          }
        }

        this.contacts = newContacts
      },

      /**
       * Load internal contact data objects for all contacts in the component
       * model for which no internal representation has been loaded yet.
       */
      loadNewContactData () {
        for (const c in this.value.domain.contacts) {
          if (this.value.domain.contacts.hasOwnProperty (c)) {
            const contact = this.value.domain.contacts[c]

            const contactAlreadyExists = this.contacts.some (c => {
              return contact.role === c.role && contact.handle === c.value
            })

            if (contactAlreadyExists) {
              continue
            }

            this.populateContact (
              contact.handle, this.value.domain.registryId, contact.role)
          }
        }
      },

      /**
       * Hide the contact handle text field (and show the select component) when
       * clearing the handle.
       */
      onClearHandle () {
        this.handle = ''
        this.showContactHandleField = false
      }
    }
  }
</script>

<!--
================================================================================
  Styling
================================================================================
-->

<style scoped>
.notTooSmall {
  min-width: 66.666%;
}

.red-label label {
  color: red !important;
  font-weight: bold
}
</style>
