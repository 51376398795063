export default {
  methods: {
    /**
     * Get the sub-component references; must be overwritten in components using
     * this mixin.
     *
     * @return {Array}      the sub-component references
     */
    getComponentRefs () {
      return []
    },

    /**
     * Whether the form data of the sub-components is valid.
     *
     * @return {Boolean}      true if so
     */
    isDataValid () {
      return this.getComponentRefs ().reduce (function (prev, curr) {
        return prev && (!curr || curr.isValid ())
      }, true)
    },

    /**
     * Touch the form data of the sub-components, i.e., flag the validated
     * fields as "dirty".
     */
    touchData () {
      this.getComponentRefs ().forEach (function (r) {
        if (r?.$v) r.$v.$touch ()
      })
    },

    /**
     * Reset the form data validation of the sub-components, i.e., remove the
     * "dirty" flag from the validated fields.
     */
    resetData () {
      this.getComponentRefs ().forEach (function (r) {
        if (r?.$v) r.$v.$reset ()
      })
    }
  }
}
