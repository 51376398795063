var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.link && _vm.exists && !_vm.history)?_c('router-link',{staticClass:"nobr",attrs:{"to":_vm.link}},[_c('span',{class:{deletedItemLink: _vm.status === 'deleted'},domProps:{"textContent":_vm._s(_vm.value)}})]):(_vm.history)?_c('router-link',{staticClass:"nobr",attrs:{"to":{
      name: 'contact.search',
      query: {
        filter: JSON.stringify ({
          handle: _vm.value || _vm.handle,
          registryTypes: [_vm.registry],
          contactState: 'any'
        })
      }
    }}},[_c('span',{class:{deletedItemLink: _vm.status === 'deleted'},domProps:{"textContent":_vm._s(_vm.handle)}})]):(_vm.status === 'foreign')?_c('span',{staticClass:"nobr",domProps:{"textContent":_vm._s(("" + _vm.handle))}}):_c('span',{staticClass:"nobr",domProps:{"textContent":_vm._s(("" + (_vm.value || _vm.handle)))}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }