/**
 * string constants for DNSKEY flags, algorithms and protocols
 *
 * @type {Object}
 */
export const DnssecConstants = {
  SecureEntryPointFlag: 'SEP',
  RevokeFlag: 'REVOKE',
  ZoneFlag: 'ZONE',
  OtherFlags: 'other',
  InvalidFlags: 'INVALID',
  DnssecProtocol: 'DNSSEC',
  UnknownProtocol: 'UNKNOWN',
  ReservedAlgorithm: 'RESERVED',
  UnassignedAlgorithm: 'UNASSIGNED',
  InvalidAlgorithm: 'INVALID'
}

const DnskeyFlagsLSB = 15
const DnskeyFlagsSepBit = 15
const DnskeyFlagsRevokeBit = 8
const DnskeyFlagsZoneBit = 7
const DnskeyFlagsReservedBits = [0, 1, 2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 14]

const DnskeyFlagsReservedMask = DnskeyFlagsReservedBits.reduce (
  (mask, bit) => mask + (1 << (DnskeyFlagsLSB - bit)), 0)

/**
 * Determine the string representation of the given DNSSEC flags value.
 *
 * @param {Number} flags      the flags
 * @return {String}           the string representation of the flags
 */
export function keyFlagsStr (flags) {
  const flagStrings = []

  if (flags & (1 << (DnskeyFlagsLSB - DnskeyFlagsSepBit))) {
    flagStrings.push (DnssecConstants.SecureEntryPointFlag)
  }

  if (flags & (1 << (DnskeyFlagsLSB - DnskeyFlagsRevokeBit))) {
    flagStrings.push (DnssecConstants.RevokeFlag)
  }

  if (flags & (1 << (DnskeyFlagsLSB - DnskeyFlagsZoneBit))) {
    flagStrings.push (DnssecConstants.ZoneFlag)
  }

  if (flags & DnskeyFlagsReservedMask) {
    flagStrings.push (DnssecConstants.OtherFlags)
  }

  return flagStrings.join (', ')
}

/**
 * Determine the string representation of the given DNSSEC protocol value.
 *
 * @param {Number} protocol     the protocol
 * @return {String}             the string representation of the protocol
 */
export function keyProtStr (protocol) {
  switch (protocol) {
    case 3:
      return DnssecConstants.DnssecProtocol

    default:
      return DnssecConstants.UnknownProtocol
  }
}

/**
 * Determine the string representation of the given DS Record digest type.
 *
 * @param {Number} digestType   the digest type
 * @return {String}             the string representation of the digest type
 */
export function digestTypeStr (digestType) {
  switch (digestType) {
    case 0:
      return DnssecConstants.ReservedAlgorithm

    case 1: return 'SHA-1'
    case 2: return 'SHA-256'
    case 3: return 'GOST R 34.11-94'
    case 4: return 'SHA-384'

    default:
      return DnssecConstants.UnassignedAlgorithm
  }
}

/**
 * Determine the string representation of the given DNSSEC algorithm
 * value.
 *
 * @param {Number} algorithm      the algorithm
 * @return {String}               the string representation of the algorithm
 */
export function keyAlgStr (algorithm) {
  switch (algorithm) {
    case 0: return 'DELETE'
    case 1: return 'RSAMD5'
    case 2: return 'DH'
    case 3: return 'DSA'
    case 4: return DnssecConstants.ReservedAlgorithm
    case 5: return 'RSASHA1'
    case 6: return 'DSA-NSEC3-SHA1'
    case 7: return 'RSASHA1-NSEC3-SHA1'
    case 8: return 'RSASHA256'
    case 9: return DnssecConstants.ReservedAlgorithm
    case 10: return 'RSASHA512'
    case 11: return DnssecConstants.ReservedAlgorithm
    case 12: return 'ECC-GOST'
    case 13: return 'ECDSAP256SHA256'
    case 14: return 'ECDSAP384SHA384'
    case 15: return 'ED25519'
    case 16: return 'ED448'
    // 17-122: unassigned, handled in default case
    // 123-251: reserved, handled in default case
    case 252: return 'INDIRECT'
    case 253: return 'PRIVATEDNS'
    case 254: return 'PRIVATEOID'
    case 255: return DnssecConstants.ReservedAlgorithm

    default:
      if (algorithm >= 16 && algorithm <= 122) {
        return DnssecConstants.UnassignedAlgorithm
      } else if (algorithm >= 123 && algorithm <= 251) {
        return DnssecConstants.ReservedAlgorithm
      } else {
        return DnssecConstants.InvalidAlgorithm
      }
  }
}
