<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-row>
    <v-col cols="6">
      <v-btn
        v-if="!last"
        v-t="'general.button.continue'"
        type="submit"
        rounded
        color="lime"
        class="elevation-0"
        @keydown.9.exact="$emit ('next')"
        @focus="$emit ('focus')"
        @click="$emit ('next')"/>
      <v-btn
        v-if="step !== 1"
        v-t="'general.button.back'"
        :class="{'ml-2': !last}"
        rounded
        color="lightgray"
        class="elevation-0"
        @click="$emit ('prev')"/>
    </v-col>
  </v-row>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  export default {
    name: 'NavigationButtons',

    props: {
      step: {
        type: Number,
        required: true
      },
      last: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
