var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.isContactAuthInfoSupported)?_c('v-col',{staticClass:"cgwng-bg-color-1 pa-2",attrs:{"cols":"6"}},[_c('auth-info',{attrs:{"registry-id":_vm.value.contact.registryId},on:{"input":_vm.updateData},model:{value:(_vm.value.contact.authInfo),callback:function ($$v) {_vm.$set(_vm.value.contact, "authInfo", $$v)},expression:"value.contact.authInfo"}})],1):_vm._e(),(!_vm.isFieldProhibited (_vm.fieldNamesMap.maintainer))?_c('v-col',{staticClass:"cgwng-bg-color-2 pa-2",attrs:{"cols":"6"}},[_c('v-text-field',{class:{required: _vm.isFieldPresent(_vm.fieldNamesMap.maintainer) && _vm.isFieldRequired(_vm.fieldNamesMap.maintainer)},attrs:{"spellcheck":"false","label":_vm.$t ('label.maintainer'),"error-messages":_vm.requiredErrors (
        'value.contact.maintainer',
        'required.maintainer')},on:{"blur":_vm.$v.value.contact.maintainer.$touch,"input":_vm.updateData},model:{value:(_vm.value.contact.maintainer),callback:function ($$v) {_vm.$set(_vm.value.contact, "maintainer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.contact.maintainer"}})],1):_vm._e(),(_vm.isCreate)?_c('v-col',{staticClass:"cgwng-bg-color-2 pa-2",attrs:{"cols":"6"}},[_c('v-text-field',{class:{required: _vm.isFieldPresent(_vm.fieldNamesMap.contactHandle) && _vm.isFieldRequired(_vm.fieldNamesMap.contactHandle)},attrs:{"spellcheck":"false","label":_vm.$t ('label.handle'),"disabled":_vm.isFieldProhibited(_vm.fieldNamesMap.contactHandle),"error-messages":_vm.requiredErrors (
        'value.contact.handle',
        'required.handle'),"hint":_vm.value.contact.handle === null || _vm.value.contact.handle === '' ?
        _vm.$t ('create.autoGeneratedHint') : '',"persistent-hint":""},on:{"input":_vm.updateData},model:{value:(_vm.value.contact.handle),callback:function ($$v) {_vm.$set(_vm.value.contact, "handle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.contact.handle"}})],1):_vm._e(),(!_vm.isFieldProhibited (_vm.fieldNamesMap.policy))?_c('v-col',{staticClass:"cgwng-bg-color-1 pa-2",attrs:{"cols":"6"}},[_c('v-autocomplete',{class:{required: _vm.isFieldPresent(_vm.fieldNamesMap.policy) && _vm.isFieldRequired(_vm.fieldNamesMap.policy)},attrs:{"clearable":"","label":_vm.$t ('label.policy.title'),"items":_vm.dataPolicyItems,"error-messages":_vm.requiredErrors (
        'value.contact.policy',
        'required.policy')},on:{"blur":_vm.$v.value.contact.policy.$touch,"input":_vm.updateData},model:{value:(_vm.value.contact.policy),callback:function ($$v) {_vm.$set(_vm.value.contact, "policy", $$v)},expression:"value.contact.policy"}})],1):_vm._e(),_c('v-col',{staticClass:"cgwng-bg-color-3",attrs:{"cols":"12"}},[_c('provider-chain',{ref:"contactProviderChain",attrs:{"edit":_vm.editProviderChain},on:{"edit":_vm.onEditProviderChain,"input":_vm.updateData},model:{value:(_vm.chainModel),callback:function ($$v) {_vm.chainModel=$$v},expression:"chainModel"}})],1),_c('v-col',{staticClass:"cgwng-bg-color-1",attrs:{"cols":"12"}},[_c('add-on',{attrs:{"v":_vm.$v.value.addOnFieldData},on:{"input":_vm.updateData},model:{value:(_vm.value.addOnFieldData),callback:function ($$v) {_vm.$set(_vm.value, "addOnFieldData", $$v)},expression:"value.addOnFieldData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }