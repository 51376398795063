import {digestTypeStr, keyAlgStr, keyFlagsStr, keyProtStr, DnssecConstants}
  from '@/app/utils/dnssec'

export default {
  methods: {
    /**
     * Determine the string representation of the given DNSSEC flags value.
     *
     * @param {String} flags      the flags
     * @return {String}           the string representation of the flags
     */
    flagsString (flags) {
      let flagsString = ''

      if (flags !== '') {
        flagsString = this.$t ('dnssec.invalid')

        const number = Number (flags)

        if (!isNaN (number)) {
          const str = keyFlagsStr (number)

          if (str !== DnssecConstants.InvalidFlags) {
            flagsString = str
          }
        }
      }

      return flagsString
    },

    /**
     * Determine the string representation of the given DNSSEC protocol value.
     *
     * @param {String} protocol     the protocol
     * @return {String}             the string representation of the protocol
     */
    protocolString (protocol) {
      let protocolString = ''

      if (protocol !== '') {
        protocolString = this.$t ('dnssec.invalid')

        const protocolNumber = Number (protocol)

        if (!isNaN (protocolNumber)) {
          const str = keyProtStr (protocolNumber)

          protocolString = str === DnssecConstants.UnknownProtocol
            ? this.$t ('dnssec.unknown')
            : str
        }
      }

      return protocolString
    },

    /**
     * Determine the string representation of the given DS Record digest type.
     *
     * @param {String} digestType     the digest type
     * @return {String}               the string representation of the digest
     *                                type
     */
    digestTypeString (digestType) {
      let digestTypeString = ''

      if (digestType !== '') {
        digestTypeString = this.$t ('dnssec.invalid')

        const digestTypeNumber = Number (digestType)

        if (!isNaN (digestTypeNumber)) {
          digestTypeString = digestTypeStr (digestTypeNumber)

          switch (digestTypeString) {
            case DnssecConstants.ReservedAlgorithm:
              digestTypeString = this.$t ('dnssec.reserved')
              break

            case DnssecConstants.UnassignedAlgorithm:
              digestTypeString = this.$t ('dnssec.unassigned')
              break

            default:
              // do nothing
          }
        }
      }

      return digestTypeString
    },

    /**
     * Determine the string representation of the given DNSSEC algorithm
     * value.
     *
     * @param {String} algorithm      the algorithm
     * @return {String}               the string representation of the
     *                                algorithm
     */
    algorithmString (algorithm) {
      let algorithmString = ''

      if (algorithm !== '') {
        algorithmString = this.$t ('dnssec.invalid')

        const algorithmNumber = Number (algorithm)

        if (!isNaN (algorithmNumber)) {
          algorithmString = keyAlgStr (algorithmNumber)

          switch (algorithmString) {
            case DnssecConstants.ReservedAlgorithm:
              algorithmString = this.$t ('dnssec.reserved')
              break

            case DnssecConstants.UnassignedAlgorithm:
              algorithmString = this.$t ('dnssec.unassigned')
              break

            case DnssecConstants.InvalidAlgorithm:
              algorithmString = this.$t ('dnssec.invalid')
              break

            default:
            // nothing
          }
        }
      }

      return algorithmString
    }
  }
}
