<template>
  <span>
    <router-link
      v-if="link && exists && !history"
      class="nobr"
      :to="link">
      <span
        :class="{deletedItemLink: status === 'deleted'}"
        v-text="value"/>
    </router-link>
    <router-link
      v-else-if="history"
      class="nobr"
      :to="{
        name: 'contact.search',
        query: {
          filter: JSON.stringify ({
            handle: value || handle,
            registryTypes: [registry],
            contactState: 'any'
          })
        }
      }">
      <span
        :class="{deletedItemLink: status === 'deleted'}"
        v-text="handle"/>
    </router-link>
    <span
      v-else-if="status === 'foreign'"
      class="nobr"
      v-text="`${handle}`"/>
    <span
      v-else
      class="nobr"
      v-text="`${value || handle}`"/>
  </span>
</template>

<script>
  export default {
    name: 'ContactHandle',

    /** contact properties */
    props: {
      handle: {
        type: String,
        default: undefined
      },
      link: {
        type: Object,
        default: undefined
      },
      status: {
        type: String,
        default: undefined
      },
      value: {
        type: String,
        default: undefined
      },
      registry: {
        type: String,
        default: undefined
      },
      history: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      exists () {
        return this.status !== 'foreign'
      }
    }
  }
</script>

<style scoped>
  .nobr {
    white-space: nowrap
  }
</style>
