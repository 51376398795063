/**
 * Mixin for validating the last step if the stepper changes
 *
 *
 * requirements:
 *  - have a step value in the component
 *  - have a stepToRefMap value
 */
export default {
  watch: {
    step (newVal, oldVal) {
      const ref = this.stepToRefMap[oldVal]

      if (ref && ref.$v) {
        ref.$v.$touch ()
      }
    }
  }
}
