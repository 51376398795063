<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <div>
    <v-row>
      <v-col
        v-if="isAuthInfoSettable || !isCreate"
        cols="6"
        class="cgwng-bg-color-1">
        <auth-info
          v-if="isAuthInfoSettable"
          v-model="value.domain.authInfo"
          :registry-id="value.domain.registryId"
          @input="updateData"/>
        <template v-else-if="!isCreate">
          <v-row class="fill-height" align="center" justify="center">
            <v-btn
              v-t="'create.label.requestAuth'"
              @click="openAuthRequest"/>
            <auth-info-dialog
              ref="authInfoDialog"
              v-model="authInfoDialog"
              :object="domainIdentification"/>
          </v-row>
        </template>
      </v-col>
      <v-col
        :cols="isAuthInfoSettable || !isCreate ? 6 : 12"
        class="cgwng-bg-color-2">
        <v-text-field
          v-model.trim="value.domain.maintainer"
          spellcheck="false"
          :label="$t ('create.label.maintainer')"
          @input="updateData"/>
      </v-col>
      <v-col
        cols="12"
        class="cgwng-bg-color-3">
        <provider-chain
          ref="domainProviderChain"
          v-model="chainModel"
          class="ml-0"
          :edit="editProviderChain"
          @edit="onEditProviderChain"
          @input="updateData"/>
      </v-col>
      <v-col
        cols="12"
        class="cgwng-bg-color-1">
        <add-on
          v-model="value.addOnFieldData"
          :v="$v.value.addOnFieldData"
          @input="updateData"/>
      </v-col>
    </v-row>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters} from 'vuex'

  import AddOn from '@/app/core/components/RegistryObject/AddOn'
  import AddOnValidation from '@/app/core/components/RegistryObject/AddOnValidation'
  import AuthInfo from '@/app/core/components/RegistryObject/AuthInfo'
  import ProviderChain from '@/app/core/components/RegistryObject/ProviderChain'

  import AuthInfoDialog
    from './components/DomainAuthInfoDialog'

  export default {
    name: 'DomainAdministrativeData',

    components: {
      AddOn,
      AuthInfo,
      ProviderChain,
      AuthInfoDialog
    },

    props: {
      value: {
        type: Object,
        required: true,
        validator (v) {
          return v.hasOwnProperty ('domain')
        }
      },

      isCreate: {
        type: Boolean,
        default: false
      },

      domainIdentification: {
        type: Object,
        default: undefined
      }
    },

    data () {
      return {
        chainModel: {
          spec: '',
          type: '',
          clientId: 0
        },
        editProviderChain: false,
        authInfoDialog: false
      }
    },

    computed: {
      ...mapGetters ({
        isAuthInfoSettable: 'meta/isAuthInfoSettable'
      })
    },

    validations: {
      value: {
        addOnFieldData: AddOnValidation
      }
    },

    watch: {
      value (newValue) {
        this.populateModel ()
      }
    },

    created () {
      this.editProviderChain = this.value.editProviderChain
      this.populateModel ()
    },

    methods: {
      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$refs.domainProviderChain.hasErrors ()
      },

      /**
       * make a request for a new auth info for the specified domain
       */
      openAuthRequest () {
        this.authInfoDialog = true

        if (this.$refs.authInfoDialog) {
          this.$refs.authInfoDialog.requestAuth ()
        } else {
          this.$nextTick (() => {
            this.$refs.authInfoDialog.requestAuth ()
          })
        }
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return this.$refs.domainProviderChain.isValid ()
      },

      /**
       * Emit an "input" event in case of changed data.
       */
      updateData () {
        this.value.editProviderChain = this.editProviderChain
        this.value.domain.providerChainSpec = this.chainModel.spec
        this.value.domain.providerChainType = this.chainModel.type
        this.$emit ('input', this.value)
      },

      /**
       * Emit an "input" event in case the "edit provider chain" switch is
       * toggled.
       */
      onEditProviderChain (e) {
        this.editProviderChain = e
        this.updateData ()
      },

      /**
       * Populate the internal model with the values from the passed prop.
       */
      populateModel () {
        this.chainModel.spec = this.value.domain.providerChainSpec
        this.chainModel.type = this.value.domain.providerChainType
        this.chainModel.clientId = this.value.domain.clientId
      }

    }
  }
</script>
