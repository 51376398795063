<messages>["../Domain"]</messages>

<template>
  <v-autocomplete
    ref="input"
    v-model="selectedPhases"
    :multiple="!single"
    clearable
    :label="$t('create.label.launchPhase')"
    :items="options"
    :loading="isLoading"
    @blur="$emit('blur', $event)">
    <template
      v-if="!single"
      #selection="{ item, parent }">
      <v-chip
        :key="item.text"
        close
        outlined
        small
        color="black"
        class="my-1"
        @update:active="parent.selectItem (item)">
        <span
          class="chipContent"
          :title="item.text"
          v-text="item.text"/>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
  import {mapActions} from 'vuex'

  import {formatLauchPhase} from '../constants'

  export default {
    props: {
      selectedRegistries: {
        type: Array,
        default: undefined
      },
      value: {
        type: [Array, Object],
        default: undefined
      },
      single: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isLoading: false,
        availableRegistries: null
      }
    },

    computed: {
      selectedPhases: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      options () {
        const mapRegistries = (r) => r.map ((r) => {
          return this.availableRegistries?.[r]?.launchPhases
        }).reduce ((acc, p) => {
          return [...acc, ...(p?.map (ph => ({text: formatLauchPhase (ph), value: ph})) || [])]
        }, [])

        const options = this.selectedRegistries && this.selectedRegistries.length
          ? mapRegistries (this.selectedRegistries)
          : mapRegistries (Object.keys (this.availableRegistries || {}))

        const deduplicated = []

        options.forEach ((d) => {
          if (!deduplicated.find ((i) => i.text === d.text)) deduplicated.push (d)
        })

        deduplicated.sort ((n1, n2) => n1.text.localeCompare (n2.text))

        return [
          ...!this.single ? [{text: this.$t ('filter.noLaunchPhase'), value: null}] : [],
          ...deduplicated
        ]
      }
    },

    async created () {
      this.isLoading = true
      this.availableRegistries = await this.getVisibleRegistries ()
      this.isLoading = false
    },

    methods: {
      ...mapActions ({
        getVisibleRegistries: 'cache/getVisibleRegistryMetaData'
      }),

      focus () {
        this.$refs.input.focus ()
      }
    }
  }
</script>
