var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"cgwng-bg-color-1"},[(!_vm.isFieldProhibited (_vm.fieldNamesMap.phoneNumber))?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-text-field',{class:{required: _vm.isFieldPresent(_vm.fieldNamesMap.phoneNumber) && _vm.isFieldRequired(_vm.fieldNamesMap.phoneNumber)},attrs:{"label":_vm.$t ('label.voicePhoneNumber'),"error-messages":_vm.validationErrors (
        'value.phoneNumber',
        {
          phone: 'general.invalid.phone',
          required: 'required.phoneNumber'
        })},on:{"input":_vm.updateData,"blur":function($event){_vm.$v.value.phoneNumber.$touch ()}},model:{value:(_vm.value.phoneNumber),callback:function ($$v) {_vm.$set(_vm.value, "phoneNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.phoneNumber"}})],1):_vm._e(),(!_vm.isFieldProhibited (_vm.fieldNamesMap.phoneNumberExt))?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-text-field',{class:{required: _vm.isFieldPresent(_vm.fieldNamesMap.phoneNumberExt) && _vm.isFieldRequired(_vm.fieldNamesMap.phoneNumberExt)},attrs:{"label":_vm.$t ('label.voicePhoneNumberExt'),"error-messages":_vm.validationErrors (
        'value.phoneNumberExt',
        {
          extention: 'required.extentionError',
          required: 'required.phoneNumberExt'
        })},on:{"input":_vm.updateData,"blur":function($event){_vm.$v.value.phoneNumberExt.$touch ()}},model:{value:(_vm.value.phoneNumberExt),callback:function ($$v) {_vm.$set(_vm.value, "phoneNumberExt", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.phoneNumberExt"}})],1):_vm._e(),(!_vm.isFieldProhibited (_vm.fieldNamesMap.faxNumber))?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-text-field',{class:{required: _vm.isFieldPresent(_vm.fieldNamesMap.faxNumber) && _vm.isFieldRequired(_vm.fieldNamesMap.faxNumber)},attrs:{"label":_vm.$t ('label.faxNumber'),"error-messages":_vm.validationErrors (
        'value.faxNumber',
        {
          phone: 'general.invalid.fax',
          required: 'required.faxNumber'
        })},on:{"input":_vm.updateData,"blur":function($event){_vm.$v.value.faxNumber.$touch ()}},model:{value:(_vm.value.faxNumber),callback:function ($$v) {_vm.$set(_vm.value, "faxNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.faxNumber"}})],1):_vm._e(),(!_vm.isFieldProhibited (_vm.fieldNamesMap.faxNumberExt))?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-text-field',{class:{required: _vm.isFieldPresent(_vm.fieldNamesMap.faxNumberExt) && _vm.isFieldRequired(_vm.fieldNamesMap.faxNumberExt)},attrs:{"label":_vm.$t ('label.faxNumberExt'),"error-messages":_vm.validationErrors (
        'value.faxNumberExt',
        {
          extention: 'required.extentionError',
          required: 'required.faxNumberExt'
        })},on:{"input":_vm.updateData,"blur":function($event){_vm.$v.value.faxNumberExt.$touch ()}},model:{value:(_vm.value.faxNumberExt),callback:function ($$v) {_vm.$set(_vm.value, "faxNumberExt", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.faxNumberExt"}})],1):_vm._e(),(!_vm.isFieldProhibited (_vm.fieldNamesMap.emailAddress))?_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{class:{required: _vm.isFieldPresent(_vm.fieldNamesMap.emailAddress) && _vm.isFieldRequired(_vm.fieldNamesMap.emailAddress)},attrs:{"spellcheck":"false","label":_vm.$t ('label.emailAddress'),"error-messages":_vm.validationErrors (
        'value.emailAddress',
        {
          email: 'general.invalid.email',
          required: 'required.email'
        })},on:{"input":_vm.updateData,"blur":function($event){_vm.$v.value.emailAddress.$touch ()}},model:{value:(_vm.value.emailAddress),callback:function ($$v) {_vm.$set(_vm.value, "emailAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.emailAddress"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }