/**
 * Create an object from the given key/value array.
 *
 * The array is supposed to contain objects with each a "key" and a "value" key.
 * The resulting object then contains the "key" values as field names and the
 * "value" values as corresponding values.
 *
 * Example: The array [{key: foo, value: bar}, {key: baz, value: qux}] will be
 * converted to the object {foo: bar, baz: qux}.
 *
 * @param {Array} array       the source array
 * @return {Object}           the created object
 */
export function keyValueArrayToObject (array) {
  const obj = {}

  if (isKeyValueArray (array)) {
    array.reduce ((map, it) => {
      if (it.key !== '') {
        map[it.key] = it.value
      }
      return map
    }, obj)
  }

  return obj
}

/**
 * Create a key/value array from the given object.
 *
 * The created array will contain objects with each a "key" and a "value" key.
 *
 * @param {Object} obj                the source object
 * @param {Object} keyExtractor       the function converting object property
 *                                    keys into array keys
 * @param {Object} valueExtractor     the function converting object property
 *                                    values into array values
 * @return {Array}                    the created array
 */
export function objectToKeyValueArray (obj, keyExtractor, valueExtractor) {
  const array = []

  for (const field in obj) {
    if (obj.hasOwnProperty (field)) {
      array.push ({
        key: keyExtractor (field),
        value: valueExtractor (obj[field])
      })
    }
  }

  return array
}

/**
 * Check whether the given array consists of objects with each a "key" and a
 * "value" key.
 *
 * @param {*} array       the array to check
 * @return {Boolean}      true if the array meets the requirements
 */
function isKeyValueArray (array) {
  return array.every (e => {
    return typeof e === 'object' &&
      e.hasOwnProperty ('key') &&
      e.hasOwnProperty ('value')
  })
}
