<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <div>
    <v-row :class="{ 'cgwng-bg-color-1': bgcolor }">
      <v-col cols="12">
        <domain-dnssec-dialog
          v-if="!readonly"
          v-model="dnssecDialog"
          :show-ds="enterDsRecordData"
          :show-dnskey="enterDnskeyData"
          :data="dnssecData"
          @ok="onSave"/>
      </v-col>
    </v-row>
    <v-row :class="{ 'cgwng-bg-color-1': bgcolor }">
      <!-- DS Record table -->
      <v-col
        v-if="hasDsData"
        cols="12"
        class="pb-6">
        <div
          v-t="'create.label.dnssec.ds'"
          class="dnssec-table-header"/>
        <v-data-table
          hide-default-footer
          class="dnssec-table elevation-1"
          :headers="dsHeaders"
          :items="dnssecRecords"
          :items-per-page="-1">
          <template #item="props">
            <tr
              v-if="props.item.dsData"
              :class="{ highlighted: highlight === props.index }"
              @mouseover="highlight = props.index"
              @mouseout="highlight = null">
              <td v-if="props.item.dsData.generated">
                <v-tooltip top>
                  <v-icon v-on="on">
                    computer
                  </v-icon>
                  <span v-t="'create.label.dnssec.computed'"/>
                </v-tooltip>
              </td>
              <td v-else-if="hasGeneratedDsData"/>
              <td
                v-if="$vuetify.breakpoint.mdAndUp"
                class="text-right">
                {{ props.index + 1 }}
              </td>
              <td>
                <span :class="{ 'dnssec-code': props.item.dsData.keyTag }">
                  {{ props.item.dsData.keyTag || '\u2014' }}
                </span>
              </td>
              <td>
                <span :class="{ 'dnssec-code': props.item.dsData.algorithm }">
                  {{ props.item.dsData.algorithm || '\u2014' }}
                </span>
                <div
                  v-if="props.item.dsData.algorithm &&
                    $vuetify.breakpoint.mdAndUp"
                  class="dnssec-string">
                  {{ algorithmString (props.item.dsData.algorithm) }}
                </div>
              </td>
              <td>
                <span :class="{ 'dnssec-code': props.item.dsData.digestType }">
                  {{ props.item.dsData.digestType || '\u2014' }}
                </span>
                <div
                  v-if="props.item.dsData.digestType &&
                    $vuetify.breakpoint.mdAndUp"
                  class="dnssec-string">
                  {{ digestTypeString (props.item.dsData.digestType) }}
                </div>
              </td>
              <td>
                <span :class="{ 'dnssec-bin': props.item.dsData.hash }">
                  {{ props.item.dsData.hash || '\u2014' }}
                </span>
              </td>
              <td v-if="!readonly">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="mx-0"
                      v-on="on"
                      @click="onEdit (props.index, 'ds')">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'create.label.dnssec.edit'"/>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="mx-0"
                      v-on="on"
                      @click="onDelete (props.index)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'create.label.dnssec.delete'"/>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>

      <!-- DNSKEY data table -->
      <v-col
        v-if="hasDnskeyData"
        cols="12">
        <div
          v-t="'create.label.dnssec.dnskey'"
          class="dnssec-table-header"/>
        <v-data-table
          hide-default-footer
          class="dnssec-table elevation-1"
          :headers="dnskeyHeaders"
          :items="dnssecRecords"
          :items-per-page="-1">
          <template #item="props">
            <tr
              :class="{ highlighted: highlight === props.index }"
              @mouseover="highlight = props.index"
              @mouseout="highlight = null">
              <td
                v-if="$vuetify.breakpoint.mdAndUp"
                class="text-right">
                {{ props.index + 1 }}
              </td>
              <td>
                <span :class="{ 'dnssec-code': props.item.dnskeyData && props.item.dnskeyData.flags }">
                  {{ props.item.dnskeyData && props.item.dnskeyData.flags || '\u2014' }}
                </span>
                <div
                  v-if="props.item.dnskeyData && props.item.dnskeyData.flags &&
                    $vuetify.breakpoint.mdAndUp"
                  class="dnssec-string">
                  {{ flagsString (props.item.dnskeyData.flags) }}
                </div>
              </td>
              <td>
                <span :class="{ 'dnssec-code': props.item.dnskeyData && props.item.dnskeyData.protocol }">
                  {{ props.item.dnskeyData && props.item.dnskeyData.protocol || '\u2014' }}
                </span>
                <div
                  v-if="props.item.dnskeyData && props.item.dnskeyData.protocol &&
                    $vuetify.breakpoint.mdAndUp"
                  class="dnssec-string">
                  {{ protocolString (props.item.dnskeyData.protocol) }}
                </div>
              </td>
              <td>
                <span :class="{ 'dnssec-code': props.item.dnskeyData && props.item.dnskeyData.algorithm }">
                  {{ props.item.dnskeyData && props.item.dnskeyData.algorithm || '\u2014' }}
                </span>
                <div
                  v-if="props.item.dnskeyData && props.item.dnskeyData.algorithm &&
                    $vuetify.breakpoint.mdAndUp"
                  class="dnssec-string">
                  {{ algorithmString (props.item.dnskeyData.algorithm) }}
                </div>
              </td>
              <td>
                <span :class="{ 'dnssec-bin': props.item.dnskeyData && props.item.dnskeyData.publicKey }">
                  {{ props.item.dnskeyData && props.item.dnskeyData.publicKey || '\u2014' }}
                </span>
              </td>
              <td v-if="!readonly">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="mx-0"
                      v-on="on"
                      @click="onEdit (props.index, 'dnskey')">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'create.label.dnssec.edit'"/>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="mx-0"
                      v-on="on"
                      @click="onDelete (props.index)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'create.label.dnssec.delete'"/>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import _cloneDeep from 'lodash/cloneDeep'

  import DomainDnssecDialog from './DomainDnssecDialog'

  import dnssecMixins from './DomainDnssecHelper'

  export default {
    name: 'DomainDnssecData',

    components: {
      DomainDnssecDialog
    },

    mixins: [dnssecMixins],

    props: {
      value: {
        type: Array,
        required: true
      },
      readonly: {
        type: Boolean,
        default: false
      },
      bgcolor: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        highlight: null,
        editIndex: -1,
        dnssecData: {
          dsData: {},
          dnskeyData: {}
        },
        dnssecDialog: false,
        enterDsRecordData: false,
        enterDnskeyData: false,
        dnssecRecords: []
      }
    },

    computed: {
      /**
       * Whether at least one DNSKEY data record exists.
       *
       * @return {Boolean}     true if so
       */
      hasDnskeyData () {
        return this.dnssecRecords.some (r => r.dnskeyData)
      },

      /**
       * Whether at least one DS record exists.
       *
       * @return {Boolean}     true if so
       */
      hasDsData () {
        return this.dnssecRecords.some (r => r.dsData)
      },

      /**
       * Whether at least one DS Record has been generated automatically by the
       * Gateway.
       *
       * @return {Boolean}      true if so
       */
      hasGeneratedDsData () {
        return this.dnssecRecords.some (r => r.dsData?.generated)
      },

      /**
       * Get the headers for the DNSKEY data table.
       *
       * @return {Array}       the headers
       */
      dnskeyHeaders () {
        let headers = []

        if (this.$vuetify.breakpoint.mdAndUp) {
          headers.push ({
            text: this.$t ('create.label.dnssec.record'),
            value: 'record',
            sortable: false,
            align: 'right',
            width: '75px'
          })
        }

        headers = headers.concat ([
          {
            text: this.$t ('create.label.dnssec.flags'),
            value: 'dnskeyFlags',
            sortable: false
          },
          {
            text: this.$t ('create.label.dnssec.protocol'),
            value: 'dnskeyProtocol',
            sortable: false
          },
          {
            text: this.$t ('create.label.dnssec.algorithm'),
            value: 'dnskeyAlgorithm',
            sortable: false
          },
          {
            text: this.$t ('create.label.dnssec.key'),
            value: 'dnskeyPublicKey',
            sortable: false,
            width: this.$vuetify.breakpoint.mdAndUp ? '35%' : '20%'
          }])

        if (!this.readonly) {
          headers.push ({
            value: 'actions',
            sortable: false,
            width: '105px'
          })
        }

        return headers
      },

      /**
       * Get the headers for the DS Records data table.
       *
       * @return {Array}       the headers
       */
      dsHeaders () {
        let headers = []

        if (this.hasGeneratedDsData) {
          headers.push ({
            value: 'computed',
            sortable: false,
            width: '55px'
          })
        }

        if (this.$vuetify.breakpoint.mdAndUp) {
          headers.push ({
            text: this.$t ('create.label.dnssec.record'),
            value: 'record',
            sortable: false,
            align: 'right',
            width: '75px'
          })
        }

        headers = headers.concat ([
          {
            text: this.$t ('create.label.dnssec.keytag'),
            value: 'dsKeyTag',
            sortable: false
          },
          {
            text: this.$t ('create.label.dnssec.algorithm'),
            value: 'dsAlgorithm',
            sortable: false
          },
          {
            text: this.$t ('create.label.dnssec.digesttype'),
            value: 'dsDigestType',
            sortable: false
          },
          {
            text: this.$t ('create.label.dnssec.hash'),
            value: 'dsHash',
            sortable: false,
            width: this.$vuetify.breakpoint.mdAndUp ? '35%' : '20%'
          }])

        if (!this.readonly) {
          headers.push ({
            value: 'actions',
            sortable: false,
            width: '105px'
          })
        }

        return headers
      }
    },

    watch: {
      value () {
        this.populateForm ()
      },

      dnssecRecords () {
        this.$emit ('input', this.dnssecRecords)
      }
    },

    created () {
      this.populateForm ()
    },

    methods: {
      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return false
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return true
      },

      /**
       * Save the DNSSEC data from the form to the DNSSEC data table (if valid).
       *
       * @param {Object} dnssecData     the DNSSEC data object to save
       */
      onSave (dnssecData) {
        const hasDnskeyData = Object.keys (dnssecData.dnskeyData).length > 0
        const hasDsData = Object.keys (dnssecData.dsData).length > 0

        if (hasDnskeyData || hasDsData) {
          if (this.editIndex >= 0) {
            this.$set (this.dnssecRecords, this.editIndex, dnssecData)
          } else {
            this.dnssecRecords.push (_cloneDeep (dnssecData))
          }

          this.$emit ('input', this.dnssecRecords)
        }

        this.editIndex = -1
      },

      /**
       * Edit the DNSSEC record with the given index.
       *
       * @param {Number} index      the index of the DNSSEC data record to edit
       * @param {String} type       the record type to edit
       */
      onEdit (index, type) {
        switch (type) {
          case 'ds':
            this.enterDsRecordData = true
            this.enterDnskeyData = false
            break

          case 'dnskey':
            this.enterDnskeyData = true
            this.enterDsRecordData = false
            break
        }

        this.dnssecData = this.dnssecRecords[index]
        this.editIndex = index
        this.dnssecDialog = true
      },

      /**
       * Delete the record with the given index from the DNSSEC data table.
       *
       * @param {Number} index      the index of the record to delete
       */
      onDelete (index) {
        this.dnssecRecords.splice (index, 1)
      },

      /**
       * Populate the DNSSEC data form with the DNSSEC records passed as value
       * parameter (if any).
       */
      populateForm () {
        this.dnssecRecords = this.value
      }
    }
  }
</script>

<!--
================================================================================
  Styling
================================================================================
-->

<style lang="scss">
@import '../../scss/definitions';

.dnssec-table-header {
  font-size: 14px;
  font-weight: 500;
}

.dnssec-table table {
  table-layout: fixed;
  & th {
    vertical-align: middle;
  }
  & tr {
    vertical-align: top;
  }
  & td {
    word-wrap: break-word;
    & .dnssec-code {
      font-weight: bold;
    }
    & .dnssec-string {
      font-style: italic;
      color: gray;
    }
    & .dnssec-bin {
      @extend %monospaced;
    }
  }
}

.highlighted {
  background-color: rgb(238, 238, 238) !important
}

.pubkey-field div.input-group__input {
  border: 1px solid !important
}
</style>
