<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <div>
    <registry-object-create-update
      type="domain"
      :is-create="isCreate"
      :name="domainObject.domain.name"
      :registry-id="domainObject.domain.registryId"
      :client-id="domainObject.domain.clientId"
      :show-buttons="showStepper"
      :show-registry-warning="domainObject.domain.registryId && !metaDataSupported"
      :response="orderProcessingResponse"
      :is-loading="isLoading"
      :limit-price="isCreate"
      :promo-code.sync="promoCode"
      :show-promocode="showPromotionCode"
      @limitPriceChanged="onLimitPriceChanged"
      @registrySelected="onRegistrySelected"
      @loaded="onCreateUpdateComponentLoaded"
      @clientSelected="onClientSelected"
      @submit="onSubmit"
      @reset="onReset">
      <template
        v-if="isCreate"
        #actions>
        <v-btn
          v-t="'create.startOver.label'"
          @click="onStartOver"/>
      </template>

      <!-- domain data stepper -->
      <template #content>
        <v-stepper
          v-if="showStepper"
          v-model="step"
          vertical
          class="mb-12">
          <!-- basic data -->
          <v-stepper-step
            :step="getStep (1)"
            editable
            :rules="[() => isBasicDataValid ()]"
            :complete="step > getStep (1)">
            <span v-t="'create.basicData'"/>
          </v-stepper-step>
          <v-stepper-content :step="getStep (1)">
            <v-card class="elevation-0">
              <form
                novalidate
                @submit.prevent>
                <v-card-text>
                  <domain-basic-data
                    ref="domainBasicData"
                    v-model="basicData"
                    :is-create="isCreate"/>
                </v-card-text>
                <v-card-actions>
                  <navigation-buttons
                    :step="getStep (1)"
                    @focus="step = getStep (1)"
                    @next="step = getStep (1) + 1"/>
                </v-card-actions>
              </form>
            </v-card>
          </v-stepper-content>

          <!-- contact data -->
          <v-stepper-step
            v-if="hasContactSupport"
            :step="getStep (2)"
            editable
            :rules="[() => isContactDataValid ()]"
            :complete="step > getStep (2)">
            <span v-t="'create.contactData'"/>
          </v-stepper-step>
          <v-stepper-content v-if="hasContactSupport" :step="getStep (2)">
            <v-card class="elevation-0">
              <form
                novalidate
                @submit.prevent>
                <v-card-text>
                  <domain-contact-data
                    ref="domainContactData"
                    v-model="contactData"
                    :extra-data="contactsExtraData"
                    :reset-inline-success-message="!isContactDataVisible"/>
                </v-card-text>
                <v-card-actions>
                  <navigation-buttons
                    :step="getStep (2)"
                    @focus="step = getStep (2)"
                    @prev="step = getStep (2) - 1"
                    @next="step = getStep (2) + 1"/>
                </v-card-actions>
              </form>
            </v-card>
          </v-stepper-content>
          <!-- registry-specific data -->
          <template v-if="showRegistrySpecificDataStep">
            <v-stepper-step
              :step="getStep (3)"
              editable
              :rules="[() => isSpecificDataValid ()]"
              :complete="step > getStep (3)">
              <span v-t="'create.specificData'"/>
            </v-stepper-step>
            <v-stepper-content :step="getStep (3)">
              <v-card class="elevation-0">
                <form
                  novalidate
                  @submit.prevent>
                  <v-card-text>
                    <registry-specific-data
                      ref="domainRegistrySpecificData"
                      v-model="specificFields"/>
                  </v-card-text>
                  <v-card-actions>
                    <navigation-buttons
                      :step="getStep (3)"
                      @focus="step = getStep (3)"
                      @prev="step = getStep (3) - 1"
                      @next="step = getStep (3) + 1"/>
                  </v-card-actions>
                </form>
              </v-card>
            </v-stepper-content>
          </template>

          <!-- host data -->
          <v-stepper-step
            v-if="hasHostSupport"
            :step="getStep (4)"
            editable
            :rules="[() => isHostDataValid ()]"
            :complete="step > getStep (4)">
            <span v-t="'create.hostData'"/>
          </v-stepper-step>
          <v-stepper-content
            v-if="hasHostSupport" :step="getStep (4)">
            <v-card class="elevation-0">
              <form
                novalidate
                @submit.prevent>
                <v-card-text>
                  <domain-host-data
                    ref="domainHostData"
                    v-model="hostData"/>
                </v-card-text>
                <v-card-actions>
                  <navigation-buttons
                    :step="getStep (4)"
                    @focus="step = getStep (4)"
                    @prev="step = getStep (4) - 1"
                    @next="step = getStep (4) + 1"/>
                </v-card-actions>
              </form>
            </v-card>
          </v-stepper-content>

          <!-- DNSSEC data -->
          <template v-if="registrySupportsDnsSec">
            <v-stepper-step
              :step="getStep (5)"
              editable
              :rules="[() => isDnssecDataValid ()]"
              :complete="step > getStep (5)">
              <span v-t="'create.dnssecData'"/>
            </v-stepper-step>
            <v-stepper-content :step="getStep (5)">
              <v-card class="elevation-0">
                <form
                  novalidate
                  @submit.prevent>
                  <v-card-text>
                    <domain-dnssec-data
                      v-if="isDnssecEnabled"
                      ref="domainDnssecData"
                      v-model="dnssecData"
                      bgcolor/>
                    <p
                      v-else
                      v-t="'update.dnssecDisabled'"/>
                  </v-card-text>
                  <v-card-actions>
                    <navigation-buttons
                      :step="getStep (5)"
                      @focus="step = getStep (5)"
                      @prev="step = getStep (5) - 1"
                      @next="step = getStep (5) + 1"/>
                  </v-card-actions>
                </form>
              </v-card>
            </v-stepper-content>
          </template>

          <!-- administrative data -->
          <v-stepper-step
            :step="getStep (6)"
            editable
            :rules="[() => isAdministrativeDataValid ()]"
            :complete="step > getStep (6)">
            <span v-t="'create.furtherData'"/>
          </v-stepper-step>
          <v-stepper-content :step="getStep (6)">
            <v-card class="elevation-0">
              <form
                novalidate
                @submit.prevent>
                <v-card-text>
                  <domain-administrative-data
                    ref="domainAdministrativeData"
                    v-model="administrativeData"
                    :domain-identification="idObject"
                    :is-create="isCreate"/>
                </v-card-text>
                <v-card-actions>
                  <navigation-buttons
                    :step="getStep (6)"
                    last
                    @focus="step = getStep (6)"
                    @prev="step = getStep (6) - 1"/>
                </v-card-actions>
              </form>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </template>
    </registry-object-create-update>

    <confirmation-dialog
      v-model="showStartOverConfirmation"
      v-t="'create.startOver.confirmation.text'"
      :headline="$t ('create.startOver.confirmation.headline')"
      @ok="doStartOver"/>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import _cloneDeep from 'lodash/cloneDeep'
  import _isEmpty from 'lodash/isEmpty'
  import _isEqual from 'lodash/isEqual'
  import _isObject from 'lodash/isObject'
  import _merge from 'lodash/merge'
  import _pick from 'lodash/pick'
  import _transform from 'lodash/transform'

  import {mapActions, mapGetters, mapMutations} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import NavigationButtons
    from '@/app/core/components/RegistryObject/NavigationButtons'
  import RegistryObjectCreateUpdate
    from '@/app/core/components/RegistryObject/RegistryObjectCreateUpdate'
  import RegistrySpecificData
    from '@/app/core/components/RegistryObject/RegistrySpecificData'
  import ValidationParent from '@/app/core/mixins/ValidationParent'
  import StepValidatorMixin from '@/app/core/mixins/StepValidatorMixin'

  import {getDefaultDomainParams, DefaultEditProviderChain, getAddOnFieldData} from './constants'
  import DomainAdministrativeData from './DomainAdministrativeData'
  import DomainBasicData from './DomainBasicData'
  import DomainContactData from './DomainContactData'
  import DomainDnssecData from './DomainDnssecData'
  import DomainHostData, {defaultValue as defaultHosts} from './DomainHostData'

  import {keyValueArrayToObject, objectToKeyValueArray} from '@/app/utils/array'
  import {removePrefix} from '@/app/utils/string'
  import errorTranslator from '@/app/services/errortranslator'

  import {typeProp, TYPES} from './ApplicationSupport'

  import {isUpdateProhibited}
    from '@/app/core/components/RegistryObject/StatesDialog'

  export default {
    name: 'DomainCreateUpdate',

    components: {
      ConfirmationDialog,
      DomainAdministrativeData,
      DomainBasicData,
      DomainContactData,
      DomainDnssecData,
      DomainHostData,
      NavigationButtons,
      RegistryObjectCreateUpdate,
      RegistrySpecificData
    },

    mixins: [ValidationParent, StepValidatorMixin],

    props: {
      isCreate: {
        type: Boolean,
        default: false
      },
      domainData: {
        type: Object,
        default: undefined
      },
      domainName: {
        type: String,
        default: undefined
      },
      registryId: {
        type: String,
        default: undefined
      },
      domain: {
        type: String,
        default: undefined
      },
      reset: {
        type: Boolean,
        default: false
      },
      type: typeProp
    },

    data () {
      return {
        step: 1,
        orderProcessingResponse: null,
        domainObject: {
          domain: {},
          editProviderChain: false,
          specificFieldData: [],
          addOnFieldData: []
        },
        contactsExtraData: {},
        isLoading: false,
        isContactDataVisible: false,
        defaultDomain: null,
        showStartOverConfirmation: false,
        isDnssecEnabled: true,
        initialLoad: true,
        promoCode: ''
      }
    },

    computed: {
      ...mapGetters ({
        domainCreateData: 'create/getDomainCreateData',
        getDomainCloneData: 'create/getDomainCloneData',
        getClonedDomainDataFor: 'create/getClonedDomainDataFor',
        launchPhases: 'meta/getLaunchPhases',
        metaDataSupported: 'meta/isMetaDataSupported',
        dsDisallowed: 'meta/isDsDataDisallowed',
        dnskeyDisallowed: 'meta/isDnskeyDataDisallowed',
        isPromotionCodeSupported: 'meta/isPromotionCodeSupported',
        hasContactSupport: 'meta/hasContactSupport',
        hasHostSupport: 'meta/hasHostSupport'
      }),

      isClone () {
        return this.isCreate && this.domainData
      },

      showPromotionCode () {
        return this.isCreate && this.isPromotionCodeSupported
      },

      // value for StepValidatorMixin
      stepToRefMap () {
        return {
          1: this.$refs.domainBasicData,
          2: this.$refs.domainContactData,
          3: this.showRegistrySpecificDataStep
            ? this.$refs.domainRegistrySpecificData
            : this.$refs.domainHostData,
          ...this.registrySupportsDnsSec
            ? {
              [this.showRegistrySpecificDataStep ? 5 : 4]: this.$refs.domainDnssecData
            }
            : {},
          [4 + (this.showRegistrySpecificDataStep ? 1 : 0) + (this.registrySupportsDnsSec ? 1 : 0)]:
            this.$refs.domainAdministrativeData
        }
      },

      registrySupportsDnsSec () {
        return !(this.dsDisallowed && this.dnskeyDisallowed)
      },

      domainIdentification () {
        return {
          id: this.domainData?.domain?.id,
          name: this.domainData?.domain?.name
        }
      },

      idObject () {
        return {
          v_id: this.domainObject?.domain?.versionId,
          name: this.domainObject?.domain?.name
        }
      },

      specificFields: {
        get () {
          const specificFields = this.domainObject?.specificFieldData || []

          const fields = [
            ...(this.smdField ? [this.smdField] : []),
            ...this.eligibilityFields
          ]

          // add fields only if not already present
          fields.forEach (field => {
            if (!specificFields.some (it => it.key === field.key)) {
              specificFields.push (field)
            }
          })

          // add the 'value' field to items, that do not have it yet
          const fieldHash = this.domainObject.domain?.specificFields

          if (fieldHash?.length) {
            for (const key in fieldHash) {
              const idx = specificFields.findIndex (it => it.key === key)

              if (idx >= 0) {
                specificFields[idx].value = fieldHash[key]
              } else {
                specificFields.push ({
                  key,
                  value: fieldHash[key]
                })
              }
            }
          }

          return specificFields
        },

        set (newValue) {
          if (Array.isArray (newValue)) {
            this.domainObject.specificFieldData = newValue

            this.domainObject.domain.specificFields =
              keyValueArrayToObject (newValue)
          }
        }
      },

      addOnFields: {
        get () {
          return this.domainObject?.addOnFieldData || []
        },

        set (newValue) {
          if (Array.isArray (newValue)) {
            this.domainObject.domain.addOnFields =
              keyValueArrayToObject (newValue)
          }
        }
      },

      launchPhaseData () {
        let launchPhaseData

        const launchPhases = this.launchPhases
        const launchPhase = this.domainObject.domain.launchPhase

        if (launchPhases?.length && launchPhase) {
          const idx = launchPhases.findIndex (it =>
            it.name === launchPhase.name && it.subname === launchPhase.subname
          )

          if (idx >= 0) {
            launchPhaseData = launchPhases[idx]
          }
        }

        return launchPhaseData
      },

      eligibilityFields () {
        let eligibilityFields = []

        if (this.isCreate && this.launchPhaseData) {
          eligibilityFields =
            this.launchPhaseData.eligibilityFields.map (it => ({
              ...it,
              ...(it.value ? {} : {value: null})
            }))
        }

        return eligibilityFields
      },

      smdField () {
        let smdField

        if (this.isCreate && this.launchPhaseData?.signedMarkRequired) {
          smdField = {
            key: 'trademark.smd',
            value: null,
            name: 'Signed Mark Data (SMD)',
            type: 'textarea',
            description: '',
            example: '',
            validationData: {
              required: true
            }
          }
        }

        return smdField
      },

      showRegistrySpecificDataStep () {
        return !_isEmpty (this.specificFields)
      },

      basicData: {
        get () {
          return {
            domain: _pick (
              this.domainObject.domain,
              ['launchPhase', 'names', 'variants', 'language', 'period'])
          }
        },
        set (newValue) {
          Object.assign (this.domainObject.domain, newValue.domain)
        }
      },

      contactData: {
        get () {
          return {
            domain: {
              registryId: this.domainObject.domain.registryId,
              clientId: this.domainObject.domain.clientId,
              contacts: this.domainObject.domain.contacts,
              wapOverride: this.domainObject.domain.wapOverride,
              registrantType: this.domainObject.domain.registrantType,
              publishRegistrantData: this.domainObject.domain.publishRegistrantData
            }
          }
        },
        set (newValue) {
          this.domainObject.domain.contacts = newValue.domain.contacts
          this.domainObject.domain.wapOverride = newValue.domain.wapOverride

          this.domainObject.domain.registrantType =
            newValue.domain.registrantType

          this.domainObject.domain.publishRegistrantData =
            newValue.domain.publishRegistrantData
        }
      },

      dnssecData: {
        get () {
          return this.domainObject.domain.dnssecData
        },
        set (newValue) {
          this.domainObject.domain.dnssecData = newValue
        }
      },

      hostData: {
        get () {
          return this.domainObject.domain.hosts
        },
        set (newValue) {
          this.domainObject.domain.hosts = newValue
          if (_isEqual (newValue, defaultHosts ())) {
            this.defaultDomain = _cloneDeep (this.domainObject.domain)
          }
        }
      },

      administrativeData: {
        get () {
          return {
            domain: {
              authInfo: this.domainObject.domain.authInfo,
              clientId: this.domainObject.domain.clientId,
              registryId: this.domainObject.domain.registryId,
              providerChainType: this.domainObject.domain.providerChainType,
              providerChainSpec: this.domainObject.domain.providerChainSpec,
              maintainer: this.domainObject.domain.maintainer
            },
            editProviderChain: this.domainObject.editProviderChain,
            addOnFieldData: this.domainObject.addOnFieldData
          }
        },
        set (newValue) {
          this.domainObject.domain.authInfo = newValue.domain.authInfo

          this.domainObject.domain.providerChainType =
            newValue.domain.providerChainType

          this.domainObject.domain.providerChainSpec =
            newValue.domain.providerChainSpec

          this.domainObject.domain.maintainer = newValue.domain.maintainer

          this.domainObject.editProviderChain = newValue.editProviderChain
          this.domainObject.addOnFieldData = newValue.addOnFieldData
        }
      },

      showStepper () {
        return !!(this.domainObject.domain.registryId &&
          this.domainObject.domain.clientId)
      }
    },

    watch: {
      'domainData.domain.name' (name) {
        this.domainObject.domain.name = name
      },

      domainObject: {
        handler () {
          this.onChangeDomainData ({
            domain: this.domainObject.domain,
            editProviderChain: this.domainObject.editProviderChain,
            specificFieldData: this.domainObject.specificFieldData,
            addOnFieldData: this.domainObject.addOnFieldData
          })
        },
        deep: true
      },

      'domainIdentification' (newValue, oldValue) {
        switch (this.type) {
          case TYPES.APPLICATION:
            if (oldValue.id !== newValue.id) {
              this.loadDomain ({id: newValue.id})
            }
            break

          case TYPES.DOMAIN:
            if (oldValue.name !== newValue.name) {
              this.loadDomain ({name: newValue.name})
            }
            break
        }
      },

      'domainObject.domain.registryId' (newValue) {
        if (newValue) {
          this.loadRegistryData (newValue)
          this.orderProcessingResponse = null
        }
      },

      'domainObject.addOnFieldData': {
        handler (newValue) {
          if (Array.isArray (newValue)) {
            this.domainObject.domain.addOnFields =
              keyValueArrayToObject (newValue)
          }
        },
        deep: true
      },

      step (newValue) {
        this.isContactDataVisible = newValue === '2'

        this.$router.replace ({query: {...this.$route.query, step: newValue}})
      },

      registryId () {
        this.domainObject.domain.registryId = this.registryId
        this.loadRegistryData (this.domainObject.domain.registryId)
      },

      domain (newValue) {
        this.domainObject.domain.names = [newValue]
      }
    },

    created () {
      if (this.domainData?.domain?.name) {
        this.loadDomain ({name: this.domainData.domain.name})
      }

      if (this.domainData?.domain?.id) {
        this.loadDomain ({id: this.domainData.domain.id})
      }

      this.step = this.$route.query.step || 1
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        setDomainCreateData: 'create/setDomainCreateData',
        setDomainCloneData: 'create/setDomainCloneData',
        setClonedDomainDataFor: 'create/setClonedDomainDataFor',
        showError: 'notification/setError'
      }),

      /**
       * Pre-select registry if only one registry is available for the current
       * user.
       */
      onRegistriesLoaded (registryTypes) {
        const typeKeys = Object.keys (registryTypes)

        if (typeKeys.length === 1) {
          this.domainObject.domain.registryId = typeKeys[0]
        }
      },

      getStep (virtualStep) {
        switch (virtualStep) {
          case 1:
            return 1
          case 2:
            return 2
          case 3:
            return virtualStep - (!this.hasContactSupport && 1)
          case 4:
            return virtualStep -
              (!this.hasContactSupport && 1) -
              (!this.showRegistrySpecificDataStep && 1)
          case 5:
            return virtualStep -
              (!this.hasContactSupport && 1) -
              (!this.showRegistrySpecificDataStep && 1) -
              (!this.hasHostSupport && 1)
          case 6:
            return virtualStep -
              (!this.hasContactSupport && 1) -
              (!this.showRegistrySpecificDataStep && 1) -
              (!this.hasHostSupport && 1) -
              (!this.registrySupportsDnsSec && 1)
        }
      },

      /**
       * Store the registry ID after a registry has been selected.
       *
       * @param {String} registryId     the ID of the selected registry
       */
      onRegistrySelected (registryId) {
        // prevent data reset for clone operation: domain and registry data
        // for registry selector are requested simultaneously, thus it's
        // possible, that registry selector updated later and in this case
        // the domain object (which is already populated) should not be reset.
        // So it's always a good idea to check if the registry is really
        // changed.
        if (registryId !== this.domainObject.domain.registryId) {
          const domainNames = this.domainObject.domain.names
          this.onReset ()
          this.domainObject.domain.registryId = registryId
          this.domainObject.domain.names = domainNames
        }
      },

      /**
       * Store limit price and currency after it is changed
       *
       * @param {Object} priceModel
       */
      onLimitPriceChanged (priceModel) {
        this.domainObject.domain.priceLimit = priceModel
      },

      /**
       * Load the domain with the given name/ID.
       * The current version of the requested domain will be loaded.
       *
       * @param {String} params       name/ID of the domain to be loaded
       */
      loadDomain (params) {
        this.isLoading = true
        this.fetchData ({
          op: `${this.type}/load`,
          params: {
            ...params,
            includeRelations: true,
            includeRemote: true,
            purpose: 'update'
          },
          cb: data => {
            this.isDnssecEnabled = data.domainExtraData.linkedZoneId === null
            let domain

            if (this.isCreate) {
              domain = _merge (getDefaultDomainParams (), data.domainData)

              delete domain.id
              delete domain.name
              delete domain.handle
              delete domain.versionId
            } else {
              domain = data.domainData
              domain.names = [data.domainData.name]

              const states = data.viewData.states

              if (states.length && isUpdateProhibited (states)) {
                this.showError (this.$t ('update.prohibited'))
                this.$router.replace ({
                  name: 'domain.view',
                  params: {name: domain.name}
                })
              }
            }

            if (this.isClone && _isEqual (this.domainData, this.getClonedDomainDataFor)) {
              this.domainObject.domain = this.getDomainCloneData.domain

              this.defaultDomain = _cloneDeep (this.domainObject.domain)

              this.domainObject.specificFieldData =
                this.getDomainCloneData.specificFieldData

              this.domainObject.addOnFieldData =
                this.getDomainCloneData.addOnFieldData

              this.domainObject.editProviderChain =
                this.getDomainCloneData.editProviderChain ||
                DefaultEditProviderChain
            } else {
              this.domainObject.domain = domain
            }

            this.defaultDomain = _cloneDeep (this.domainObject.domain)
            this.contactsExtraData = data.domainExtraData.contacts
          },
          cbFinal: () => {
            this.isLoading = false
            this.initialLoad = false
          }
        })

        this.populateAddOnFields ()
      },

      /**
       * Load the registry-specific field data for the registry with the given
       * ID.
       *
       * @param {Integer} registryId      the registry ID
       */
      loadRegistryData (registryId) {
        this.loadRegistrySpecificFields (registryId)
      },

      /**
       * Set the client ID when a new client has been selected.
       */
      onClientSelected (clientId) {
        if (clientId !== this.domainObject.domain.clientId) {
          this.domainObject.domain.clientId = clientId
        }
      },

      /**
       * Load the meta data of the Payload fields that are specific for the
       * registry with the given ID.
       *
       * @param {Integer} registryId      the registry ID
       */
      loadRegistrySpecificFields (registryId) {
        this.fetchData ({
          op: 'domain/loadSpecificFields',
          params: {
            registryId: registryId,
            operation: this.isCreate ? 'create' : 'update'
          },
          cb: data => {
            const specificFields = this.domainObject.domain.specificFields
            const specificFieldData = []

            for (const idx in data.fieldData) {
              const field = data.fieldData[idx]

              if (field.multiValued) {
                const keyPrefix = field.key
                let keyIndex = 1
                let key = keyPrefix + '.' + keyIndex

                do {
                  specificFieldData.push ({
                    key: key,
                    name: field.name,
                    value: specificFields[key] || '',
                    description: field.description,
                    example: field.example,
                    validationData: field.validationData,
                    multiValued: true
                  })

                  keyIndex++
                  key = keyPrefix + '.' + keyIndex
                } while (specificFields[key] !== undefined)
              } else {
                specificFieldData.push ({
                  key: field.key,
                  name: field.name,
                  value: specificFields[field.key] || '',
                  description: field.description,
                  example: field.example,
                  validationData: field.validationData,
                  multiValued: false
                })
              }
            }

            this.domainObject.specificFieldData = specificFieldData
          },
          cbError: () => {
            this.domainObject.specificFieldData = []
          }
        })
      },

      prepareDomainData () {
        let domainData = _cloneDeep (this.domainObject.domain)

        if (!this.domainObject.editProviderChain) {
          delete domainData.providerChainSpec
          delete domainData.providerChainType
        }

        if (!this.isCreate) {
          // noinspection JSUnusedLocalSymbols
          const {names, ...updateDomainData} = domainData
          domainData = updateDomainData
        }

        domainData.hosts = domainData.hosts.filter (h => h.name !== '')

        domainData.dnssecData.forEach (d => {
          if (!d.dnskeyData) {
            delete d.dnskeyData
          }

          if (!d.dsData) {
            delete d.dsData
          } else {
            d.dsData.generated = false
          }
        })

        return domainData
      },

      /**
       * process domain data change event
       *
       * @param {Object} data     changed domain data
       */
      onChangeDomainData (data) {
        // store domain data to vuex store in domain creation case (to be able
        // to switch to another page and then return to domain creation)
        if (this.isCreate && !this.isClone) {
          this.setDomainCreateData (data)
        }
        if (this.isClone && !this.initialLoad) {
          this.setDomainCloneData (data)
          this.setClonedDomainDataFor (this.domainData)
        }

        if (this.defaultDomain && !_isEqual (data.domain, this.defaultDomain)) {
          const dif = function difference (object, base) {
            function changes (object, base) {
              return _transform (object, function (result, value, key) {
                if (!_isEqual (value, base[key])) {
                  result[key] = (_isObject (value) && _isObject (base[key])) ? changes (value, base[key]) : value
                }
              })
            }
            return changes (object, base)
          }

          console.log (dif (data.domain, this.defaultDomain))

          this.$emit ('unsavedChanges', true)
        } else {
          this.$emit ('unsavedChanges', false)
        }
      },

      onFailure (data) {
        if (data.errors && data.errors.length !== 0) {
          this.orderProcessingResponse = data.errors
        } else {
          this.showError (errorTranslator (data))
        }

        this.$emit ('failure')
      },

      createDomains (domainData) {
        this.isLoading = true

        this.fetchData ({
          op: 'domain/create',
          params: {
            domainData,
            promotionCode: this.showPromotionCode && this.promoCode ? this.promoCode : null
          },
          cb: data => {
            if (Array.isArray (data.errors) && data.errors.length > 0) {
              this.orderProcessingResponse = data.errors

              this.domainObject.domain.names =
                this.domainObject.domain.names.filter (n =>
                  data.failedDomains.includes (n))
            } else {
              this.orderProcessingResponse = null
              this.clearFormData ()

              if (this.isClone) {
                this.setClonedDomainDataFor (null)
              }
            }

            this.step = 1

            this.$emit ('success', {
              successfulNames: data.successfulDomains,
              failedNames: data.failedDomains,
              createdObjectTypes: data.createdObjectTypes
            })
          },
          cbError: data => {
            this.onFailure (data)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      updateDomain (domainData) {
        this.isLoading = true

        this.fetchData ({
          op: `${this.type}/update`,
          params: {domainData},
          cb: data => {
            if (Array.isArray (data.errors) && data.errors.length > 0) {
              this.orderProcessingResponse = data.errors
              this.$emit ('failure')
            } else {
              this.orderProcessingResponse = null
            }

            this.step = 1
            this.$emit ('unsavedChanges', false)
            this.$emit ('success', {name: data.name})
          },
          cbError: data => {
            this.onFailure (data)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      /**
       * Create a new domain from the entered domain data.
       *
       * Clear the domain create form in case of success, display the error
       * message(s) otherwise.
       */
      onSubmit () {
        // TODO, FIXME: this is a workaround for https://github.com/vuetifyjs/vuetify/issues/5203
        // (if submitted directly from combobox, then the combobox value is not updated yet, so wait...)
        // This workaround can be reverted as soon as the issue is resolved and the Vuetify library is updated
        setTimeout (() => {
          this.triggerStepperValidation ()

          if (this.isDataValid ()) {
            this.$emit ('dataValidityChanged', true)
            const domainData = this.prepareDomainData ()

            if (this.isCreate) {
              this.createDomains (domainData)
            } else {
              this.updateDomain (domainData)
            }
          } else {
            this.$emit ('dataValidityChanged', false)
            this.touchData ()
          }
        })
      },

      /**
       * In certain cases the validation of the stepper steps is only triggered
       * when the step changes. This methods changes the current step to a
       * different one and then back to the original one to make sure that the
       * validation is actually triggered.
       */
      triggerStepperValidation () {
        const tmpStep = this.step
        this.step = tmpStep === 2 ? 1 : 2
        this.step = tmpStep
      },

      /**
       * Reset the domain create form to default values.
       */
      onReset () {
        this.clearFormData ()
        this.$emit ('dataValidityChanged', true)
        this.orderProcessingResponse = null
        this.onChangeDomainData (this.domainObject)
      },

      /**
       * Reset this component to its initial state and emit a "startOver" event.
       */
      doStartOver () {
        this.domainObject.domain = getDefaultDomainParams ()
        this.$emit ('startOver')
        this.showStartOverConfirmation = false
      },

      /**
       * Show a confirmation dialog asking whether all entered data shall be
       * erased.
       */
      onStartOver () {
        this.showStartOverConfirmation = true
      },

      /**
       * Clear the data entered into the form but keep selected client and
       * registry.
       */
      clearFormData () {
        const registryId = this.domainObject.domain.registryId
        const clientId = this.domainObject.domain.clientId
        this.domainObject.domain = getDefaultDomainParams ()

        if (this.domainObject.specificFieldData) {
          this.domainObject.specificFieldData.forEach (f => {
            f.value = ''
          })

          this.domainObject.specificFieldData =
            this.domainObject.specificFieldData.filter (f =>
              !f.multiValued ||
              f.key.substring (f.key.lastIndexOf ('.') + 1) === '1')
        }

        this.domainObject.addOnFieldData = getAddOnFieldData ()
        this.editProviderChain = DefaultEditProviderChain
        this.domainObject.domain.registryId = registryId
        this.domainObject.domain.clientId = clientId

        this.resetData ()
      },

      onCreateUpdateComponentLoaded () {
        if (this.isCreate && !this.isClone) {
          this.domainObject.domain = this.domainCreateData.domain ||
            getDefaultDomainParams ()

          this.defaultDomain = _cloneDeep (this.domainObject.domain)

          this.domainObject.specificFieldData =
            this.domainCreateData.specificFieldData

          this.domainObject.addOnFieldData =
            this.domainCreateData.addOnFieldData

          this.domainObject.editProviderChain =
            this.domainCreateData.editProviderChain ||
            DefaultEditProviderChain
        } else if (this.isClone && _isEqual (this.domainData, this.getClonedDomainDataFor)) {
          this.domainObject.domain = this.getDomainCloneData.domain ||
            getDefaultDomainParams ()

          this.defaultDomain = _cloneDeep (this.domainObject.domain)

          this.domainObject.specificFieldData =
            this.getDomainCloneData.specificFieldData

          this.domainObject.addOnFieldData =
            this.getDomainCloneData.addOnFieldData

          this.domainObject.editProviderChain =
            this.getDomainCloneData.editProviderChain ||
            DefaultEditProviderChain
        }
      },

      populateAddOnFields () {
        this.domainObject.addOnFieldData = objectToKeyValueArray (
          this.domainObject.domain.addOnFields, k => removePrefix (k, '.'),
          v => v)

        // FIXME: addon field are not recognized as default fields on the domain
        //        therefore the changes on addon field are not recognized as unsaved changes
        this.defaultDomain = _cloneDeep (this.domainObject.domain)
      },

      /**
       * Get the references of all sub-components for validity checks.
       *
       * @return {Array}      the reference objects
       */
      getComponentRefs () {
        return [
          this.$refs.domainBasicData,
          this.$refs.domainContactData,
          this.$refs.domainHostData,
          this.$refs.domainAdministrativeData,
          this.$refs.domainRegistrySpecificData
          // always valid: this.$refs.domainDnssecData
        ]
      },

      isBasicDataValid () {
        return !this.$refs.domainBasicData ||
          !this.$refs.domainBasicData.hasErrors ()
      },

      isContactDataValid () {
        return !this.$refs.domainContactData ||
          !this.$refs.domainContactData.hasErrors ()
      },

      isHostDataValid () {
        return !this.$refs.domainHostData ||
          !this.$refs.domainHostData.hasErrors ()
      },

      isDnssecDataValid () {
        return !this.$refs.domainDnssecData ||
          !this.$refs.domainDnssecData.hasErrors ()
      },

      isAdministrativeDataValid () {
        return !this.$refs.domainAdministrativeData ||
          !this.$refs.domainAdministrativeData.hasErrors ()
      },

      isSpecificDataValid () {
        return !this.$refs.domainRegistrySpecificData ||
          !this.$refs.domainRegistrySpecificData.hasErrors ()
      }
    }
  }
</script>
