import {mapGetters} from 'vuex'
import {helpers} from 'vuelidate/lib/validators'

export const propertyToFieldNames = {
  name: 'name',
  organization: 'organization',
  street: 'street',
  city: 'city',
  stateOrProvince: 'state',
  postalCode: 'postalcode',
  countryCode: 'countrycode',
  phoneNumber: 'voice.number',
  phoneNumberExt: 'voice.extension',
  faxNumber: 'fax.number',
  faxNumberExt: 'fax.extension',
  emailAddress: 'email',
  maintainer: 'maintainer',
  policy: 'datapolicy',
  contactHandle: 'id'
}

export default {
  computed: {
    ...mapGetters ('meta', [
      'getRequiredContactFields',
      'getOptionalContactFields',
      'getProhibitedContactFields'
    ]),

    fieldNamesMap () {
      return propertyToFieldNames
    }
  },

  methods: {
    isFieldPresent (fieldName) {
      return this.isFieldOptional (fieldName) ||
        this.isFieldRequired (fieldName) || this.isFieldProhibited (fieldName)
    },

    isFieldRequired (fieldName) {
      return this.getRequiredContactFields?.includes (fieldName)
    },

    isFieldProhibited (fieldName) {
      return this.getProhibitedContactFields?.includes (fieldName) &&
        !this.isFieldOptional (fieldName) && !this.isFieldRequired (fieldName)
    },

    isFieldOptional (fieldName) {
      return this.getOptionalContactFields?.includes (fieldName) &&
        !this.isFieldRequired (fieldName)
    },

    createValidators (fieldName) {
      let validators = {}

      if (this.isFieldRequired (fieldName)) {
        validators = {
          required: helpers.req
        }
      }

      if (this.isFieldProhibited (fieldName)) {
        validators = {
          ...validators,
          prohibited: (v) => !helpers.req (v)
        }
      }

      return validators
    }
  }
}
