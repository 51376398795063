var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{class:{ 'cgwng-bg-color-1': _vm.bgcolor }},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.readonly)?_c('domain-dnssec-dialog',{attrs:{"show-ds":_vm.enterDsRecordData,"show-dnskey":_vm.enterDnskeyData,"data":_vm.dnssecData},on:{"ok":_vm.onSave},model:{value:(_vm.dnssecDialog),callback:function ($$v) {_vm.dnssecDialog=$$v},expression:"dnssecDialog"}}):_vm._e()],1)],1),_c('v-row',{class:{ 'cgwng-bg-color-1': _vm.bgcolor }},[(_vm.hasDsData)?_c('v-col',{staticClass:"pb-6",attrs:{"cols":"12"}},[_c('div',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.ds'),expression:"'create.label.dnssec.ds'"}],staticClass:"dnssec-table-header"}),_c('v-data-table',{staticClass:"dnssec-table elevation-1",attrs:{"hide-default-footer":"","headers":_vm.dsHeaders,"items":_vm.dnssecRecords,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(props){return [(props.item.dsData)?_c('tr',{class:{ highlighted: _vm.highlight === props.index },on:{"mouseover":function($event){_vm.highlight = props.index},"mouseout":function($event){_vm.highlight = null}}},[(props.item.dsData.generated)?_c('td',[_c('v-tooltip',{attrs:{"top":""}},[_c('v-icon',_vm._g({},_vm.on),[_vm._v(" computer ")]),_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.computed'),expression:"'create.label.dnssec.computed'"}]})],1)],1):(_vm.hasGeneratedDsData)?_c('td'):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(props.index + 1)+" ")]):_vm._e(),_c('td',[_c('span',{class:{ 'dnssec-code': props.item.dsData.keyTag }},[_vm._v(" "+_vm._s(props.item.dsData.keyTag || '\u2014')+" ")])]),_c('td',[_c('span',{class:{ 'dnssec-code': props.item.dsData.algorithm }},[_vm._v(" "+_vm._s(props.item.dsData.algorithm || '\u2014')+" ")]),(props.item.dsData.algorithm &&
                  _vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"dnssec-string"},[_vm._v(" "+_vm._s(_vm.algorithmString (props.item.dsData.algorithm))+" ")]):_vm._e()]),_c('td',[_c('span',{class:{ 'dnssec-code': props.item.dsData.digestType }},[_vm._v(" "+_vm._s(props.item.dsData.digestType || '\u2014')+" ")]),(props.item.dsData.digestType &&
                  _vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"dnssec-string"},[_vm._v(" "+_vm._s(_vm.digestTypeString (props.item.dsData.digestType))+" ")]):_vm._e()]),_c('td',[_c('span',{class:{ 'dnssec-bin': props.item.dsData.hash }},[_vm._v(" "+_vm._s(props.item.dsData.hash || '\u2014')+" ")])]),(!_vm.readonly)?_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.onEdit (props.index, 'ds')}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.edit'),expression:"'create.label.dnssec.edit'"}]})]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.onDelete (props.index)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.delete'),expression:"'create.label.dnssec.delete'"}]})])],1):_vm._e()]):_vm._e()]}}],null,false,3005626501)})],1):_vm._e(),(_vm.hasDnskeyData)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.dnskey'),expression:"'create.label.dnssec.dnskey'"}],staticClass:"dnssec-table-header"}),_c('v-data-table',{staticClass:"dnssec-table elevation-1",attrs:{"hide-default-footer":"","headers":_vm.dnskeyHeaders,"items":_vm.dnssecRecords,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{ highlighted: _vm.highlight === props.index },on:{"mouseover":function($event){_vm.highlight = props.index},"mouseout":function($event){_vm.highlight = null}}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(props.index + 1)+" ")]):_vm._e(),_c('td',[_c('span',{class:{ 'dnssec-code': props.item.dnskeyData && props.item.dnskeyData.flags }},[_vm._v(" "+_vm._s(props.item.dnskeyData && props.item.dnskeyData.flags || '\u2014')+" ")]),(props.item.dnskeyData && props.item.dnskeyData.flags &&
                  _vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"dnssec-string"},[_vm._v(" "+_vm._s(_vm.flagsString (props.item.dnskeyData.flags))+" ")]):_vm._e()]),_c('td',[_c('span',{class:{ 'dnssec-code': props.item.dnskeyData && props.item.dnskeyData.protocol }},[_vm._v(" "+_vm._s(props.item.dnskeyData && props.item.dnskeyData.protocol || '\u2014')+" ")]),(props.item.dnskeyData && props.item.dnskeyData.protocol &&
                  _vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"dnssec-string"},[_vm._v(" "+_vm._s(_vm.protocolString (props.item.dnskeyData.protocol))+" ")]):_vm._e()]),_c('td',[_c('span',{class:{ 'dnssec-code': props.item.dnskeyData && props.item.dnskeyData.algorithm }},[_vm._v(" "+_vm._s(props.item.dnskeyData && props.item.dnskeyData.algorithm || '\u2014')+" ")]),(props.item.dnskeyData && props.item.dnskeyData.algorithm &&
                  _vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"dnssec-string"},[_vm._v(" "+_vm._s(_vm.algorithmString (props.item.dnskeyData.algorithm))+" ")]):_vm._e()]),_c('td',[_c('span',{class:{ 'dnssec-bin': props.item.dnskeyData && props.item.dnskeyData.publicKey }},[_vm._v(" "+_vm._s(props.item.dnskeyData && props.item.dnskeyData.publicKey || '\u2014')+" ")])]),(!_vm.readonly)?_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.onEdit (props.index, 'dnskey')}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.edit'),expression:"'create.label.dnssec.edit'"}]})]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.onDelete (props.index)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('create.label.dnssec.delete'),expression:"'create.label.dnssec.delete'"}]})])],1):_vm._e()])]}}],null,false,1217586873)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }